import { takeLatest, call, put } from 'redux-saga/effects'
import { LINK_API } from "../../../constants/API"
import fetchData from "../fetchData"
import { getListPopularSets, getListPopularSetsFailure, getListPopularSetsSuccess } from "../../reducer/list/listPopularSetsSlice"

function* fetchListPopularSets (payload) {
    try {

        const likeAddress = payload?.payload?.likeAddress ? `&likeAddress=${payload?.payload?.likeAddress}` : ""

        const url = LINK_API.GET_LIST_SETS + "?size=12&page=1&sort=like&status=ONSALE,NOTFORSALE" + likeAddress

        const response = yield call(fetchData, url)

        const data = response.data

        yield put({type: getListPopularSetsSuccess().type, payload: data})

    } catch (error) {
        yield put({ type: getListPopularSetsFailure().type })
        
    }
}

const listPopularSetsSaga = [
    takeLatest(getListPopularSets().type, fetchListPopularSets)
]

export default listPopularSetsSaga