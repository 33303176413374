import React from "react";
import SweetAlert from 'react-bootstrap-sweetalert';
import { withTranslation } from 'react-i18next';
import "./css/sweet.css";
const  Loading = props => {
  const {t} = props
  const handleSubmit = () => {

  }
  return (
    <SweetAlert title="" showConfirm={false} onConfirm={() => {}} onSubmit={handleSubmit} showCancel={false} style={{ display: "block", top: props?.top ? props?.top : "0%" }}>
      <div className="text-center">
        
        <div className="super-spinner"></div>
        <div className="font-weight-bold font-size-lg mt-4 text-black">{t('processing')}</div>
        <p className="mb-0 mt-2 text-muted text-black">{props?.title ? props?.title : `${t("plsWait")}...`}</p>
      </div>
    </SweetAlert >
  )
}

export default withTranslation('common')(Loading);