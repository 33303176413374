import { takeLatest, call, put } from 'redux-saga/effects'
import { LINK_API } from "../../../constants/API"
import fetchData from "../fetchData"
import { getFeaturedCreatorsList, getFeaturedCreatorsListFailure, getFeaturedCreatorsListSuccess } from "../../reducer/list/featuredCreatorsListSlice"

function* fetchFeaturedCreatorsList (payload) {
    try {

        const params = {
            "page": 1,
            "size": 12,
            "isCreator": 1,
        }

        const url = LINK_API.GET_USER_LIST

        const response = yield call(fetchData, url, "GET", {}, params)

        const data = response.data

        yield put({type: getFeaturedCreatorsListSuccess().type, payload: data})

    } catch (error) {
        yield put({ type: getFeaturedCreatorsListFailure().type })
        
    }
}

const featuredCreatorsListSaga = [
    takeLatest(getFeaturedCreatorsList().type, fetchFeaturedCreatorsList)
]

export default featuredCreatorsListSaga