import React from "react";
import { ModalHeader } from "reactstrap";
import "./index.scss"
import CloseCircleOutlineIcon from "mdi-react/CloseCircleOutlineIcon"

const ModalHeaderComponent = props => {
    const {
        toggle,
        title,
    } = props

    return(
        <ModalHeader
        >
            <div className="modal-header-container">
                <div className="title">
                    <div>
                        {title}
                    </div>
                </div>

                {
                    toggle ?
                    <div className="close-icon" onClick={toggle} >
                        <CloseCircleOutlineIcon/>
                    </div>
                    : null
                }
            </div>
        </ModalHeader>
    )
}

export default ModalHeaderComponent