import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isLoading: false,
    is_failure: false,
}

const detailBundleSlice = createSlice({
    name:"detailBundle",
    initialState: initialState,
    reducers: {
        getDetailBundle(state, action){
            return {
                isLoading: true,
                is_failure: false,
            }
        },
        getDetailBundleSuccess(state, action){
            return {
                isLoading: false,
                is_failure: false,
                ...action.payload
            }
        },
        getDetailBundleFailure(state, action){
            return {
                isLoading: false,
                is_failure: true,
            }
        },
        cleanDetailBundle(state, action){
            return {
                isLoading: false,
                is_failure: false,
            }
        }
    }
})

export const { cleanDetailBundle, getDetailBundle, getDetailBundleFailure, getDetailBundleSuccess } = detailBundleSlice.actions

export default detailBundleSlice.reducer