import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isLoading: false,
}

const listNFTsOfCollectionSlice = createSlice({
    name:"listNFTsOfCollection",
    initialState: initialState,
    reducers: {
        getListNFTsOfCollection(state, action){
            return {
                isLoading: true,
            }
        },
        getListNFTsOfCollectionSuccess(state, action){
            return {
                isLoading: false,
                data: action.payload
            }
        },
        getListNFTsOfCollectionFailure(state, action){
            return {
                isLoading: false,
            }
        },
        cleanListNFTsOfCollection(state, action){
            return {
                isLoading: false
            }
        }
    }
})

export const { cleanListNFTsOfCollection, getListNFTsOfCollection, getListNFTsOfCollectionFailure, getListNFTsOfCollectionSuccess } = listNFTsOfCollectionSlice.actions

export default listNFTsOfCollectionSlice.reducer