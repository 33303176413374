import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isLoading: false,
}

const nftsListOfMostPopularCollectionSlice = createSlice({
    name:"nftsListOfMostPopularCollection",
    initialState: initialState,
    reducers: {
        getNFTsListOfMostPopularCollection(state, action){
            return {
                isLoading: true,
            }
        },
        getNFTsListOfMostPopularCollectionSuccess(state, action){
            return {
                isLoading: false,
                ...action.payload
            }
        },
        getNFTsListOfMostPopularCollectionFailure(state, action){
            return {
                isLoading: false,
            }
        },
        cleanNFTsListOfMostPopularCollection(state, action){
            return {
                isLoading: false
            }
        }
    }
})

export const { cleanNFTsListOfMostPopularCollection, getNFTsListOfMostPopularCollection, getNFTsListOfMostPopularCollectionFailure, getNFTsListOfMostPopularCollectionSuccess } = nftsListOfMostPopularCollectionSlice.actions

export default nftsListOfMostPopularCollectionSlice.reducer