import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isLoading: false,
    is_failure: false,
}

const listProvenanceOfNFTSlice = createSlice({
    name:"listProvenanceOfNFT",
    initialState: initialState,
    reducers: {
        getListProvenanceOfNFT(state, action){
            return {
                isLoading: true,
                is_failure: false,
            }
        },
        getListProvenanceOfNFTSuccess(state, action){
            return {
                isLoading: false,
                is_failure: false,
                data: action?.payload,
            }
        },
        getListProvenanceOfNFTFailure(state, action){
            return {
                isLoading: false,
                is_failure: true,
            }
        },
        cleanListProvenanceOfNFT(state, action){
            return{
                isLoading: false,
                is_failure: false,
            }
        }
    }
})

export const { 
    cleanListProvenanceOfNFT,
    getListProvenanceOfNFT,
    getListProvenanceOfNFTFailure,
    getListProvenanceOfNFTSuccess,
} = listProvenanceOfNFTSlice.actions

export default listProvenanceOfNFTSlice.reducer