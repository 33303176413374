import React from "react";
import { withTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import EBSLogo from "../../../assets/images/WalletLogo/EBSLogo.webp"
import MetamaskLogo from "../../../assets/images/WalletLogo/MetamaskLogo.svg"
import renderAddress from "../../../utils/renderAddress"

const Wallet = props => {
    const {
        t,
        handleSetIsAccountSidebar,
    } = props

    const currentBcAddressSlice = useSelector(state => state.currentBcAddressSlice)
    const walletTypeSlice = useSelector(state => state.walletTypeSlice)

    return (
        <div className="header__primaryBtn"
            onClick={() => {
                handleSetIsAccountSidebar()
            }}
        >
            {
                currentBcAddressSlice ?
                <>
                    <img
                        src={walletTypeSlice === "VBchain" ? EBSLogo : walletTypeSlice === "MetaMask" ? MetamaskLogo : EBSLogo}
                        alt=""
                    />
                    <div>
                        {renderAddress(currentBcAddressSlice)}
                    </div>
                </>
                :
                <> {t("header.connectWallet")} </>
            }
        </div>
    )
}

export default withTranslation("common")(Wallet)