import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isLoading: false,
    is_failure: false,
}

const featuredCreatorsListSlice = createSlice({
    name:"featuredCreatorsList",
    initialState: initialState,
    reducers: {
        getFeaturedCreatorsList(state, action){
            return {
                isLoading: true,
                is_failure: false,
            }
        },
        getFeaturedCreatorsListSuccess(state, action){
            return {
                isLoading: false,
                is_failure: false,
                ...action.payload
            }
        },
        getFeaturedCreatorsListFailure(state, action){
            return {
                isLoading: false,
                is_failure: true,
            }
        },
        cleanFeaturedCreatorsList(state, action){
            return {
                isLoading: false,
                is_failure: false,
            }
        }
    }
})

export const { cleanFeaturedCreatorsList, getFeaturedCreatorsList, getFeaturedCreatorsListFailure, getFeaturedCreatorsListSuccess } = featuredCreatorsListSlice.actions

export default featuredCreatorsListSlice.reducer