import { takeLatest, call, put } from 'redux-saga/effects'
import { LINK_API } from "../../../constants/API"
import fetchData from "../fetchData"
import { getListAllCollections, getListAllCollectionsFailure, getListAllCollectionsSuccess } from "../../reducer/list/listAllCollectionsSlice"

function* fetchListAllCollections (payload) {
    try {

        const temp_payload = payload?.payload

        const likeAddress = temp_payload?.likeAddress ? `&likeAddress=${temp_payload?.likeAddress}` : ""
        const page = temp_payload?.page ? `&page=${temp_payload?.page}` : ""
        const sort = temp_payload?.sort ? `&sort=${temp_payload?.sort}` : ""

        const networkId = temp_payload?.networkId ? `&networkId=${temp_payload?.networkId}` : ""

        const labelId = temp_payload?.labelId ? `&labelId=${temp_payload?.labelId}` : ""

        const url = LINK_API.GET_LIST_COLLECTIONS + "?size=24" + page + likeAddress + sort + networkId + labelId

        const response = yield call(fetchData, url)

        const data = response.data

        yield put({type: getListAllCollectionsSuccess().type, payload: data})

    } catch (error) {
        yield put({ type: getListAllCollectionsFailure().type })
        
    }
}

const listAllCollectionsSaga = [
    takeLatest(getListAllCollections().type, fetchListAllCollections)
]

export default listAllCollectionsSaga