import React from 'react'
import "./App.scss"
import "./style.scss"
import 'bootstrap/dist/css/bootstrap.min.css';
import "./styles/index.scss"

import { BrowserRouter } from 'react-router-dom'
import Routes from './routes';
import "aos/dist/aos.css"

import { PersistGate } from 'redux-persist/lib/integration/react'
import { store, persistor } from './redux/store';
import { Provider } from 'react-redux'

// bootstrap
import "./assets/base.scss"


// language
import { config as i18nextConfig } from './translation/index';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
i18next.init(i18nextConfig);


const App = props => {

  

  return(
    <>
      <div>    
        <Provider store={store}>
          <PersistGate persistor={persistor}>
            <BrowserRouter>
              <I18nextProvider i18n={i18next}>
                <Routes/>
              </I18nextProvider>
            </BrowserRouter>
          </PersistGate>
        </Provider>
      </div>
    </>
  )
}

export default (App)