import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import EBSLogo from "../../../assets/images/WalletLogo/EBSLogo.webp"
import MetamaskLogo from "../../../assets/images/WalletLogo/MetamaskLogo.svg"

const Logo = props => {

    const walletTypeSlice = useSelector(state => state.walletTypeSlice)
    const currentVBCWalletInfoSlice = useSelector(state => state.currentVBCWalletInfoSlice)
    const { t } = useTranslation("common")

    return(
        <div className="container mt-3 waSi__logo">
            <div className="waSi__logo--img">
                <img
                    alt="walletLogo"
                    src = {
                        walletTypeSlice === "VBchain" ?
                        EBSLogo
                        :
                        walletTypeSlice === "MetaMask" ?
                        MetamaskLogo
                        :
                        EBSLogo
                    }
                />
            </div>
            <div className="mt-2 waSi__logo--name">
                {
                    walletTypeSlice === "MetaMask" ?
                    "MetaMask"
                    : 
                    walletTypeSlice === "VBchain" &&  currentVBCWalletInfoSlice?.name ?
                    currentVBCWalletInfoSlice?.name
                    :
                    t("noDataFound")
                }
            </div>
        </div>
    )
}

export default Logo