import { createSlice } from "@reduxjs/toolkit";

const initialState = ""

const currentAddressOfUserSlice = createSlice({
    name:"currentAddressOfUser",
    initialState: initialState,
    reducers: {
        saveCurrentAddressOfUser(state, action){
            return action.payload
        },
        cleanCurrentAddressOfUser(state, action){
            return ""
        }
    }
})

export const { saveCurrentAddressOfUser, cleanCurrentAddressOfUser } = currentAddressOfUserSlice.actions

export default currentAddressOfUserSlice.reducer