export const handleRenderPrice = (number, toFixed) => {
    if(!isNaN(number)){
        const tempNumber1 = parseFloat(number).toFixed(toFixed || 5)

        const split_str = tempNumber1?.split(".")

        const decimal_str = split_str[1]

        let temp_decimal_str = decimal_str

        return parseFloat(`${split_str[0]}.${temp_decimal_str}`)
    }
    else {
        return 0
    }
}