import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isLoading: false,
    is_failure: false,
}

const bidListOfSetSlice = createSlice({
    name:"bidListOfSet",
    initialState: initialState,
    reducers: {
        getBidListOfSet(state, action){
            return {
                isLoading: true,
                is_failure: false,
            }
        },
        getBidListOfSetSuccess(state, action){
            return {
                isLoading: false,
                is_failure: false,
                data: action?.payload,
            }
        },
        getBidListOfSetFailure(state, action){
            return {
                isLoading: false,
                is_failure: true,
            }
        },
        cleanBidListOfSet(state, action){
            return{
                isLoading: false,
                is_failure: false,
            }
        }
    }
})

export const { 
    cleanBidListOfSet,
    getBidListOfSet,
    getBidListOfSetFailure,
    getBidListOfSetSuccess,
} = bidListOfSetSlice.actions

export default bidListOfSetSlice.reducer