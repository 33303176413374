import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isLoading: false,
    is_failure: false,
}

const popularBundlesListSlice = createSlice({
    name:"popularBundlesList",
    initialState: initialState,
    reducers: {
        getPopularBundlesList(state, action){
            return {
                isLoading: true,
                is_failure: false,
            }
        },
        getPopularBundlesListSuccess(state, action){
            return {
                isLoading: false,
                is_failure: false,
                ...action.payload
            }
        },
        getPopularBundlesListFailure(state, action){
            return {
                isLoading: false,
                is_failure: true,
            }
        },
        cleanPopularBundlesList(state, action){
            return {
                isLoading: false,
                is_failure: false,
            }
        }
    }
})

export const { cleanPopularBundlesList, getPopularBundlesList, getPopularBundlesListFailure, getPopularBundlesListSuccess } = popularBundlesListSlice.actions

export default popularBundlesListSlice.reducer