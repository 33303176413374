import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isLoading: false,
}

const detailProfileAccountSlice = createSlice({
    name:"detailProfileAccount",
    initialState: initialState,
    reducers: {
        getDetailProfileAccount(state, action){
            return {
                isLoading: true
            }
        },
        getDetailProfileAccountSuccess(state, action){
            return {
                isLoading: false,
                ...action.payload,
            }
        },
        getDetailProfileAccountFailure(state, action){
            return {
                isLoading: false,
            }
        },
        cleanDetailProfileAccount(state, action){
            return{
                isLoading: false,
            }
        }
    }
})

export const { 
    getDetailProfileAccount, 
    getDetailProfileAccountFailure, 
    getDetailProfileAccountSuccess,
    cleanDetailProfileAccount,
} = detailProfileAccountSlice.actions

export default detailProfileAccountSlice.reducer