import React from "react";
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import DefaultUserAvt from "../../../assets/images/DefaultUserAvt.png"
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import { LogOut } from "react-feather";
import { cleanAccountInfo } from "../../../redux/reducer/config.profile/accountSlice";
import { cleanDetailProfileAccount } from "../../../redux/reducer/config.profile/detailProfileAccountSlice";
import { setIsNotConnectWallet } from "../../../redux/reducer/config.wallet/isConnectWalletSlice";
import { cleanWalletType } from "../../../redux/reducer/config.wallet/walletTypeSlice";
import { cleanBcAddress } from "../../../redux/reducer/config.wallet/currentBcAddressSlice";
import { cleanVbcWalletEncPass } from "../../../redux/reducer/config.wallet/vbcWalletEncPassSlice";
import { cleanListVBCAccount } from "../../../redux/reducer/config.wallet/listVBCAccountSlice";
import { cleanVBCAccount } from "../../../redux/reducer/config.wallet/currentVBCAccountSlice";
import { cleanVBCWallet } from "../../../redux/reducer/config.wallet/currentVBCWalletInfoSlice";
import { cleanAccountBalance } from "../../../redux/reducer/config.balance/accountBalanceSlice";
import { cleanWrapperBalance } from "../../../redux/reducer/config.balance/wrapperBalanceSlice";
import { useMediaQuery } from 'react-responsive'
import { User } from "react-feather"
import Axios from "axios"

const Profile = props => {
    const {
        t,
        history,
    } = props

    const dispatch = useDispatch()

    const handleLogOut = () => {
        dispatch(cleanAccountInfo())
        dispatch(cleanDetailProfileAccount())
        dispatch(setIsNotConnectWallet())
        dispatch(cleanWalletType())
        dispatch(cleanBcAddress())
        dispatch(cleanVbcWalletEncPass())
        dispatch(cleanListVBCAccount())
        dispatch(cleanVBCAccount())
        dispatch(cleanVBCWallet())
        dispatch(cleanAccountBalance())
        dispatch(cleanWrapperBalance())
        Axios.defaults.headers.common.Authorization = ``;
    }

    const accountSlice = useSelector(state => state.accountSlice)
    const detailProfileAccountSlice = useSelector(state => state.detailProfileAccountSlice)

    const is_responsive = useMediaQuery({ query: '(max-width: 1199px)' })

    return (
        <div className="header__profile">
            {
                accountSlice?.isSignedIn ?
                <UncontrolledDropdown>
                    <DropdownToggle
                        tag={"span"}
                    >
                        <div className="header__profile--avatar">
                            <img
                                src={detailProfileAccountSlice?.data?.avatar || DefaultUserAvt}
                                onError={e => e.target.src = DefaultUserAvt}
                                alt=""
                            />
                        </div>
                    </DropdownToggle>
                    <DropdownMenu>
                        <DropdownItem>
                            <div className="dropdown__item"
                                onClick={() => {
                                    history.push("/profile")
                                }}
                            >
                                <img
                                    src={detailProfileAccountSlice?.data?.avatar || DefaultUserAvt}
                                    alt=""
                                /> <div> {detailProfileAccountSlice?.data?.username} </div>
                            </div>
                        </DropdownItem>
                        <DropdownItem
                            onClick={handleLogOut}
                        >
                            <div className="dropdown__item">
                                <LogOut/> <div> {t("logOut")} </div>
                            </div>
                        </DropdownItem>
                    </DropdownMenu>
                </UncontrolledDropdown>
                :
                <>
                    {
                        !is_responsive ?
                        <div className="header__primaryBtn"
                            onClick={() => {
                                history.push("/profile")
                            }}
                        >
                            {t("signIn")}
                        </div>
                        :
                        <div className="header__noti"
                            onClick={() => {
                                history.push("/profile/nft")
                            }}  
                        >
                            <User/>
                        </div>
                    }
                </>
            }
        </div>
    )
}

export default withTranslation("common")(withRouter(Profile))