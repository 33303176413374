import { takeLatest, call, put } from 'redux-saga/effects'
import { LINK_API } from "../../../constants/API"
import fetchData from "../fetchData"
import { getListPopularItems, getListPopularItemsFailure, getListPopularItemsSuccess } from "../../reducer/list/listPopularItemsSlice"

function* fetchListPopularItems (payload) {
    try {

        const likeAddress = payload?.payload?.likeAddress ? `&likeAddress=${payload?.payload?.likeAddress}` : ""

        const url = LINK_API.GET_LIST_NFTS + "?size=6&page=1&status=ONSALE,NOTFORSALE&sort=like&isSet=0&isBundle=0" + likeAddress

        const response = yield call(fetchData, url)

        const data = response.data

        yield put({type: getListPopularItemsSuccess().type, payload: data})

    } catch (error) {
        yield put({ type: getListPopularItemsFailure().type })
        
    }
}

const listPopularItemsSaga = [
    takeLatest(getListPopularItems().type, fetchListPopularItems)
]

export default listPopularItemsSaga