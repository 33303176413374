import { takeLatest, call, put } from 'redux-saga/effects'
import { LINK_API } from "../../../constants/API"
import fetchData from "../fetchData"
import { getOnSaleNFTsList, getOnSaleNFTsListSuccess, getOnSaleNFTsListFailure } from "../../reducer/config.profile/onSaleNFTsListSlice"

function* fetchOnSaleNFTsList (payload) {
    try {

        const temp_payload = payload?.payload

        const params = {
            page: temp_payload?.page || 1,
            size: 12,
            isSet: 0,
            isBundle: 0,
            owner: temp_payload?.owner,
            status: "ONSALE",
            likeAddress: temp_payload?.likeAddress
        }

        const url = LINK_API.GET_LIST_NFTS
        const response = yield call(fetchData, url, "GET", {}, params)

        const data = response.data
 
        yield put({type: getOnSaleNFTsListSuccess().type, payload: data})

    } catch (error) {
        yield put({ type: getOnSaleNFTsListFailure().type })
        
    }
}

const onSaleNFTsListSaga = [
    takeLatest(getOnSaleNFTsList().type, fetchOnSaleNFTsList)
]

export default onSaleNFTsListSaga