import { createSlice } from "@reduxjs/toolkit";
import Web3 from "web3";

const initialState = {
    items_count: 0,
    price_count: 0,
    discount_price_count: 0,
    data: []
}

const selectedNFTsListOfBundleSlice = createSlice({
    name:"selectedNFTsListOfBundle",
    initialState: initialState,
    reducers: {
        selectNFTsFromBundle(state, action){
            const nft = action?.payload
            const old_data = state?.data
            const find_nft = old_data?.find(temp_nft => temp_nft?.id === nft?.id)
            const new_data = find_nft ? old_data?.filter(temp_nft => temp_nft?.id !== nft?.id) : old_data?.concat([nft])

            const new_items_count = find_nft ? old_data?.length - 1 : old_data?.length + 1
            const nft_price = nft?.listInfo?.buyPrice?.toString()
            const render_nft_price = nft_price ? parseFloat(Web3.utils.fromWei(nft_price, "ether")) : 0
            const new_price_count = find_nft ? state.price_count - render_nft_price : state.price_count + render_nft_price
            const discount = parseFloat(nft?.listInfo?.discount) || 0
            const nft_discount_price = discount > 0 ? render_nft_price - ((discount / 100) * render_nft_price) : render_nft_price
            const new_discount_price_count = find_nft ? state.discount_price_count - nft_discount_price :  state.discount_price_count + nft_discount_price

            return {
                items_count: new_items_count,
                price_count: new_price_count,
                discount_price_count: new_discount_price_count,
                data: new_data
            }
        },
        cleanSelectedNFTsOfBundle(state, action){
            return {
                items_count: 0,
                price_count: 0,
                discount_price_count: 0,
                data: []
            }
        },
        selectedAllNFTsOfBundle(state, action){
            const prices = action?.payload?.map(nft => {
                return nft?.listInfo?.buyPrice
            })

            const discount_prices = action?.payload?.map(nft => {
                const buyPrice = nft?.listInfo?.buyPrice?.toString() || ""
                const render_buy_price = parseFloat(Web3.utils.fromWei(buyPrice, "ether"))
                const discount = parseFloat(nft?.listInfo?.discount) || 0
                const nft_discount_price = discount > 0 ? render_buy_price - ((discount / 100) * render_buy_price) : render_buy_price
                return nft_discount_price
            })

            let total_prices = 0
            let total_dicount_prices = 0

            prices.forEach(element => {
                const render = element ? parseFloat(Web3.utils.fromWei(element?.toString(), "ether")) : 0
                total_prices = total_prices + render
            });

            discount_prices.forEach(element => {
                const render = element
                total_dicount_prices = total_dicount_prices + render
            });

            return {
                items_count: action?.payload?.length,
                price_count: total_prices,
                discount_price_count: total_dicount_prices,
                data: action?.payload
            }
        }
    }
})

export const { cleanSelectedNFTsOfBundle, selectNFTsFromBundle, selectedAllNFTsOfBundle } = selectedNFTsListOfBundleSlice.actions

export default selectedNFTsListOfBundleSlice.reducer