import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isLoading: false,
    is_failure: false,
}

const listPopularItemsSlice = createSlice({
    name:"listPopularItems",
    initialState: initialState,
    reducers: {
        getListPopularItems(state, action){
            return {
                isLoading: true,
                is_failure: false,
            }
        },
        getListPopularItemsSuccess(state, action){
            return {
                isLoading: false,
                is_failure: false,
                ...action.payload
            }
        },
        getListPopularItemsFailure(state, action){
            return {
                isLoading: false,
                is_failure: true,
            }
        },
        cleanListPopularItems(state, action){
            return {
                isLoading: false,
                is_failure: false,
            }
        }
    }
})

export const { cleanListPopularItems, getListPopularItems, getListPopularItemsFailure, getListPopularItemsSuccess } = listPopularItemsSlice.actions

export default listPopularItemsSlice.reducer