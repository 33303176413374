import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isLoading: false,
    is_failure: false,
}

const nftsListOfBundleSlice = createSlice({
    name:"nftsListOfBundle",
    initialState: initialState,
    reducers: {
        getNFTsListOfBundle(state, action){
            return {
                isLoading: true,
                is_failure: false,
            }
        },
        getNFTsListOfBundleSuccess(state, action){
            return {
                isLoading: false,
                is_failure: false,
                ...action.payload
            }
        },
        getNFTsListOfBundleFailure(state, action){
            return {
                isLoading: false,
                is_failure: true,
            }
        },
        cleanNFTsListOfBundle(state, action){
            return {
                isLoading: false,
                is_failure: false,
            }
        }
    }
})

export const { cleanNFTsListOfBundle, getNFTsListOfBundle, getNFTsListOfBundleFailure, getNFTsListOfBundleSuccess } = nftsListOfBundleSlice.actions

export default nftsListOfBundleSlice.reducer