/* eslint-disable camelcase */
import common_vi from './vi/common.json';
import common_en from './en/common.json';

const object =  {
  en: {
    common: {
      ...common_en
    },
  },
  vi: {
    common: common_vi,
  }
};

export default object
