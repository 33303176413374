import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isLoading: false,
}

const accountBalanceSlice = createSlice({
    name:"accountBalance",
    initialState: initialState,
    reducers: {
        getAccountBalance(state, action){
            return { isLoading: true }
        },
        getAccountBalanceSuccess(state, action){
            return { isLoading: false, data: action.payload }
        },
        getAccountBalanceFailure(state, action){
            return { isLoading: false }
        },
        cleanAccountBalance(state, action){
            return { isLoading: false }
        }
    }
})

export const { cleanAccountBalance, getAccountBalance, getAccountBalanceFailure, getAccountBalanceSuccess } = accountBalanceSlice.actions

export default accountBalanceSlice.reducer