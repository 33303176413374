
import loadWeb3 from "./loadWeb3"
import Web3 from "web3";

const connectMetaMask = async () => {
    let success = false
    let account = ""
    let chainId = ""

    const isLoadWeb3 = await loadWeb3()

    if(isLoadWeb3){
        try {
            const web3 = new Web3(window.web3.currentProvider);
            const temp_account = await web3.eth.getAccounts();
            const temp_chainId = await web3.eth.getChainId();
    
            success = true
            account = temp_account
            chainId = temp_chainId

        } catch (error) {
            success = false
        }
    }
    else {
       success = false
    }

    return {success, account, chainId}
}

export default connectMetaMask