import { takeLatest, call, put } from 'redux-saga/effects'
import { LINK_API } from "../../../constants/API"
import fetchData from "../fetchData"
import { getListAllSets, getListAllSetsFailure, getListAllSetsSuccess } from "../../reducer/list/listAllSetsSlice"

function* fetchListAllSets (payload) {
    try {
        const tempPayload = payload?.payload

        const page = tempPayload?.page ? `&page=${tempPayload?.page}` : ""

        const likeAddress = tempPayload?.likeAddress ? `&likeAddress=${tempPayload?.likeAddress}` : ""

        const status = tempPayload?.status ? `&status=${tempPayload?.status}` : "&status=ONSALE,NOTFORSALE,INCOMING,SOLD"
        
        const sort = tempPayload?.sort ? `&sort=${tempPayload?.sort}` : ""

        const  networkId = tempPayload?.networkId ? `&networkId=${tempPayload?.networkId}` : ""

        const labelId = tempPayload?.labelId ? `&labelId=${tempPayload?.labelId}` : ""

        const url = LINK_API.GET_LIST_SETS + "?size=24" + page + likeAddress + status + sort + networkId + labelId

        const response = yield call(fetchData, url)

        const data = response.data

        yield put({type: getListAllSetsSuccess().type, payload: data})

    } catch (error) {
        yield put({ type: getListAllSetsFailure().type })
        
    }
}

const listAllSetsSaga = [
    takeLatest(getListAllSets().type, fetchListAllSets)
]

export default listAllSetsSaga