import React from "react";
import Fade from "./Fade.js";
import "./index.scss"
import SmallInfo from "./Small.Info";
import Container from "./Container.Comp/index.js";
// import AccountSidebar from "../Account.Sidebar";
import { useMediaQuery } from 'react-responsive'
import WalletSidebar from "../Wallet.Sidebar/index.js";

const Header = props => {

    const [isAccountSiderbar, setIsAccountSidebar] = React.useState(false)

    const is_responsive = useMediaQuery({ query: '(max-width: 1199px)' })

    const handleSetIsAccountSidebar = () => {
        setIsAccountSidebar(!isAccountSiderbar)
    }

    return(
        <div className="header">
            <Fade/>
            <WalletSidebar
                isOpen={isAccountSiderbar}
                toggle={handleSetIsAccountSidebar}
            />
            {
                !is_responsive ?
                    <SmallInfo/>
                : null
            }
            <Container handleSetIsAccountSidebar={handleSetIsAccountSidebar}/>
        </div>
    )
}

export default Header