import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isLoading: false,
}

const activitiesOfCollectionSlice = createSlice({
    name:"activitiesOfCollection",
    initialState: initialState,
    reducers: {
        getActivitesOfCollection(state, action){
            return {
                isLoading: true,
            }
        },
        getActivitesOfCollectionSuccess(state, action){
            return {
                isLoading: false,
                ...action.payload,
            }
        },
        getActivitesOfCollectionFailure(state, action){
            return {
                isLoading: false,
                ...action.payload
            }
        },
        cleacActivitesOfCollection(state, action){
            return {
                isLoading: false,
            }
        }
    }
})

export const { cleacActivitesOfCollection, getActivitesOfCollection, getActivitesOfCollectionFailure, getActivitesOfCollectionSuccess } = activitiesOfCollectionSlice.actions

export default activitiesOfCollectionSlice.reducer