import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isLoading: false,
    is_failure: false,
}

const bidTypeHistorySlice = createSlice({
    name:"bidTypeHistory",
    initialState: initialState,
    reducers: {
        getBidTypeHistory(state, action){
            return {
                isLoading: true,
                is_failure: false,
            }
        },
        getBidTypeHistorySuccess(state, action){
            return {
                isLoading: false,
                is_failure: false,
                ...action.payload,
            }
        },
        getBidTypeHistoryFailure(state, action){
            return {
                isLoading: false,
                is_failure: true,
            }
        },
        cleanBidTypeHistory(state, action){
            return{
                isLoading: false,
                is_failure: false,
            }
        }
    }
})

export const { 
    getBidTypeHistory, 
    getBidTypeHistoryFailure, 
    getBidTypeHistorySuccess,
    cleanBidTypeHistory,
} = bidTypeHistorySlice.actions

export default bidTypeHistorySlice.reducer