
import loadWeb3 from "./loadWeb3"

const sendTxByMetaMask = async (params) => {
    let success = false
    let data = ""
    const loadWeb3Result = await loadWeb3()

    if(loadWeb3Result){
        try {

            const txHash = await window.ethereum.request({
                method: "eth_sendTransaction",
                params: [params],
            });

            if(txHash){
                success = true
                data = {txHash}

                return{success, data}
            }
            else {
                success = false
                return {success}
            }
            
        } catch (error) {
            ;
            success = false
            return {success}
        }
    }
    else {
        success = false
        return {success}
    }
} 

export default sendTxByMetaMask