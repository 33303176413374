import React from "react";
import { useTranslation } from "react-i18next";
import DotsVerticalIcon from "mdi-react/DotsVerticalIcon"
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { getAccountBalance } from "../../../redux/reducer/config.balance/accountBalanceSlice";
import Web3 from "web3"
import WrapModal from "./Wrap.Modal";
import { handleRenderPrice } from "../../../constants/handleRenderPrice";
import wrapBalance from "../utils/wrapBalance";
import CONTRACT_TYPE from "../../../constants/CONTRACT_TYPE";
import { getWrapperBalance } from "../../../redux/reducer/config.balance/wrapperBalanceSlice";
import RenderPrice from "../../Render.Price";

const AccountBalance = props => {
    const { t } = useTranslation("common")
    const {
        setOnProcess,
        isOpen,
    } = props

    const dispatch = useDispatch()
    const currentNetworkSlice = useSelector(state => state.currentNetworkSlice)
    const currentBcAddressSlice = useSelector(state => state.currentBcAddressSlice)
    const accountBalanceSlice = useSelector(state => state.accountBalanceSlice)
    const acc_balance_eth = accountBalanceSlice?.data ? parseFloat(Web3.utils.fromWei(parseInt(accountBalanceSlice?.data, 16).toString(),"ether")) : 0
    const walletTypeSlice = useSelector(state => state.walletTypeSlice)
    const vbcWalletEncPassSlice = useSelector(state => state.vbcWalletEncPassSlice)
    const currentVBCAccountSlice = useSelector(state => state.currentVBCAccountSlice)

    const handleGetAccountBlance = React.useCallback(() => {
        const body = {
            url: currentNetworkSlice?.domain + `${currentNetworkSlice?.networkId?.toString() === "97" ? "" : "VBC001"}`,
            params: {
                jsonrpc: "2.0",
                id: currentNetworkSlice?._id,
                params: [currentBcAddressSlice, "latest"],
                method: "eth_getBalance",
            }
        }
        if(isOpen) {
            dispatch(getAccountBalance(body))
        }
    }, [currentNetworkSlice, currentBcAddressSlice, dispatch, isOpen])

    React.useEffect(() => {
        handleGetAccountBlance()
    }, [handleGetAccountBlance])

    const [is_wrap_modal, setIsWrapModal] = React.useState(false)
    const openModal = () => {
        if(handleRenderPrice(acc_balance_eth, 4) > 0) {
            setIsWrapModal(!is_wrap_modal)
        }
        else {
            setOnProcess({status: "warning", hideCancel: true, title: t("notEnoughAccountBalance")})
        }
    }

    const handleGetWrapperBalance = React.useCallback(() => {
        const body = {
            network_id: currentNetworkSlice?.networkId?.toString(),
            function_name: "balanceOf",
            param_list: [currentBcAddressSlice, 1],
            contract_type: CONTRACT_TYPE.WRAPPER
        }
        dispatch(getWrapperBalance(body))
    }, [dispatch, currentBcAddressSlice, currentNetworkSlice])

    const handleWrap = async (amount, formikOb) => {
        setOnProcess({status: "loading"})
        setIsWrapModal(!is_wrap_modal)
        try {
            const wrap_params = {
                "network_id": currentNetworkSlice?.networkId?.toString(),
                "token_address": null,
                "is_native_token": true,
                "value": Web3.utils.toWei(amount?.toString(), "ether"),
                "is_metamask": walletTypeSlice === "MetaMask" ? true : false,
                "bc_address": currentBcAddressSlice,
                walletTypeSlice: walletTypeSlice ? walletTypeSlice : "",
                currentNetworkSlice: currentNetworkSlice ? currentNetworkSlice : "",
                vbcWalletEncPassSlice: vbcWalletEncPassSlice ? vbcWalletEncPassSlice : "",
                encPrivateKey: currentVBCAccountSlice?.encPrivateKey,
                currentBcAddressSlice: currentBcAddressSlice ? currentBcAddressSlice : ""
            }
            const wrap_res = await wrapBalance(wrap_params)
            if(wrap_res?.success){
                setOnProcess({status: "closeAlert"})
                handleGetAccountBlance()
                handleGetWrapperBalance()
                formikOb.resetForm()
            }
            else {
                setOnProcess({status: "error"})
                formikOb.resetForm()
            }
        } catch (error) {
            setOnProcess({status: "error"})
            formikOb.resetForm()
        }
    }

    return (
        <>
            <WrapModal
                toggle={() => setIsWrapModal(!is_wrap_modal)}
                isOpen={is_wrap_modal}
                handleWrap={handleWrap}
            />
            <div className="waSi__bala">
                <div className={
                    `
                        waSi__bala--title
                        ${currentNetworkSlice?.networkId?.toString() === "97" ? "bnbAcc" : "vbcAcc"}
                    `
                }
                
                >
                    <div className="waSi__bala--contentTitle">
                        {t("accountBalance")}
                    </div>
                    <div className="waSi__bala--options">
                        <UncontrolledDropdown direction="start">
                            <DropdownToggle tag={"span"}>
                                <div className="waSi__bala--opt">
                                    <DotsVerticalIcon/>
                                </div>
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem onClick={openModal}>
                                    <div className="dropdown__item">
                                        {t("wrap")}
                                    </div>
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </div>
                </div>
                <div className="waSi__bala--value">
                    <RenderPrice
                        type={currentNetworkSlice?.networkId?.toString() === "97" ? "BNB" : "VBC"}
                        data={acc_balance_eth}
                    />
                </div>
            </div>
        </>
    )
}

export default AccountBalance