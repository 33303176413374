import React from "react";
import EBSLogo from "../../../assets/images/WalletLogo/EBSLogo.webp"
import { X } from "react-feather";

const Close = props => {
    const {
        toggle,
    } = props

    return (
        <div className="headerSidebar__close">
            <img
                src={EBSLogo}
                alt=""
            />

            <div>
                <div className="headerSidebar__close--btn" onClick={toggle}>
                    <X/>
                </div>
            </div>
        </div>
    )
}

export default Close