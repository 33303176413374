import { takeLatest, call, put } from 'redux-saga/effects'
import { LINK_API } from "../../../constants/API"
import fetchData from "../fetchData"
import { getHotNFTList, getHotNFTListFailure, getHotNFTListSuccess } from "../../reducer/config.profile/hotNFTListSlice"

function* fetchHotNFTList (payload) {
    try {

        const temp_payload = payload?.payload

        const params = {
            page: temp_payload?.page || 1,
            size: 12,
            isSet: 0,
            isBundle: 0,
            sort: "like",
            owner: temp_payload?.owner,
            status: "ONSALE",
            hotNFT: 1 ,
            likeAddress: temp_payload?.likeAddress
        }

        const url = LINK_API.GET_LIST_NFTS
        const response = yield call(fetchData, url, "GET", {}, params)

        const data = response.data
 
        yield put({type: getHotNFTListSuccess().type, payload: data})

    } catch (error) {
        yield put({ type: getHotNFTListFailure().type })
        
    }
}

const hotNFTListSaga = [
    takeLatest(getHotNFTList().type, fetchHotNFTList)
]

export default hotNFTListSaga