import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isLoading: false,
    is_failure: false,
}

const hotNFTsListOfUserSlice = createSlice({
    name:"hotNFTsListOfUser",
    initialState: initialState,
    reducers: {
        getHotNFTsListOfUser(state, action){
            return {
                isLoading: true,
                is_failure: false,
            }
        },
        getHotNFTsListOfUserSuccess(state, action){
            return {
                isLoading: false,
                is_failure: false,
                ...action.payload,
            }
        },
        getHotNFTsListOfUserFailure(state, action){
            return {
                isLoading: false,
                is_failure: true,
            }
        },
        cleanHotNFTsListOfUser(state, action){
            return {
                isLoading: false,
                is_failure: false,
            }
        }
    }
})

export const { cleanHotNFTsListOfUser, getHotNFTsListOfUser, getHotNFTsListOfUserFailure, getHotNFTsListOfUserSuccess } = hotNFTsListOfUserSlice.actions

export default hotNFTsListOfUserSlice.reducer