import { takeLatest, call, put } from 'redux-saga/effects'
import { LINK_API } from "../../../constants/API"
import { getListCategories, getListCategoriesFailure, getListCategoriesSuccess } from "../../reducer/list/listCategoriesSlice"
import Axios from 'axios'

const fetchData = () => {
    return Axios({
        method: "GET",
        url: LINK_API.GET_LIST_CATEGORIES,
        headers: {
            "marketplace-key": process.env.REACT_APP_MARKETPLACE_KEY
        }
    })
}

function* fetchListCategories (payload) {
    try {

        const response = yield call(fetchData)

        const data = response.data

        yield put({type: getListCategoriesSuccess().type, payload: data})

    } catch (error) {
        yield put({ type: getListCategoriesFailure().type })
        
    }
}

const listCategoriesSaga = [
    takeLatest(getListCategories().type, fetchListCategories)
]

export default listCategoriesSaga