import { takeLatest, call, put } from 'redux-saga/effects'
import { LINK_API } from "../../../constants/API"
// import fetchData from "../fetchData"
import { getListNetwork, getListNetworkFailure, getListNetworkSuccess } from "../../reducer/config.network/listNetworkSlice"
import Axios from 'axios'

function fetchData(url) {
    return Axios({
        method: "GET",
        url: url,
        headers: {
            "marketplace-key": process.env.REACT_APP_MARKETPLACE_KEY
        }
    })
}

function* fetchListNetwork (payload) {
    try {

        const url = LINK_API.GET_LIST_NETWORK

        const response = yield call(fetchData, url)

        const data = response.data

        const listNetworks0 = data?.networks0
        const listNetworks1 = data?.networks1
        const listNetworks2 = data?.networks2

        const renderList1 = listNetworks0 ? listNetworks0 : []
        const renderList2 = listNetworks1 ? renderList1.concat(listNetworks1) : []
        const renderList3 = listNetworks2 ? renderList2.concat(listNetworks2) : []

        yield put({type: getListNetworkSuccess().type, payload: renderList3})

    } catch (error) {
        yield put({ type: getListNetworkFailure().type })
        
    }
}

const listNetworkSaga = [
    takeLatest(getListNetwork().type, fetchListNetwork)
]

export default listNetworkSaga