import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import MetamaskLogo from "../../../assets/images/WalletLogo/MetamaskLogo.svg"
import Axios from "axios"
import { LINK_API } from "../../../constants/API";
import { saveBcAddress } from "../../../redux/reducer/config.wallet/currentBcAddressSlice";
import { saveMetaMaskWalletType } from "../../../redux/reducer/config.wallet/walletTypeSlice";
import { setIsConnectWallet } from "../../../redux/reducer/config.wallet/isConnectWalletSlice";
import Web3 from "web3"
import { saveCurrentNetwork } from "../../../redux/reducer/config.network/currentNetworkSlice";
import switchNetworkOnMetaMask from "../../../utils/switchNetworkOnMetaMask";
import connectMetaMask from "../../../utils/connectMetaMask";
import checkIsMetaMaskInstalled from "../../../utils/checkIsMetaMaskInstalled";

const ConnectMetaMask = props => {
    const { t } = useTranslation("common")
    const { setOnProcess } = props

    const currentNetworkSlice = useSelector(state => state.currentNetworkSlice)
    const listNetworkSlice = useSelector(state => state.listNetworkSlice)
    const accountSlice = useSelector(state => state.accountSlice)
    const detailProfileAccountSlice = useSelector(state => state.detailProfileAccountSlice)
    const dispatch = useDispatch()

    const handleCheckBcAddressOnVBC = async (chainId, account) => {
        await Axios({
            method: "GET",
            url: LINK_API.CHECK_BC_ADDRESS,
            params: {"bc_address": account}
        })
        .then(async(res) => {
            if(res?.data?.data){
                const owned_bc_address = detailProfileAccountSlice?.data?.bc_address_mints ? detailProfileAccountSlice?.data?.bc_address_mints : []
                const find_bc_address = owned_bc_address.find(bc_address => bc_address.toString() === account?.toString())
                if(find_bc_address){
                    dispatch(saveBcAddress(find_bc_address))
                    dispatch(saveMetaMaskWalletType())
                    dispatch(setIsConnectWallet())
                    setOnProcess({status: "closeAlert"})
                }
                else {
                    setOnProcess({status: "warning", hideCancel: true, title: t("bcAddrInUse"), subTitle: t("plsUseAnotherAddr")})
                }
            }
            else {
                Axios.defaults.headers.common.Authorization = `Bearer ${accountSlice?.data?.token}`;
                await Axios({
                    method: "POST",
                    url: LINK_API.ADD_ADDRESS_TO_VBCHAIN_ACCOUNT,
                    data: {
                        "new_bc_address": account
                    },
                })
                .then(res => {
                    dispatch(saveBcAddress(account))
                    dispatch(saveMetaMaskWalletType())
                    dispatch(setIsConnectWallet())
                    setOnProcess({status: "closeAlert"})
                })
                .catch(error => {
                    setOnProcess({status: "error"})
                })
            }
        })
        .catch(error => {
            setOnProcess({status: "error"})
        })
    }

    const handleCheckNetwork = async (chainId, account) => {
        const find_network_of_vbc = listNetworkSlice?.data?.find(nw => nw?.networkId?.toString() === chainId?.toString())
        if(find_network_of_vbc){
            dispatch(saveCurrentNetwork(find_network_of_vbc))
            await handleCheckBcAddressOnVBC(chainId, account)
        }
        else {
            const switch_nw_res = await switchNetworkOnMetaMask(currentNetworkSlice)
            if(switch_nw_res?.success){
                await handleCheckBcAddressOnVBC(chainId, account)
            }
            else {
                setOnProcess({status: "error"})
            }
        }
    }

    const handleConnectMetaMask = async () => {
        const connect_res = await connectMetaMask()
        if(connect_res?.success && connect_res?.chainId && connect_res?.account[0]){
            const checksum_acc = Web3.utils.toChecksumAddress(connect_res?.account[0])
            await handleCheckNetwork(connect_res?.chainId, checksum_acc)
        }
        else {
            setOnProcess({status: "error"})
        }
    }

    const checkMetaMaskInstalled = async () => {
        setOnProcess({status: "loading"})
        const is_metamask_installed = await checkIsMetaMaskInstalled()

        if(is_metamask_installed){
            await handleConnectMetaMask()
        }
        else {
            setOnProcess({status: "error"})
        }
    }

    return (
        <div className="container">
            <div className="waSi__selectWallet"
                onClick={checkMetaMaskInstalled}
            >
                <img
                    src={MetamaskLogo}
                    alt=""
                />
                <div>
                    {t("wallet.MetaMask")}
                </div>
            </div>
        </div>
    )
}

export default ConnectMetaMask