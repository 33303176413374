import { takeLatest, call, put } from 'redux-saga/effects'
import { LINK_API } from "../../../constants/API"
import fetchData from "../fetchData"
import { getOnSaleNFTsListOfUser, getOnSaleNFTsListOfUserFailure, getOnSaleNFTsListOfUserSuccess } from "../../reducer/config.user.detail/onSaleNFTsListOfUserSlice"

function* fetchOnSaleNFTsListOfUser (payload) {
    try {

        const temp_payload = payload?.payload

        const params = {
            page: temp_payload?.page || 1,
            size: 12,
            isSet: 0,
            isBundle: 0,
            sort: "like",
            owner: temp_payload?.owner,
            status: "ONSALE",
            likeAddress: temp_payload?.likeAddress
        }

        const url = LINK_API.GET_LIST_NFTS
        const response = yield call(fetchData, url, "GET", {}, params)

        const data = response.data
 
        yield put({type: getOnSaleNFTsListOfUserSuccess().type, payload: data})

    } catch (error) {
        yield put({ type: getOnSaleNFTsListOfUserFailure().type })
        
    }
}

const onSaleNFTsListOfUserSaga = [
    takeLatest(getOnSaleNFTsListOfUser().type, fetchOnSaleNFTsListOfUser)
]

export default onSaleNFTsListOfUserSaga