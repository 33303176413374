import { takeLatest, call, put } from 'redux-saga/effects'
import { LINK_API } from "../../../constants/API"
import fetchData from "../fetchData"
import { getDetailNFT, getDetailNFTFailure, getDetailNFTSuccess } from "../../reducer/config.nft.detail/detailNFTSlice"

function* fetchDetailNFT (payload) {
    try {
        const temp_payload = payload?.payload

        const collectionAddr = temp_payload?.collectionAddr ? `?collectionAddr=${temp_payload?.collectionAddr}` : ""
        const tokenId = temp_payload?.tokenId ? `&tokenId=${temp_payload?.tokenId}` : ""
        const likeAddress = temp_payload?.likeAddress ? `&likeAddress=${temp_payload?.likeAddress}` : ""

        let url = LINK_API.GET_DETAIL_NFT + collectionAddr + tokenId + likeAddress

        const response = yield call(fetchData, url)

        const data = response?.data

        yield put({type: getDetailNFTSuccess().type, payload: data?.data})

    } catch (error) {
        yield put({ type: getDetailNFTFailure().type })
        
    }
}

const detailNFTSaga = [
    takeLatest(getDetailNFT().type, fetchDetailNFT)
]

export default detailNFTSaga