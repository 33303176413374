import Axios from 'axios'
import { takeLatest, call, put } from 'redux-saga/effects'
import { LINK_API } from "../../../constants/API"
import { getPropertiesOfSet, getPropertiesOfSetFailure, getPropertiesOfSetSuccess  } from "../../reducer/config.set.detail/propertiesOfSetSlice"

const fetchData = url => {  
    return Axios({
        method: "GET",
        url,
        headers: {
            "marketplace-key": process.env.REACT_APP_MARKETPLACE_KEY
        }
    })
}

function* fetchPropertiesOfSet (payload) {
    try {
        const url = LINK_API.GET_PROPERTIES_OF_NFT + `?set_id=${payload?.payload}`
        const response = yield call(fetchData, url)
        const data = response.data
        yield put({type: getPropertiesOfSetSuccess().type, payload: data})
    } catch (error) {
        yield put({ type: getPropertiesOfSetFailure().type })
    }
}

const propertiesOfSetSaga = [
    takeLatest(getPropertiesOfSet().type, fetchPropertiesOfSet)
]

export default propertiesOfSetSaga