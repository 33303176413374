import { createSlice } from "@reduxjs/toolkit";

const initialState = []

const selectedNFTOnSetSlice = createSlice({
    name:"selectedNFTOnSet",
    initialState: initialState,
    reducers: {
        addNewNFTIntoSelectedNFTOnSet(state, action){
            const old_list = state

            let temp_list = []

            const find_nft = old_list.find(item => item?.id === action?.payload?.id) ? true : false

            if(find_nft){
                const new_list = old_list.filter(item => item?.id !==  action?.payload?.id)
                temp_list = new_list
            }
            else {
                temp_list = old_list?.concat([ action?.payload])
            }

            return temp_list
        },
        selectAllNFTsInSet(state, action) {
            return action.payload
        },
        cleanSelectedNFTOnSet(state, action){
            return []
        }
    }
})

export const { cleanSelectedNFTOnSet, addNewNFTIntoSelectedNFTOnSet, selectAllNFTsInSet } = selectedNFTOnSetSlice.actions

export default selectedNFTOnSetSlice.reducer