

const checkIsMetaMaskInstalled = async () => {
    const isMetaMaskInstalled = await window?.ethereum?.isMetaMask

    if(isMetaMaskInstalled){
        return true
    }
    else {
        return false
    }
}

export default checkIsMetaMaskInstalled