const renderAddress = address => {
    let res = ""

    try {
        if(address) {
            res = address?.slice(0, 4) + "..." + address?.slice(address?.length - 4, address?.length)
        }
        
    } catch (error) {
        
    }

    return res
}

export default renderAddress