import { combineReducers } from 'redux'
import storage from 'redux-persist/lib/storage'
import axios from 'axios'

// Marketplace
import listPopularItemsSlice from './list/listPopularItemsSlice'
import listCategoriesSlice from './list/listCategoriesSlice'
import listPopularCollectionsSlice from './list/listPopularCollectionsSlice'
import listTrendingAuctionsSlice from './list/listTrendingAuctionsSlice'
import listPopularSetsSlice from './list/listPopularSetsSlice'
import nftsListOfMostPopularCollectionSlice from './list/nftsListOfMostPopularCollectionSlice'
import featuredCreatorsListSlice from './list/featuredCreatorsListSlice'
import popularBundlesListSlice from './list/popularBundlesListSlice'

// Profile
import accountSlice from './config.profile/accountSlice'
import detailProfileAccountSlice from './config.profile/detailProfileAccountSlice'
import orderHistorySlice from './config.profile/orderHistorySlice'
import hotNFTListSlice from './config.profile/hotNFTListSlice'
import collectionsListOfProfileSlice from './config.profile/collectionsListOfProfileSlice'
import onSaleNFTsListSlice from './config.profile/onSaleNFTsListSlice'
import ownedNFTsListSlice from './config.profile/ownedNFTsListSlice'
import bidTypeHistorySlice from './config.profile/bidTypeHistorySlice'

// language
import language from "./language"

// Wallet
import isConnectWalletSlice from './config.wallet/isConnectWalletSlice'
import listNetworkSlice from './config.network/listNetworkSlice'
import currentNetworkSlice from './config.network/currentNetworkSlice'
import listVBCWalletSlice from './config.wallet/listVBCWalletSlice'
import vbcWalletEncPassSlice from './config.wallet/vbcWalletEncPassSlice'
import currentBcAddressSlice from './config.wallet/currentBcAddressSlice'
import currentVBCWalletInfoSlice from './config.wallet/currentVBCWalletInfoSlice'
import currentVBCAccountSlice from './config.wallet/currentVBCAccountSlice'
import walletTypeSlice from "./config.wallet/walletTypeSlice"
import listVBCAccountSlice from './config.wallet/listVBCAccountSlice'
import totalBidBalanceSlice from './config.wallet/totalBidBalanceSlice'

// Balance
import accountBalanceSlice from './config.balance/accountBalanceSlice'
import wrapperBalanceSlice from './config.balance/wrapperBalanceSlice'

// List All
import listAllNFTsSlice from './list/listAllNFTsSlice'
import listAllSetsSlice from './list/listAllSetsSlice'
import listAllCollectionsSlice from "./list/listAllCollectionsSlice"
import allBundlesListSlice from './list/allBundlesListSlice'

// NFT
import listProvenanceOfNFTSlice from './config.nft.detail/listProvenanceOfNFTSlice'
import listPropertiesOfNFTSlice from './config.nft.detail/listPropertiesOfNFTSlice'
import bidHistoryOfNFTSlice from "./config.nft.detail/bidHistoryOfNFTSlice"
import detailNFTSlice from './config.nft.detail/detailNFTSlice'
import inspectionReportOfNFTSlice from './config.nft.detail/inspectionReportOfNFTSlice'

// Collection
import detailCollectionSlice from './config.collection.detail/detailCollectionSlice'
import listNFTsOfCollectionSlice from './config.collection.detail/listNFTsOfCollectionSlice'
import activitiesOfCollectionSlice from './config.collection.detail/activitiesOfCollectionSlice'
import listSetsOfCollectionSlice from './config.collection.detail/listSetsOfCollectionSlice'

// Set
import detailSetSlice from './config.set.detail/detailSetSlice'
import nftListOfSetSlice from './config.set.detail/nftListOfSetSlice'
import selectedNFTOnSetSlice from './config.set.detail/selectedNFTOnSetSlice'
import propertiesOfSetSlice from './config.set.detail/propertiesOfSetSlice'
import bidListOfSetSlice from './config.set.detail/bidListOfSetSlice'

// Bundle
import selectedNFTsListOfBundleSlice from './config.bundle.detail/selectedNFTsListOfBundleSlice'
import detailBundleSlice from './config.bundle.detail/detailBundleSlice'
import nftsListOfBundleSlice from './config.bundle.detail/nftsListOfBundleSlice'

// User
import detailUserSlice from './config.user.detail/detailUserSlice'
import currentAddressOfUserSlice from './config.user.detail/currentAddressOfUserSlice'
import hotNFTsListOfUserSlice from './config.user.detail/hotNFTsListOfUserSlice'
import onSaleNFTsListOfUserSlice from './config.user.detail/onSaleNFTsListOfUserSlice'
import collectionListOfUserSlice from './config.user.detail/collectionListOfUserSlice'

//Other
import fakePassphraseSlice from './fakePassphraseSlice'

const appReducer = combineReducers({
    // Marketplace
    listPopularItemsSlice,
    listCategoriesSlice,
    listPopularCollectionsSlice,
    listTrendingAuctionsSlice,
    listPopularSetsSlice,
    nftsListOfMostPopularCollectionSlice,
    featuredCreatorsListSlice,
    popularBundlesListSlice,

    // Profile
    accountSlice,
    detailProfileAccountSlice,
    orderHistorySlice,
    hotNFTListSlice,
    collectionsListOfProfileSlice,
    onSaleNFTsListSlice,
    ownedNFTsListSlice,
    bidTypeHistorySlice,

    // language
    language,

    // Wallet
    listNetworkSlice,
    currentNetworkSlice,
    currentBcAddressSlice,
    isConnectWalletSlice,
    listVBCWalletSlice,
    vbcWalletEncPassSlice,
    currentVBCWalletInfoSlice,
    currentVBCAccountSlice,
    walletTypeSlice,
    listVBCAccountSlice,
    totalBidBalanceSlice,

    // Balance
    accountBalanceSlice,
    wrapperBalanceSlice,

    // List All
    listAllNFTsSlice,
    listAllSetsSlice,
    listAllCollectionsSlice,
    allBundlesListSlice,

    // NFT
    listProvenanceOfNFTSlice,
    listPropertiesOfNFTSlice,
    bidHistoryOfNFTSlice,
    detailNFTSlice,
    inspectionReportOfNFTSlice,

    // Collection
    detailCollectionSlice,
    listNFTsOfCollectionSlice,
    activitiesOfCollectionSlice,
    listSetsOfCollectionSlice,

    // Set
    detailSetSlice,
    nftListOfSetSlice,
    selectedNFTOnSetSlice,
    propertiesOfSetSlice,
    bidListOfSetSlice,

    // Bundle
    selectedNFTsListOfBundleSlice,
    detailBundleSlice,
    nftsListOfBundleSlice,

    // User
    detailUserSlice,
    currentAddressOfUserSlice,
    hotNFTsListOfUserSlice,
    onSaleNFTsListOfUserSlice,
    collectionListOfUserSlice,

    // Other
    fakePassphraseSlice,
})

const rootReducer = (state, action) => {
    if (action.type === 'CLEAN_STORE') {
        state = undefined
        storage.removeItem('persist:root')
        axios.defaults.headers.common.Authorization = ``;
    }

    return appReducer(state, action)
}
  
  export default rootReducer
