import { takeLatest, call, put } from 'redux-saga/effects'
import { LINK_API } from "../../../constants/API"
import fetchData from "../fetchData"
import { getDetailCollection, getDetailCollectionFailure, getDetailCollectionSuccess } from "../../reducer/config.collection.detail/detailCollectionSlice"

function* fetchDetailCollection (payload) {
    try {
        const temp_payload = payload?.payload

        const id = temp_payload?.id ? `?id=${temp_payload?.id}` : ""
        const likeAddress = temp_payload?.likeAddress ? `&likeAddress=${temp_payload?.likeAddress}` : ""

        const url = LINK_API.GET_DETAIL_COLLECTION + id + likeAddress

        const response = yield call(fetchData, url)

        const data = response?.data?.data

        yield put({type: getDetailCollectionSuccess().type, payload: data})

    } catch (error) {
        yield put({ type: getDetailCollectionFailure().type, payload: {isError: true} })
        
    }
}

const detailCollectionSaga = [
    takeLatest(getDetailCollection().type, fetchDetailCollection)
]

export default detailCollectionSaga