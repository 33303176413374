import { takeLatest, call, put } from 'redux-saga/effects'
import { LINK_API } from "../../../constants/API"
import fetchData from "../fetchData"
import { getListNFTsOfCollection, getListNFTsOfCollectionFailure, getListNFTsOfCollectionSuccess } from "../../reducer/config.collection.detail/listNFTsOfCollectionSlice"

function* fetchListNFTsOfCollection (payload) {
    try {
        const temp_payload = payload?.payload

        const page = temp_payload?.page ? `&page=${temp_payload?.page}` : ""
        const networkId = temp_payload?.networkId ? `&networkId=${temp_payload?.networkId}` : ""
        const collectionAddr = temp_payload?.collectionAddr ? `&collectionAddr=${temp_payload?.collectionAddr}` : ""
        const baseType = temp_payload?.baseType ? `&baseType=${temp_payload?.baseType}` : ""
        const likeAddress = temp_payload?.likeAddress ? `&likeAddress=${temp_payload?.likeAddress}` : ""

        const url = LINK_API.GET_LIST_NFTS + "?size=24" + page + collectionAddr + baseType + likeAddress + networkId + "&status=ONSALE,NOTFORSALE,INCOMING,SOLD"

        const response = yield call(fetchData, url)

        const data = response?.data?.data

        yield put({type: getListNFTsOfCollectionSuccess().type, payload: data})

    } catch (error) {
        yield put({ type: getListNFTsOfCollectionFailure().type})
        
    }
}

const listNFTsOfCollectionSaga = [
    takeLatest(getListNFTsOfCollection().type, fetchListNFTsOfCollection)
]

export default listNFTsOfCollectionSaga