import { createSlice } from "@reduxjs/toolkit";

const initialState = ""

const walletTypeSlice = createSlice({
    name:"walletType",
    initialState: initialState,
    reducers: {
        saveVBCWalletType(state, action){
            return "VBchain"
        },
        saveMetaMaskWalletType(state, action){
            return "MetaMask"
        },
        cleanWalletType(state, action){
            return ""
        },
    }
})

export const { saveVBCWalletType, saveMetaMaskWalletType, cleanWalletType } = walletTypeSlice.actions

export default walletTypeSlice.reducer