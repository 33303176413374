import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isSignedIn: false,
}

const accountSlide = createSlice({
    name:"account",
    initialState: initialState,
    reducers: {
        saveAccountInfo(state, action){
            return {
                isSignedIn: true,
                ...action.payload,
            }
        },
        cleanAccountInfo(state, action){
            return{
                isSignedIn: false,
            }
        }
    }
})

export const { saveAccountInfo, cleanAccountInfo } = accountSlide.actions

export default accountSlide.reducer