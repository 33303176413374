const STAGING_DASHBOARD = process?.env?.REACT_APP_STAGING_DASHBOARD
const STAGING_MARKETPLACE = process?.env?.REACT_APP_STAGING_MARKETPLACE
const PRODUCTION_DASHBOARD = process?.env?.REACT_APP_PRODUCTION_DASHBOARD
const PRODUCTION_MARKETPLACE = process?.env?.REACT_APP_PRODUCTION_MARKETPLACE

const environment = process.env.REACT_APP_MARKETPLACE_ENV
const MARKETPLACE_HOST = environment === "staging" || environment === "dev" ? STAGING_MARKETPLACE : environment === "production" ? PRODUCTION_MARKETPLACE : ""
const DASHBOARD_HOST = environment === "staging" || environment === "dev" ? STAGING_DASHBOARD : environment === "production" ? PRODUCTION_DASHBOARD : ""

const LINK_API = {

    // PROFILE FROM DASHBOARD
    SIGN_IN_DASHBOARD_ACCOUNT: DASHBOARD_HOST + "/api/v1/account/sign-in",
    GET_DETAIL_PROFILE_DASHBOARD_ACCOUNT: DASHBOARD_HOST + "/api/v1/account/info",
    CHANGE_PASSWORD_VBC_ACCOUNT: DASHBOARD_HOST + "/api/v1/account/change-password",
    CHECK_BC_ADDRESS: DASHBOARD_HOST + "/api/v1/bc-address/is-use",
    GET_MESSAGE: DASHBOARD_HOST + "/api/v1/account/sign-in/message",
    SIGN_IN_WITH_METAMASK: DASHBOARD_HOST + "/api/v1/account/sign-in-with-metamask",
    ADD_ADDRESS_TO_VBCHAIN_ACCOUNT: DASHBOARD_HOST + "/api/v1/bc-address/add-to-account",
    UPDATE_INFO_OF_USER: DASHBOARD_HOST + "/api/v1/account/info",
    SIGN_UP_ON_DASHBOARD: DASHBOARD_HOST + "/api/v1/account/sign-up",
    VERIRY_EMAIL: DASHBOARD_HOST + "/api/v1/account/verify-email",
    RESEND_EMAIL: DASHBOARD_HOST + "/api/v1/account/resend-email",

    // WALLET FROM DASHBOARD
    GET_ALL_WALLET: DASHBOARD_HOST + "/api/v1/ETHwallets/get",
    GET_ACCOUNT_BY_PLATFORM: DASHBOARD_HOST + '/api/v1/ETHwallets/account/',
    SWITCH_ACTIVE_WALLET: DASHBOARD_HOST + '/api/v1/ETHwallets/switchActive/',
    GET_WRAPPER_BALANCE: DASHBOARD_HOST + '/api/v1/market/call',
    GET_DATA_DEPOSIT_TO_WRAPPER: DASHBOARD_HOST + "/api/v2/market/wrapper/deposit/getData",
    GET_DATA_WITHDRAW_WRAPPER: DASHBOARD_HOST + "/api/v2/market/wrapper/withdraw/getData",
    CREATE_ETH_WALLET: DASHBOARD_HOST + "/api/v1/ETHwallets/create",
    CREATE_ETH_ACCOUNT: DASHBOARD_HOST + "/api/v1/ETHwallets/account/create/",

    // INFORMATION FROM DASHBOARD
    GET_LIST_CATEGORIES: DASHBOARD_HOST +  "/api/v1/nft/categories/",
    GET_LIST_NETWORK: DASHBOARD_HOST + "/api/v1/ETHnetworks/gets",

    // NFT INFORMATION FROM DASHBOARD
    GET_PROVENANCE_OF_NFT: DASHBOARD_HOST + "/api/v1/diaries",
    GET_PROPERTIES_OF_NFT: DASHBOARD_HOST + "/api/v1/nft/properties",
    CREATE_DEFAULT_USER : DASHBOARD_HOST + "/api/v1/account/create/default",
    GET_INSPECTION_REPORT_OF_NFT: DASHBOARD_HOST + "/api/v1/nft/inspection-report/histories",

    // SUPPORT
    SEND_MAIL_SUPPORT: DASHBOARD_HOST + "/api/v1/notification/qa-email",

    // ===========================================================================================================
    // ===========================================================================================================
    // ===========================================================================================================

    // GET LIST
    GET_LIST_SETS: MARKETPLACE_HOST +  "/api/v2/show/market-sets",
    GET_LIST_NFTS: MARKETPLACE_HOST + "/api/v2/show/market-nfts",
    GET_LIST_COLLECTIONS: MARKETPLACE_HOST + "/api/v2/show/market-collections",
    GET_USER_LIST: MARKETPLACE_HOST + "/api/v2/user",
    GET_BUNDLES_LIST: MARKETPLACE_HOST + "/api/v2/show/market-bundles",
    GET_SMALL_INFO_OF_NFTS_LIST: MARKETPLACE_HOST + "/api/v2/show/market-nfts-id", 

    // DETAIL
    GET_DETAIL_NFT: MARKETPLACE_HOST + "/api/v2/show/market-nft",
    GET_DETAIL_COLLECTION: MARKETPLACE_HOST + "/api/v2/show/market-collection",
    GET_DETAIL_SET: MARKETPLACE_HOST + "/api/v2/show/market-set",
    GET_DETAIL_BUNDLE: MARKETPLACE_HOST + "/api/v2/show/market-bundle",

    // OTHER INFORMATION
    GET_ORDER_HISTORY: MARKETPLACE_HOST + "/api/v2/user/my-orders",
    GET_HISTORY_BID_OF_NFT: MARKETPLACE_HOST + "/api/v2/bid/bid",
    GET_ACTIVITIES_OF_COLLECTION: MARKETPLACE_HOST + "/api/v2/activity",
    GET_BID_TYPE_HISTORY: MARKETPLACE_HOST + "/api/v2/bid/bids-by-bidder",

    // LIKE ON MARKETPLACE
    LIKE_ON_MARKETPLACE: MARKETPLACE_HOST + "/api/v2/user/like",
    HACK_LIKE: MARKETPLACE_HOST + "/api/v2/user/hackLike",

    // Follow user
    FOLLOW_USER: MARKETPLACE_HOST + "/api/v2/user/follow",

    // BALANCE INFO
    GET_TOTAL_BID_BALANCE: MARKETPLACE_HOST + "/api/v2/bid/totalHighestBidBalance",

    // TRADE ON MARKETPLACE
    CREATE_RAWTX_BUY: MARKETPLACE_HOST + "/api/v2/trade/raw-matchOrder",
    SEND_SIGNEDTX: MARKETPLACE_HOST + "/api/v2/trade/send-rawtx",
    CREATE_RAWTX_CLAIM: MARKETPLACE_HOST + "/api/v2/bid/claim",
    UPDATE_NFT_AFTER_BUY: MARKETPLACE_HOST + "/api/v2/trade/matchOrder",
    PLACE_A_BID_NFT: MARKETPLACE_HOST + "/api/v2/bid/bid",
    CREATE_RAW_MATCH_ORDER_NFTS: MARKETPLACE_HOST + "/api/v2/trade/raw-matchOrder-nfts",
    UPDATE_NFTS_AFTER_MATCH_ORDER: MARKETPLACE_HOST + "/api/v2/trade/matchOrderNFTs",
    BUY_ALL_BUNDLE_RAW: MARKETPLACE_HOST + "/api/v2/trade/raw-matchOrder-bundle",
    UPDATE_BUNDLE_AFTER_BUY_ALL: MARKETPLACE_HOST + "/api/v2/trade/matchOrderBundle/", 
    PLACE_A_BID_SET: MARKETPLACE_HOST + "/api/v2/bid/bidSet/",
    GET_RAW_CLAIM_SET: MARKETPLACE_HOST + "/api/v2/bid/claimSet",
    UPDATE_AFTER_CLAIM_SET: MARKETPLACE_HOST + "/api/v2/trade/matchOrderSet",
}

export {
    LINK_API
}