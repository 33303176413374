import React from "react";
import Icon from "./components/icon";
import Price from "./components/price";
import "./index.scss";

const RenderPrice = props => {
    const {
        type,
        data,
    } = props

    return(
        <span className="renderPrice">
            <Icon type={type}/>
            <Price data={data} type={type} />
        </span>
    )
}

export default RenderPrice