import { takeLatest, call, put, select } from 'redux-saga/effects'
import { LINK_API } from "../../../constants/API"
import fetchData from "../fetchData"
import { getListVBCWallet, getListVBCWalletFailure, getListVBCWalletSuccess } from "../../reducer/config.wallet/listVBCWalletSlice"
import Axios from 'axios'

const accountSlice = state => state.accountSlice

function* fetchListVBCWallet (payload) {
    try {
        const account = yield select(accountSlice)

        const url = LINK_API.GET_ALL_WALLET
        
        Axios.defaults.headers.common.Authorization = `Bearer ${account?.data?.token}`;

        const response = yield call(fetchData, url)

        const data = response.data

        yield put({type: getListVBCWalletSuccess().type, payload: data})

    } catch (error) {
        yield put({ type: getListVBCWalletFailure().type })
        
    }
}

const listVBCWalletSaga = [
    takeLatest(getListVBCWallet().type, fetchListVBCWallet)
]

export default listVBCWalletSaga