import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isLoading: false,
    is_failure: false,
}

const collectionListOfUserSlice = createSlice({
    name:"collectionListOfUser",
    initialState: initialState,
    reducers: {
        getCollectionListOfUser(state, action){
            return {
                isLoading: true,
                is_failure: false,
            }
        },
        getCollectionListOfUserSuccess(state, action){
            return {
                isLoading: false,
                is_failure: false,
                ...action.payload,
            }
        },
        getCollectionListOfUserFailure(state, action){
            return {
                isLoading: false,
                is_failure: true,
            }
        },
        cleanCollectionListOfUser(state, action){
            return {
                isLoading: false,
                is_failure: false,
            }
        }
    }
})

export const { cleanCollectionListOfUser, getCollectionListOfUser, getCollectionListOfUserFailure, getCollectionListOfUserSuccess } = collectionListOfUserSlice.actions

export default collectionListOfUserSlice.reducer