import { takeLatest, call, put } from 'redux-saga/effects'
import { LINK_API } from "../../../constants/API"
import fetchData from "../fetchData"
import { getTotalBidBalance, getTotalBidBalanceFailure, getTotalBidBalanceSuccess } from "../../reducer/config.wallet/totalBidBalanceSlice"

function* fetchTotalBidBalance (payload) {
    try {
        const params = {
            paymentMethod: "0x0000000000000000000000000000000000000001",
            useWrapper: process?.env?.REACT_APP_MARKETPLACE_ENV === "production" ? true : 2,
            networkId: payload?.payload?.networkId,
        }

        const url = LINK_API.GET_TOTAL_BID_BALANCE + "/" + payload?.payload?.bidder 

        const response = yield call(fetchData, url, "GET", {} , params)

        const data = response.data

        yield put({type: getTotalBidBalanceSuccess().type, payload: data})

    } catch (error) {
        yield put({ type: getTotalBidBalanceFailure().type })
        
    }
}

const totalBidBalanceSaga = [
    takeLatest(getTotalBidBalance().type, fetchTotalBidBalance)
]

export default totalBidBalanceSaga