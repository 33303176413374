import { createSlice } from "@reduxjs/toolkit";

const initialState = {}

const currentVBCWalletInfoSlice = createSlice({
    name:"currentVBCWalletInfo",
    initialState: initialState,
    reducers: {
        saveVBCWallet(state, action){
            return {...action.payload}
        },
        cleanVBCWallet(state, action){
            return {}
        }
    }
})

export const { saveVBCWallet, cleanVBCWallet } = currentVBCWalletInfoSlice.actions

export default currentVBCWalletInfoSlice.reducer