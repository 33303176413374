import React from "react";
import { useSelector } from "react-redux";
import "./index.scss"
import MetaMaskAddress from "./MetaMask.Address";
import VBCAddress from "./VBC.Address";

const Address = props => {
    const { setOnProcess } = props
    const walletTypeSlice = useSelector(state => state.walletTypeSlice)

    return (
        <div className="container waSi__address mt-3">
            {
                walletTypeSlice === "MetaMask" ?
                <MetaMaskAddress setOnProcess={setOnProcess} />
                :
                walletTypeSlice === "VBchain" ?
                <VBCAddress/>
                :
                null
            }
        </div>
    )
}

export default Address