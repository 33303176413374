import React from "react";
import './index.scss'
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux'
import { Row, Col } from 'reactstrap'
import { withTranslation } from "react-i18next";
import ChevronRightIcon from "mdi-react/ChevronRightIcon"
import VietnamFlag from '../../assets/images/Flag/VietnamFlag.png'
import UKFlag from '../../assets/images/Flag/UKFlag.png'

const Footer = props => {
    const {
        i18n,
        t,
        language,
        saveLang
    } = props

    const handleChangeLang = async (lang) => {
        await i18n.changeLanguage(lang)
    }

    React.useEffect(() => {
        i18n.changeLanguage(language)
    }, [i18n, language])

    return(
        <div className="footer">
            <div className="container">
                <Row className="row">
                    <Col className="col" xl="3" lg="3" md="6" sm="12" xs="12">
                        <div className="footer__title">
                            {t("footer.aProductOf")}
                        </div>
                        <div className="footer__line"></div>
                        <img
                            src="https://vietnamblockchain.asia/static/media/logo.1cd2e67d.png"
                            alt="img"
                            style={{width: "90%"}}
                            className="cursor-pointer"
                        />
                        <div className="mt-3">
                            <a
                                href="https://vietnamblockchain.asia/"
                                target="_blank"
                                rel="noreferrer" 
                                className="footer__linkVBC cursor-pointer"
                            >
                                vietnamblockchain.asia
                            </a>
                        </div>
                    </Col>
                    <Col className="col" xl="3" lg="3" md="6" sm="12" xs="12">
                        <div className="footer__title">
                            {t("footer.contact")}
                        </div>
                        <div className="footer__line"></div>
                        <div>
                            028 6271 7798
                        </div>
                        <div className="mt-3">
                            contact@vietnamblockchain.asia
                        </div>
                        <div className="mt-3">
                            {t("footer.address")}
                        </div>
                    </Col>
                    <Col className="col" xl="3" lg="3" md="6" sm="12" xs="12">
                        <div className="footer__title">
                            {t("footer.legal")}
                        </div>
                        <div className="footer__line"></div>
                        <div className="footer__legal--item">
                            <ChevronRightIcon/>
                            {t("footer.privacyPolicy")}
                        </div>
                        <div className="mt-3 footer__legal--item">
                            <ChevronRightIcon/>
                            {t("footer.termOfService")}
                        </div>
                    </Col>
                    <Col className="col" xl="3" lg="3" md="6" sm="12" xs="12">
                        <div className="footer__title">
                            {t("footer.language")}
                        </div>
                        <div className="footer__line"></div>
                        <div className="footer__lang--item" onClick={() => {handleChangeLang("vi"); saveLang("vi")}}>
                            <img
                                src={VietnamFlag}
                                alt=""
                            />
                            {t("vietnamese")}
                        </div>
                        <div  className="mt-3 footer__lang--item" onClick={() => {handleChangeLang("en"); saveLang("en")}}>
                            <img
                                src={UKFlag}
                                alt=""
                            />
                            {t("english")}
                        </div>
                    </Col>
                </Row>
                <div className="footer__copyright">
                    {t("footer.copyright")}
                </div>
            </div>
        </div>
    )
}


const mapStateToProps = state => {
    return({
        language: state.language
    })
}

const mapDispatchToProps = dispatch => {
    return({
        saveLang: data => dispatch({type: "SAVE_LANGUAGE", data})
    })
}

export default withTranslation("common")(withRouter(connect(mapStateToProps, mapDispatchToProps)(Footer)))