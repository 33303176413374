import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isLoading: false,
    is_failure: false,
}

const onSaleNFTsListSlice = createSlice({
    name:"onSaleNFTsList",
    initialState: initialState,
    reducers: {
        getOnSaleNFTsList(state, action){
            return {
                isLoading: true,
                is_failure: false,
            }
        },
        getOnSaleNFTsListSuccess(state, action){
            return {
                isLoading: false,
                is_failure: false,
                ...action.payload,
            }
        },
        getOnSaleNFTsListFailure(state, action){
            return {
                isLoading: false,
                is_failure: true,
            }
        },
        cleanOnSaleNFTsList(state, action){
            return {
                isLoading: false,
                is_failure: false,
            }
        }
    }
})

export const { cleanOnSaleNFTsList, getOnSaleNFTsList, getOnSaleNFTsListFailure, getOnSaleNFTsListSuccess } = onSaleNFTsListSlice.actions

export default onSaleNFTsListSlice.reducer