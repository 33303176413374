import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledTooltip } from "reactstrap"
import { getListVBCAccount } from "../../../redux/reducer/config.wallet/listVBCAccountSlice";
import renderAddress from "../../../utils/renderAddress";
import MenuDownIcon from "mdi-react/MenuDownIcon"
import { saveBcAddress } from "../../../redux/reducer/config.wallet/currentBcAddressSlice";
import { saveVBCAccount } from "../../../redux/reducer/config.wallet/currentVBCAccountSlice";
import { useTranslation } from "react-i18next";
import { Copy } from "react-feather"

const VBCAddress = props => {
    const { t } = useTranslation("common")
    const dispatch = useDispatch()
    const currentBcAddressSlice = useSelector(state => state.currentBcAddressSlice)
    const listVBCAccountSlice = useSelector(state => state.listVBCAccountSlice)
    const currentVBCWalletInfoSlice = useSelector(state => state.currentVBCWalletInfoSlice)

    const handleGetListVBCAccount = React.useCallback(() => {
        if(currentVBCWalletInfoSlice){
            dispatch(getListVBCAccount(currentVBCWalletInfoSlice))
        }
    }, [dispatch, currentVBCWalletInfoSlice])

    React.useEffect(() => {
        handleGetListVBCAccount()
    }, [handleGetListVBCAccount])

    const [copy_content, setCopyContent] = React.useState(t("copy"))

    return (
        <>
            <div className="waSi__address--content">
                <UncontrolledDropdown>
                    <DropdownToggle tag={"span"}>
                        <div className="waSi__address--render">
                            {renderAddress(currentBcAddressSlice)}
                            {
                                listVBCAccountSlice?.data && listVBCAccountSlice?.data?.length > 1 ?
                                <MenuDownIcon style={{transform: "translateX(5px)"}} />
                                :
                                null
                            }
                        </div>
                    </DropdownToggle>
                    {
                        listVBCAccountSlice?.data && listVBCAccountSlice?.data?.length > 1 ?
                        <DropdownMenu>
                            {
                                listVBCAccountSlice?.data?.map((acc, index) => {
                                    return(
                                        <DropdownItem
                                            key={index}
                                            onClick={() => {
                                                dispatch(saveBcAddress(acc?.address))
                                                dispatch(saveVBCAccount(acc))
                                            }}
                                        >
                                            <div className="dropdown__item">
                                                {renderAddress(acc?.address)}
                                            </div>
                                        </DropdownItem>
                                    )
                                })
                            }
                        </DropdownMenu>
                        :
                        null
                    }
                </UncontrolledDropdown>
                <div className="waSi__address--icon">
                    <div id="copy-address"
                        onClick={() => {
                            if(currentBcAddressSlice){
                                setCopyContent(t("copied"))
                                navigator.clipboard.writeText(currentBcAddressSlice)
                                setTimeout(() => {
                                    setCopyContent(t("copy"))
                                }, 2000);
                            }
                        }}
                    >
                        <Copy/>
                    </div>
                            
                    <UncontrolledTooltip
                        target="copy-address"
                    >
                        {copy_content}
                    </UncontrolledTooltip>
                </div>
            </div>
        </>
    )
}

export default VBCAddress