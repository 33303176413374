import React from "react";
import "./index.scss"
import VBchainLogo from "../../../assets/images/VBChainNFTLogo/VBchainLogo.png"
import VBCLogo from "../../../assets/images/VBChainNFTLogo/VBCLogo.png"

const LogoHeader = props => {
    return (
        <div className="logoHeader">
            <div>
                <img
                    src={VBchainLogo}
                    alt=""
                    style={{height: "37px"}}
                />
            </div>
            <div style={{marginLeft: "50px"}}>
                <img
                    src={VBCLogo}
                    alt=""
                    style={{height: "37px"}}
                />
            </div>
        </div>
    )
}

export default LogoHeader