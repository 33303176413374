import { createSlice } from "@reduxjs/toolkit";

const initialState = ""

const vbcWalletEncPassSlice = createSlice({
    name:"vbcWalletEncryptPassphrase",
    initialState: initialState,
    reducers: {
        saveVbcWalletEncPass(state, action){
            return action.payload
        },
        cleanVbcWalletEncPass(state, action){
            return ""
        }
    }
})

export const { cleanVbcWalletEncPass, saveVbcWalletEncPass } = vbcWalletEncPassSlice.actions

export default vbcWalletEncPassSlice.reducer