// const initialState = "en"

// const reducer = (state = initialState, action) => {
//     switch (action.type) {
//         case 'SAVE_LANGUAGE':
//             return action.data
//         default:
//             return state;
//     }
// }

import { createReducer } from "@reduxjs/toolkit"

const initialState = "en"

const reducer = createReducer(initialState, {
    SAVE_LANGUAGE: (state, action) => {
        return action.data
    },
})

export default reducer;