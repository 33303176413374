import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isLoading: false,
    is_failure: false,
}

const hotNFTListSlice = createSlice({
    name:"hotNFTList",
    initialState: initialState,
    reducers: {
        getHotNFTList(state, action){
            return {
                isLoading: true,
                is_failure: false,
            }
        },
        getHotNFTListSuccess(state, action){
            return {
                isLoading: false,
                is_failure: false,
                ...action.payload,
            }
        },
        getHotNFTListFailure(state, action){
            return {
                isLoading: false,
                is_failure: true,
            }
        },
        cleanHotNFTList(state, action){
            return {
                isLoading: false,
                is_failure: false,
            }
        }
    }
})

export const { cleanHotNFTList, getHotNFTList, getHotNFTListFailure, getHotNFTListSuccess } = hotNFTListSlice.actions

export default hotNFTListSlice.reducer