import { all } from 'redux-saga/effects'

// Marketplace
import listPopularItemsSaga from './list/listPopularItemsSaga'
import listCategoriesSaga from './list/listCategoriesSaga'
import listPopularCollectionsSaga from './list/listPopularCollectionsSaga'
import listTrendingAuctionsSaga from './list/listTrendingAuctionsSaga'
import listPopularSetsSaga from './list/listPopularSetsSaga'
import nftsListOfMostPopularCollectionSaga from './list/nftsListOfMostPopularCollectionSaga'
import featuredCreatorsListSaga from './list/featuredCreatorsListSaga'
import popularBundlesListSaga from './list/popularBundlesListSaga'

// Profile
import detailProfileAccountSaga from './config.profile/detailProfileAccountSaga'
import listNetworkSaga from './config.network/listNetworkSaga'
import orderHistorySaga from './config.profile/orderHistorySaga'
import collectionsListOfProfileSaga from './config.profile/collectionsListOfProfileSaga'
import hotNFTListSaga from './config.profile/hotNFTListSaga'
import onSaleNFTsListSaga from './config.profile/onSaleNFTsListSaga'
import ownedNFTsListSaga from './config.profile/ownedNFTsListSaga'
import bidTypeHistorySaga from './config.profile/bidTypeHistorySaga'

// Wallet
import listVBCWalletSaga from './config.wallet/listVBCWalletSaga'
import listVBCAccountSaga from './config.wallet/listVBCAccountSaga'
import totalBidBalanceSaga from './config.wallet/totalBidBalanceSaga'

// Balance
import accountBalanceSaga from './config.balance/accountBalanceSaga'
import wrapperBalanceSaga from './config.balance/wrapperBalanceSaga'

// List All
import listAllNFTsSaga from './list/listAllNFTsSaga'
import listAllSetsSaga from './list/listAllSetsSaga'
import listAllCollectionsSaga from './list/listAllCollectionsSaga'
import allBundlesListSaga from './list/allBundlesListSaga'

// NFT
import listProvenanceOfNFTSaga from './config.nft.detail/listProvenanceOfNFTSaga'
import listPropertiesOfNFTSaga from './config.nft.detail/listPropertiesOfNFTSaga'
import bidHistoryOfNFTSaga from './config.nft.detail/bidHistoryOfNFTSaga'
import detailNFTSaga from './config.nft.detail/detailNFTSaga'
import inspectionReportOfNFTSaga from './config.nft.detail/inspectionReportOfNFTSaga'

// Collection
import detailCollectionSaga from './config.collection.detail/detailCollectionSaga'
import listNFTsOfCollectionSaga from './config.collection.detail/listNFTsOfCollectionSaga'
import activitiesOfCollectionSaga from './config.collection.detail/activitiesOfCollectionSaga'
import listSetsOfCollectionSaga from './config.collection.detail/listSetsOfCollectionSaga'

// Set
import detailSetSaga from './config.set.detail/detailSetSaga'
import nftListOfSetSaga from './config.set.detail/nftListOfSetSaga'
import propertiesOfSetSaga from './config.set.detail/propertiesOfSetSaga'
import bidListOfSetSaga from './config.set.detail/bidListOfSetSaga'

// Bunlde
import detailBundleSaga from './config.bundle.detail/detailBundleSaga'
import nftsListOfBundleSaga from './config.bundle.detail/nftsListOfBundleSaga'

// User
import detailUserSaga from './config.user.detail/detailUserSaga'
import hotNFTsListOfUserSaga from './config.user.detail/hotNFTsListOfUserSaga'
import onSaleNFTsListOfUserSaga from './config.user.detail/onSaleNFTsListOfUserSaga'
import collectionListOfUserSaga from './config.user.detail/collectionListOfUserSaga'

export default function* rootSaga() {
    yield all([
        // Marketplace
        ...listPopularItemsSaga,
        ...listCategoriesSaga,
        ...listPopularCollectionsSaga,
        ...listTrendingAuctionsSaga,
        ...listPopularSetsSaga,
        ...nftsListOfMostPopularCollectionSaga,
        ...featuredCreatorsListSaga,
        ...popularBundlesListSaga,

        // Profile
        ...detailProfileAccountSaga,
        ...listNetworkSaga,
        ...orderHistorySaga,
        ...collectionsListOfProfileSaga,
        ...hotNFTListSaga,
        ...onSaleNFTsListSaga,
        ...ownedNFTsListSaga,
        ...bidTypeHistorySaga,

        // Wallet
        ...listVBCWalletSaga,
        ...listVBCAccountSaga,
        ...totalBidBalanceSaga,

        // Balance
        ...accountBalanceSaga,
        ...wrapperBalanceSaga,

        // List All
        ...listAllNFTsSaga,
        ...listAllSetsSaga,
        ...listAllCollectionsSaga,
        ...allBundlesListSaga,

        // NFT
        ...listProvenanceOfNFTSaga,
        ...listPropertiesOfNFTSaga,
        ...bidHistoryOfNFTSaga,
        ...detailNFTSaga,
        ...inspectionReportOfNFTSaga,

        // Collection
        ...detailCollectionSaga,
        ...listNFTsOfCollectionSaga,
        ...activitiesOfCollectionSaga,
        ...listSetsOfCollectionSaga,

        // Set
        ...detailSetSaga,
        ...nftListOfSetSaga,
        ...propertiesOfSetSaga,
        ...bidListOfSetSaga,

        // Bundle
        ...detailBundleSaga,
        ...nftsListOfBundleSaga,

        // User
        ...detailUserSaga,
        ...hotNFTsListOfUserSaga,
        ...onSaleNFTsListOfUserSaga,
        ...collectionListOfUserSaga,
    ])
}

