import { takeLatest, call, put } from 'redux-saga/effects'
// import { LINK_API } from "../../../constants/API"
import { getAccountBalance, getAccountBalanceFailure, getAccountBalanceSuccess } from "../../reducer/config.balance/accountBalanceSlice"
import Axios from 'axios'

function fetchData(data) {
    return Axios.post(data.url, data.params)
}

function* fetchAccountBalance (payload) {
    try {
        const data = payload?.payload
        const response = yield call(fetchData, data)
        const balance = response?.data

        yield put({type: getAccountBalanceSuccess().type, payload: balance?.result})
    } catch (error) {
        yield put({ type: getAccountBalanceFailure().type })
        
    }
}

const accountBalanceSaga = [
    takeLatest(getAccountBalance().type, fetchAccountBalance)
]

export default accountBalanceSaga