import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isLoading: false,
    is_failure: false,
}

const collectionsListOfProfileSlice = createSlice({
    name:"listAllCollections",
    initialState: initialState,
    reducers: {
        getCollectionsListOfProfile(state, action){
            return {
                isLoading: true,
                is_failure: false,
            }
        },
        getCollectionsListOfProfileSuccess(state, action){
            return {
                isLoading: false,
                is_failure: false,
                ...action.payload
            }
        },
        getCollectionsListOfProfileFailure(state, action){
            return {
                isLoading: false,
                is_failure: false,
            }
        },
        cleanCollectionsListOfProfile(state, action){
            return {
                isLoading: false,
                is_failure: false,
            }
        }
    }
})

export const { cleanCollectionsListOfProfile, getCollectionsListOfProfile, getCollectionsListOfProfileFailure, getCollectionsListOfProfileSuccess } = collectionsListOfProfileSlice.actions

export default collectionsListOfProfileSlice.reducer