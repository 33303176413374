import React from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import "./index.scss"
import { Download, Bell, HelpCircle } from "react-feather";

const SmallInfo = props => {
    const {
        t,
        match,
        history
    } = props

    const feature_list = [
        {
            title: t("header.downloadApp"),
            icon: <Download/>,
            path: "/download-app",
        },
        {
            title: t("header.noti"),
            icon: <Bell/>,
            path: "/notification",
        },
        {
            title: t("header.support"),
            icon: <HelpCircle/>,
            path: "/support",
        },
    ]

    const [is_hide_border, setIsHideBorder] = React.useState("")

    const handleSetIsHideBorder = React.useCallback(() => {
        if(match?.path?.includes("marketplace") && window?.scrollY <= 70){
            setIsHideBorder("is_hide_border")
        }
        else {
            setIsHideBorder("")
        }
    }, [match?.path])

    React.useEffect(() => {
        handleSetIsHideBorder()
        window.addEventListener("scroll", handleSetIsHideBorder)
        return () => {
            setIsHideBorder("")
            window.removeEventListener("scroll", handleSetIsHideBorder)
        }
    }, [handleSetIsHideBorder])

    return (
        <div
            className={
                `
                    ${is_hide_border}
                    header__smallInfo
                `
            }
        >
            <div className="header__smallInfo--container container">
                {
                    feature_list?.map((item, index) => {
                        return(
                            <div
                                key={index}
                                className="header__smallInfo--item"
                                onClick={() => {
                                    history.push(item?.path)
                                    window.scrollTo(0, 0)
                                }}
                            >
                                <div className="icon"> {item?.icon} </div>
                                {item?.title}
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default withTranslation("common")(withRouter(SmallInfo))