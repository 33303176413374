import Axios from "axios"
import { LINK_API } from "../../../constants/API"
import checkTxHash from "../../../utils/checkTxHash"
import sendTxByMetaMask from "../../../utils/sendTxByMetaMask"
import Web3 from "web3"
import handleSignTx from "../../../utils/handleSignTx"

const wrapBalance = async (params) => {
    let success = false
    let data = ""
    let error = ""
    try {
        await Axios({
            method: "POST",
            url: LINK_API.GET_DATA_DEPOSIT_TO_WRAPPER,
            data: {
                "network_id": params?.network_id,
                "token_address": params?.token_address,
                "is_native_token": params?.is_native_token,
                "value": params?.value,
                "is_metamask": params?.is_metamask,
                "bc_address": params?.bc_address,
            },
            headers: {
                "marketplace-key": process?.env?.REACT_APP_MARKETPLACE_KEY
            }
        })
        .then(async (res01) => {
            const metamask_params = {
                data: res01?.data?.data,
                from: params?.bc_address,
                to: res01?.data?.wrapper_address,
                value: Web3.utils.toHex(params?.value)
            }
            if(params?.walletTypeSlice === "MetaMask"){
                const send_tx_res = await sendTxByMetaMask(metamask_params)
                const tx_hash = send_tx_res?.data?.txHash
                if(tx_hash) {
                    const check_tx_hash = await checkTxHash(params?.currentNetworkSlice, tx_hash)
                    if(check_tx_hash?.success){
                        success = true
                    }
                }
            }
            else if(params?.walletTypeSlice === "VBchain") {
                const sign_res = await handleSignTx(params?.vbcWalletEncPassSlice, params?.encPrivateKey, res01?.data, params?.currentNetworkSlice)
                if(sign_res?.success && sign_res?.data && params?.currentNetworkSlice?.gateway) {
                    await Axios({
                        method: "POST",
                        url: params?.currentNetworkSlice?.gateway + "/contract/createTransaction",
                        data: {
                            "rawTx": sign_res?.data,
                            "from": params?.currentBcAddressSlice
                        }
                    })
                    .then(async(res) => {
                        const tx_hash = res?.data?.data?.txID
                        if(tx_hash) {
                            const check_tx_hash = await checkTxHash(params?.currentNetworkSlice, tx_hash)
                            if(check_tx_hash?.success){
                                success = true
                            }
                        }
                    })
                    .catch(error => {
                        
                    })
                }
            }
        })
        .catch(error => {

        })
    } catch (error) {
        
    }
    return {success, data, error}
}

export default wrapBalance