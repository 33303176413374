import { takeLatest, call, put } from 'redux-saga/effects'
import { LINK_API } from "../../../constants/API"
import fetchData from "../fetchData"
import { getPopularBundlesListSuccess, getPopularBundlesListFailure, getPopularBundlesList } from "../../reducer/list/popularBundlesListSlice"

function* fetchPopularBundlesList (payload) {
    try {
        const temp_payload = payload?.payload

        const likeAddress = temp_payload?.likeAddress ? `&likeAddress=${temp_payload?.likeAddress}` : ""

        const url = LINK_API.GET_BUNDLES_LIST + "?page=1&size=12&status=ONSALE,NOTFORSALE" + likeAddress

        const response = yield call(fetchData, url)

        const data = response.data

        yield put({type: getPopularBundlesListSuccess().type, payload: data})

    } catch (error) {
        yield put({ type: getPopularBundlesListFailure().type })
        
    }
}

const popularBundlesListSaga = [
    takeLatest(getPopularBundlesList().type, fetchPopularBundlesList)
]

export default popularBundlesListSaga