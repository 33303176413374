import { takeLatest, call, put } from 'redux-saga/effects'
import { LINK_API } from "../../../constants/API"
import fetchData from "../fetchData"
import { getNFTsListOfMostPopularCollection, getNFTsListOfMostPopularCollectionFailure, getNFTsListOfMostPopularCollectionSuccess } from "../../reducer/list/nftsListOfMostPopularCollectionSlice"

function* fetchNFTsListOfMostPopularCollection (payload) {
    try {

        const likeAddress = payload?.payload?.likeAddress ? `&likeAddress=${payload?.payload?.likeAddress}` : ""

        const baseType = payload?.payload?.baseType ? `&baseType=${payload?.payload?.baseType}` : ""

        const collectionAddr = payload?.payload?.collectionAddr ? `&collectionAddr=${payload?.payload?.collectionAddr}` : ""

        const url = LINK_API.GET_LIST_NFTS + "?size=3&page=1&status=ONSALE&isSet=0&isBundle=0" + likeAddress + collectionAddr + baseType

        const response = yield call(fetchData, url)

        const data = response.data

        yield put({type: getNFTsListOfMostPopularCollectionSuccess().type, payload: data})

    } catch (error) {
        yield put({ type: getNFTsListOfMostPopularCollectionFailure().type })
        
    }
}

const nftsListOfMostPopularCollectionSaga = [
    takeLatest(getNFTsListOfMostPopularCollection().type, fetchNFTsListOfMostPopularCollection)
]

export default nftsListOfMostPopularCollectionSaga