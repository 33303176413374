import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    is_show: false,
    end_time: 0,
}

const fakePassphraseSlice = createSlice({
    name:"fakePassphrase",
    initialState: initialState,
    reducers: {
        setFakePassphrase(state, action){
            return {
                is_show: true,
                end_time: Date.now() + 60000
            }
        },
        cleanFakePassphrase(state, action){
            return {
                is_show: false,
                end_time: 0,
            }
        }
    }
})

export const { cleanFakePassphrase, setFakePassphrase } = fakePassphraseSlice.actions

export default fakePassphraseSlice.reducer