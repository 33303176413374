import React, { Suspense } from 'react'
import { Switch, Redirect, Route, withRouter } from "react-router-dom";
import { connect, useDispatch } from 'react-redux'
import Header from '../components/Header';
import Footer from '../components/footer';
import SuspenseComponent from '../components/suspense'
import {getListCategories} from "../redux/reducer/list/listCategoriesSlice"

import MainRoutes from './Main.Routes';
import DetailRoutes from './Detail.Routes';
import NormalRoutes from './Normal.Routes';
import Authen from '../pages/Authen';

import CryptoJS from 'crypto-js';
import { useLocation } from 'react-router-dom';
import "./index.scss"
import LogoHeader from '../components/Header/Logo.Header';

const Routes = props => {

    const dispatch = useDispatch()

    const env = process.env.REACT_APP_MARKETPLACE_ENV
    const app_passphrase = process.env.REACT_APP_MARKETPLACE_PASSPHRASE
    const system_passphrase = CryptoJS.SHA256(app_passphrase).toString()
    const browser_passphrase = localStorage.getItem("gate_password")
    const [is_authen, setIsAuthen] = React.useState(system_passphrase === browser_passphrase ? true : false)
    const [is_detail_nft, setIsDetailNFT] = React.useState(false)

    const location = useLocation()

    React.useEffect(() => {
        if(location.pathname.includes("/nft/detail/collectionAddr") || location.pathname.includes("/set/detail") || location.pathname.includes("/bundle/detail")){
            setIsDetailNFT(true)
        }
        else {
            setIsDetailNFT(false)
        }
    }, [location.pathname])

    const handleGetListCategories = React.useCallback(() => {
        dispatch(getListCategories())
    }, [dispatch])

    React.useEffect(() => {
        handleGetListCategories()
    }, [handleGetListCategories])
    
    return(
        <>
            {
            env === "production" || (env === "staging" && is_authen) || is_detail_nft || env === "dev" ?
            <Suspense fallback={<SuspenseComponent/>}>
                <Switch>
                    <Redirect exact from="/" to="/marketplace"></Redirect>
                    {
                        MainRoutes.map((page, index) => {
                            const Component = page.component
                            return(
                                <Route path={page.path} key={index} >
                                    <Header/>
                                    <div className='pages-route' style={{backgroundColor: location.pathname.includes("/support") ? "#E5E5E590" : ""}}>
                                        <Route path={`${page.path}`} component={() => <Component />}></Route>
                                        <Footer/>
                                    </div>
                                </Route>
                            )
                        })
                    }

                    {
                        DetailRoutes.map((page, index) => {
                            const Component = page.component
                            return(
                                <Route path={page.path} key={index} >
                                    <Header/>
                                    <div className='pages-route'>
                                        <Route path={`${page.path}`} component={() => <Component />}></Route>
                                    </div>
                                </Route>
                            )
                        })
                    }

                    {
                        NormalRoutes.map((page, index) => {
                            const Component = page.component
                            return(
                                <Route path={page.path} key={index} >
                                    <LogoHeader/>
                                    <div className='pages-route'>
                                        <Route path={`${page.path}`} component={() => <Component />}></Route>
                                    </div>
                                </Route>
                            )
                        })
                    }
                    <Route render={() => <Redirect to="/marketplace" />} />
                </Switch>
            </Suspense>
            :
                <Authen setIsAuthen={setIsAuthen} />
            }    
        </>
    )
}

const mapStateToProps = state => {
    return({
    })
}

const mapDispatchToProps = dispatch => {
    return({
        
    })
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Routes))