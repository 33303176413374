import React from "react";
import { useSelector } from "react-redux";
import { Col, Row } from "reactstrap";
import "./index.scss"
import Language from "./Language";
import Logo from "./Logo";
import PagesMenu from "./Pages.Menu";
import Profile from "./Profile";
import Wallet from "./Wallet";
import { useMediaQuery } from 'react-responsive'
import HeaderSidebar from "../Header.Sidebar";
import { Bell } from "react-feather"

const Container = props => {
    const {
        handleSetIsAccountSidebar,
    } = props

    const accountSlice = useSelector(state => state.accountSlice)

    const is_responsive = useMediaQuery({ query: '(max-width: 1199px)' })

    return (
        <div className="header__container">
            <div className="header__container--content container">
                <div style={{width: "100%"}}>
                    <Row>
                        <Col lg="6" xl="6" md="6" sm="6" xs="6">
                            <div className="header__leftCol">
                                <Logo/>
                                <PagesMenu/>
                            </div>
                        </Col>
                        <Col  lg="6" xl="6" md="6" sm="6" xs="6">
                            <div className="header__rightCol">
                                <Profile/>
                                {
                                    accountSlice?.isSignedIn && !is_responsive ?
                                    <Wallet handleSetIsAccountSidebar={handleSetIsAccountSidebar} /> : null
                                }
                                {
                                    is_responsive ?
                                    <>
                                        <div className="header__noti">
                                            <Bell/>
                                        </div>
                                        <HeaderSidebar handleSetIsAccountSidebar={handleSetIsAccountSidebar} />
                                    </>
                                    :
                                    <>
                                        <Language/>
                                    </>
                                }
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    )
}

export default Container