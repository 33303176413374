import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import VBChainWalletLogo from "../../../assets/images/WalletLogo/EBSLogo.webp"
import { getListVBCWallet } from "../../../redux/reducer/config.wallet/listVBCWalletSlice";
import { Collapse } from "reactstrap"
import VBCWalletItem from "../VBC.Wallet.Item";
import EmoticonSadIcon from "mdi-react/EmoticonSadIcon"
import { useHistory } from "react-router-dom";

const ConnectVBchainWallet = props => {
    const { setOnProcess } = props
    const { t } = useTranslation("common")

    const { push } = useHistory()

    const dispatch = useDispatch()

    const accountSlice = useSelector(state => state.accountSlice)
    const listVBCWalletSlice = useSelector(state => state.listVBCWalletSlice)

    const handleGetListVBCWallet = React.useCallback(() => {
        if(accountSlice?.isSignedIn){
            dispatch(getListVBCWallet())
        }
    }, [accountSlice, dispatch])

    React.useEffect(() => {
        handleGetListVBCWallet()
    }, [handleGetListVBCWallet])

    const [is_show_list_wallet, setIsShowListWallet] = React.useState(false)

    return (
        <div className="container">
            <div className="waSi__selectWallet" onClick={() => setIsShowListWallet(!is_show_list_wallet)}>
                <img
                    src={VBChainWalletLogo}
                    alt=""
                />
                <div>
                    {t("wallet.VBC")}
                </div>
            </div>
            <Collapse isOpen={is_show_list_wallet} >
                {
                    listVBCWalletSlice?.data && listVBCWalletSlice.data[0] ?
                    listVBCWalletSlice?.data?.map((wallet, index) => {
                        return (
                            <VBCWalletItem
                                wallet={wallet}
                                key={index}
                                setOnProcess={setOnProcess}
                            />
                        )
                    })
                    :
                    <>
                        <div className="waSi__selectWallet--createWallet">
                            <div className="icon">
                                <EmoticonSadIcon/>
                            </div>
                            <div className="title">
                                {t("didntHaveAnyVBCWallet")}
                            </div>
                            <div className="create"
                                onClick={() => push("/create-wallet")}
                            >
                                {t("wallet.create")}
                            </div>
                        </div>
                    </>
                }
            </Collapse>
        </div>
    )
}

export default ConnectVBchainWallet