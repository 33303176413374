import React from "react";
import "./index.scss"
import { X } from "react-feather"
import Network from "./components/Network";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import ConnectVBchainWallet from "./components/Connect.VBchain.Wallet";
import ConnectMetaMask from "./components/Connect.MetaMask";
import Loading from "../alert/loading";
import Error from "../alert/error";
import Success from "../alert/success";
import Warning from "../alert/warning";
import Disconnect from "./components/Disconnect";
import Logo from "./components/Logo";
import Address from "./Address";
import Balance from "./Balance";

const WalletSidebar = props => {
    const {
        isOpen, toggle
    } = props

    const { t } = useTranslation("common")

    const wallet_sidebar_class = "waSi " + (isOpen ? "open" : "")
    const currentBcAddressSlice = useSelector(state => state.currentBcAddressSlice)

    const [alert, setAlert] = React.useState(null)

    const setOnProcess = process => {
        const status = process?.status
        const title = process?.title
        const subTitle = process?.subTitle
        const hideCancel= process?.hideCancel
        const onConfirm = process?.onConfirm

        if(status === "loading") {
            setAlert(<Loading/>)
        }
        else if(status === "closeAlert"){
            setAlert(null)
        }
        else if(status === "error"){
            setAlert(<Error
                onClose={() => setAlert(null)}
                title={title}
            />)
        }
        else if(status === "success"){
            setAlert(<Success
                onConfirm={onConfirm ? onConfirm : () => setAlert(null)}
                hideCancel={true}
                title={title}
            />)
        }
        else if(status === "warning"){
            setAlert(
                <Warning
                    onConfirm={() => setAlert(null)}
                    title={title}
                    subTitle={subTitle}
                    hideCancel={hideCancel}
                />
            )
        }
    }

    return (
        <div className={wallet_sidebar_class}>
            {alert}
            <div className="waSi__fade" onClick={toggle} />
            <div className="waSi__container normal-scrollbar">
                <div className="container waSi__comp01">
                    <Network/>
                    <div className="waSi__close" onClick={toggle}>
                        <X/>
                    </div>
                </div>
                {
                    !currentBcAddressSlice ?
                    <>
                        <div className="container waSi__connectTitle">
                            {t("header.connectWallet")}
                        </div>
                        <ConnectVBchainWallet setOnProcess={setOnProcess} />
                        <ConnectMetaMask setOnProcess={setOnProcess} />
                    </>
                    :
                    <>
                        <Disconnect/>
                        <Logo/>
                        <Address setOnProcess={setOnProcess}/>
                        <Balance setOnProcess={setOnProcess} isOpen={isOpen} />
                    </>
                }
            </div>
        </div>
    )
}

export default WalletSidebar