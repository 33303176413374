import { takeLatest, call, put } from 'redux-saga/effects'
import { LINK_API } from "../../../constants/API"
import fetchData from "../fetchData"
import { getListAllNFTs, getListAllNFTsFailure, getListAllNFTsSuccess } from "../../reducer/list/listAllNFTsSlice"

function* fetchListAllNFTs (payload) {
    try {
        const tempPayload = payload?.payload

        const page = tempPayload?.page ? `&page=${tempPayload?.page}` : ""
        const likeAddress = tempPayload?.likeAddress ? `&likeAddress=${tempPayload?.likeAddress}` : ""
        const status = tempPayload?.status ? `&status=${tempPayload?.status}` : "&status=ONSALE,NOTFORSALE,INCOMING,SOLD"
        const sort = tempPayload?.sort ? `&sort=${tempPayload?.sort}` : ""
        const networkId = tempPayload?.networkId ? `&networkId=${tempPayload?.networkId}` : ""
        const labelId = tempPayload?.labelId ? `&labelId=${tempPayload?.labelId}` : ""
        const url = LINK_API.GET_LIST_NFTS + "?size=24&isSet=0&isBundle=0" + page + likeAddress + status + sort + networkId + labelId
        const response = yield call(fetchData, url)

        const data = response.data

        yield put({type: getListAllNFTsSuccess().type, payload: data})

    } catch (error) {
        yield put({ type: getListAllNFTsFailure().type })
        
    }
}

const listAllNFTsSaga = [
    takeLatest(getListAllNFTs().type, fetchListAllNFTs)
]

export default listAllNFTsSaga