import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isLoading: false,
}

const listAllNFTsSlice = createSlice({
    name:"listAllNFTs",
    initialState: initialState,
    reducers: {
        getListAllNFTs(state, action){
            return {
                isLoading: true,
            }
        },
        getListAllNFTsSuccess(state, action){
            return {
                isLoading: false,
                ...action.payload
            }
        },
        getListAllNFTsFailure(state, action){
            return {
                isLoading: false,
            }
        },
        cleanListAllNFts(state, action){
            return {
                isLoading: false
            }
        }
    }
})

export const { cleanListAllNFts, getListAllNFTs, getListAllNFTsFailure, getListAllNFTsSuccess } = listAllNFTsSlice.actions

export default listAllNFTsSlice.reducer