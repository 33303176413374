import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isConnectWallet: false,
}

const isConnectWalletSlice = createSlice({
    name:"isConnectWallet",
    initialState: initialState,
    reducers: {
        setIsConnectWallet(state, action){
            return {
                isConnectWallet: true,
            }
        },
        setIsNotConnectWallet(state, action){
            return{
                isConnectWallet: false,
            }
        }
    }
})

export const { setIsConnectWallet, setIsNotConnectWallet } = isConnectWalletSlice.actions

export default isConnectWalletSlice.reducer