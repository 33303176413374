import React from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import ShoppingIcon from "mdi-react/ShoppingIcon"
import VaultIcon from "../../../assets/images/svg/VaultIcon";

const PagesMenu = props => {
    const {
        t,
        match,
        history
    } = props
    
    const pages_list = [
        {
            icon: <ShoppingIcon/>,
            title: t("marketplace"),
            path: "/marketplace",
        },
        {
            icon: <VaultIcon/>,
            title: t("fractional"),
            path: "/fractional",
        },
    ]

    return (
        <div className="header__pagesMenu">
            {
                pages_list?.map((page, index) => {
                    const is_actived = match?.path?.includes(page.path) ? "is_actived" : ""
                    return (
                        <div 
                            key={index}
                            className={
                                `
                                    header__pagesMenu--item
                                    ${is_actived}
                                `
                            }
                            onClick={() => {
                                if(!is_actived){
                                    history.push(page.path)
                                    window.scrollTo(0, 0)
                                }
                            }}
                        >
                            <div className="header__pagesMenu--itemIcon">
                                {page?.icon}
                            </div>
                            <div className="header__pagesMenu--itemTitle">
                                {page?.title}
                            </div>
                        </div>
                    )
                })
            }
        </div>
    )
}

export default withTranslation("common")(withRouter(PagesMenu))