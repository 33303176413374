import axios from "axios";

const fetchData = (url, method, data, params) => {

    if(url?.includes("nft.vbchain.vn") || url?.includes("car.vbchain.vn")){
        axios.defaults.headers.common.apikey = process.env.REACT_APP_MARKETPLACE_PASSPHRASE
        // axios.defaults.headers.common.apikey = process.env.REACT_APP_MARKETPLACE_KEY
    }

    return axios({
        method: method || 'GET',
        url: url,
        data: data,
        params: params,
    })
}



export default fetchData