import { takeLatest, call, put } from 'redux-saga/effects'
import { LINK_API } from "../../../constants/API"
import fetchData from "../fetchData"
import { getListTrendingAuctions, getListTrendingAuctionsFailure, getListTrendingAuctionsSuccess } from "../../reducer/list/listTrendingAuctionsSlice"

function* fetchListTrendingAuctions (payload) {
    try {

        const likeAddress = payload?.payload?.likeAddress ? `&likeAddress=${payload?.payload?.likeAddress}` : ""

        const url = LINK_API.GET_LIST_NFTS + "?size=12&page=1&status=ONSALE,NOTFORSALE&sort=like&isBid=1&isSet=0&isBundle=0" + likeAddress

        const response = yield call(fetchData, url)

        const data = response.data

        yield put({type: getListTrendingAuctionsSuccess().type, payload: data})

    } catch (error) {
        yield put({ type: getListTrendingAuctionsFailure().type })
        
    }
}

const listTrendingAuctionsSaga = [
    takeLatest(getListTrendingAuctions().type, fetchListTrendingAuctions)
]

export default listTrendingAuctionsSaga