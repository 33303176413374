import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isLoading: false,
    is_failure: false,
}

const detailSetSlice = createSlice({
    name:"detailSet",
    initialState: initialState,
    reducers: {
        getDetailSet(state, action){
            return {
                isLoading: true,
                is_failure: false,
            }
        },
        getDetailSetSuccess(state, action){
            return {
                isLoading: false,
                is_failure: false,
                ...action.payload
            }
        },
        getDetailSetFailure(state, action){
            return {
                isLoading: false,
                is_failure: true,
            }
        },
        cleanDetailSet(state, action){
            return {
                isLoading: false,
                is_failure: false,
            }
        }
    }
})

export const { cleanDetailSet, getDetailSet, getDetailSetFailure, getDetailSetSuccess } = detailSetSlice.actions

export default detailSetSlice.reducer