import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isLoading: false,
    is_failure: false,
}

const ownedNFTsListSlice = createSlice({
    name:"ownedNFTsList",
    initialState: initialState,
    reducers: {
        getOwnedNFTsList(state, action){
            return {
                isLoading: true,
                is_failure: false,
            }
        },
        getOwnedNFTsListSuccess(state, action){
            return {
                isLoading: false,
                is_failure: false,
                ...action.payload,
            }
        },
        getOwnedNFTsListFailure(state, action){
            return {
                isLoading: false,
                is_failure: true,
            }
        },
        cleanOwnedNFTsList(state, action){
            return {
                isLoading: false,
                is_failure: false,
            }
        }
    }
})

export const { cleanOwnedNFTsList, getOwnedNFTsList, getOwnedNFTsListFailure, getOwnedNFTsListSuccess } = ownedNFTsListSlice.actions

export default ownedNFTsListSlice.reducer