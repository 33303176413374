import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { cleanAccountBalance } from "../../../redux/reducer/config.balance/accountBalanceSlice";
import { cleanWrapperBalance } from "../../../redux/reducer/config.balance/wrapperBalanceSlice";
import { cleanBcAddress } from "../../../redux/reducer/config.wallet/currentBcAddressSlice";
import { cleanVBCAccount } from "../../../redux/reducer/config.wallet/currentVBCAccountSlice";
import { cleanVBCWallet } from "../../../redux/reducer/config.wallet/currentVBCWalletInfoSlice";
import { setIsNotConnectWallet } from "../../../redux/reducer/config.wallet/isConnectWalletSlice";
import { cleanListVBCAccount } from "../../../redux/reducer/config.wallet/listVBCAccountSlice";
import { cleanVbcWalletEncPass } from "../../../redux/reducer/config.wallet/vbcWalletEncPassSlice";
import { cleanWalletType } from "../../../redux/reducer/config.wallet/walletTypeSlice";

const Disconnect = props => {
    const { t } = useTranslation("common")

    const dispatch = useDispatch()

    const handleDisconnect = () => {
        dispatch(setIsNotConnectWallet())
        dispatch(cleanWalletType())
        dispatch(cleanBcAddress())
        dispatch(cleanVbcWalletEncPass())
        dispatch(cleanListVBCAccount())
        dispatch(cleanVBCAccount())
        dispatch(cleanVBCWallet())
        dispatch(cleanAccountBalance())
        dispatch(cleanWrapperBalance())
    }

    return (
        <div className="container mt-3 waSi__disconnect">
            <div className="waSi__disconnect--content" onClick={handleDisconnect}>
                {t("disconnect")}
            </div>
        </div>
    )
}

export default Disconnect