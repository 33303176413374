import { takeLatest, call, put } from 'redux-saga/effects'
import { LINK_API } from "../../../constants/API"
import fetchData from "../fetchData"
import { getAllBundlesList, getAllBundlesListFailure, getAllBundlesListSuccess } from "../../reducer/list/allBundlesListSlice"

function* fetchAllBundlesList (payload) {
    try {
        const temp_payload = payload?.payload

        const params = {
            size: 24,
            ...temp_payload,
            status: temp_payload?.status || "INCOMING,ONSALE,NOTFORSALE,SOLD"
        }

        const url = LINK_API.GET_BUNDLES_LIST

        const response = yield call(fetchData, url, "GET", {}, params)

        const data = response.data

        yield put({type: getAllBundlesListSuccess().type, payload: data})

    } catch (error) {
        yield put({ type: getAllBundlesListFailure().type })
        
    }
}

const allBundlesListSaga = [
    takeLatest(getAllBundlesList().type, fetchAllBundlesList)
]

export default allBundlesListSaga