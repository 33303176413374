import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isLoading: false,
}

const listPopularCollectionsSlice = createSlice({
    name:"listPopularCollections",
    initialState: initialState,
    reducers: {
        getListPopularCollections(state, action){
            return {
                isLoading: true,
            }
        },
        getListPopularCollectionsSuccess(state, action){
            return {
                isLoading: false,
                ...action.payload
            }
        },
        getListPopularCollectionsFailure(state, action){
            return {
                isLoading: false,
            }
        },
        cleanListPopularCollections(state, action){
            return {
                isLoading: false
            }
        }
    }
})

export const { cleanListPopularCollections, getListPopularCollections, getListPopularCollectionsFailure, getListPopularCollectionsSuccess } = listPopularCollectionsSlice.actions

export default listPopularCollectionsSlice.reducer