import React from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
// import RenderToken from "./Render.Token"
import Web3 from "web3"
import RenderPrice from "../../Render.Price"

const TotalBalance = props => {
    const { t } = useTranslation("common")
    const currentNetworkSlice = useSelector(state => state.currentNetworkSlice)
    const accountBalanceSlice = useSelector(state => state.accountBalanceSlice)
    const acc_balance_eth = accountBalanceSlice?.data ? parseFloat(Web3.utils.fromWei(parseInt(accountBalanceSlice?.data, 16).toString(),"ether")) : 0
    const wrapperBalanceSlice = useSelector(state => state.wrapperBalanceSlice)
    const wrapper_wei = wrapperBalanceSlice?.data?.toString()
    const wrapper_eth = wrapper_wei ? parseFloat(Web3.utils.fromWei(wrapper_wei, "ether")) : 0

    return (
        <div className="waSi__bala">
            <div className={
                `
                    waSi__bala--title
                    total
                `
            }
            
            >
                <div className="waSi__bala--contentTitle">
                    {t("totalBalance")}
                </div>
            </div>
            <div className="waSi__bala--value total">
                {/* <RenderToken
                    type={currentNetworkSlice?.networkId?.toString() === "97" ? "BNB" : "VBC"}
                    data={wrapper_eth + acc_balance_eth}
                    is_loading={accountBalanceSlice?.isLoading || wrapperBalanceSlice?.isLoading}
                /> */}
                <RenderPrice
                    type={currentNetworkSlice?.networkId?.toString() === "97" ? "BNB" : "VBC"}
                    data={(acc_balance_eth + wrapper_eth)}
                />
            </div>
        </div>
    )
}

export default TotalBalance