import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isLoading: false,
}

const nftListOfSetSlice = createSlice({
    name:"nftListOfSet",
    initialState: initialState,
    reducers: {
        getNftListOfSet(state, action){
            return {
                isLoading: true,
            }
        },
        getNftListOfSetSuccess(state, action){
            return {
                isLoading: false,
                ...action.payload
            }
        },
        getNftListOfSetFailure(state, action){
            return {
                isLoading: false,
            }
        },
        cleanNftListOfSet(state, action){
            return {
                isLoading: false
            }
        }
    }
})

export const { cleanNftListOfSet, getNftListOfSet, getNftListOfSetFailure, getNftListOfSetSuccess } = nftListOfSetSlice.actions

export default nftListOfSetSlice.reducer