import { takeLatest, call, put } from 'redux-saga/effects'
import { LINK_API } from "../../../constants/API"
import fetchData from "../fetchData"
import { getDetailBundle, getDetailBundleFailure, getDetailBundleSuccess } from "../../reducer/config.bundle.detail/detailBundleSlice"

function* fetchDetailBundle (payload) {
    try {
        const temp_payload = payload?.payload

        const id = temp_payload?.id ? `?id=${temp_payload?.id}` : ""
        const likeAddress = temp_payload?.likeAddress ? `&likeAddress=${temp_payload?.likeAddress}` : ""

        const url = LINK_API.GET_DETAIL_BUNDLE + id + likeAddress

        const response = yield call(fetchData, url)

        const data = response?.data?.data

        yield put({type: getDetailBundleSuccess().type, payload: data})

    } catch (error) {
        yield put({ type: getDetailBundleFailure().type, payload: {isError: true} })
        
    }
}

const detailBundleSaga = [
    takeLatest(getDetailBundle().type, fetchDetailBundle)
]

export default detailBundleSaga