import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Input, InputGroup, InputGroupText, Label, Modal, ModalBody, ModalFooter } from "reactstrap";
import ModalHeaderComponent from "../../ModalHeader";
import VBCoin from "../../../assets/images/VBCoin/VBCoin.svg"
import WVBCoin from "../../../assets/images/VBCoin/WVBCoin.svg"
import BNBcoin from "../../../assets/images/networkIcon/bnbIcon.png"
import WBNBcoin from "../../../assets/images/VBCoin/WBNBcoin.png"
import { handleRenderPrice } from "../../../constants/handleRenderPrice"
import Web3 from "web3"
import * as Yup from "yup"
import { useFormik } from "formik";
import RenderPrice from "../../Render.Price";

const WrapModal = props => {
    const {
        isOpen,
        toggle,
        handleWrap,
    } = props

    const { t } = useTranslation("common")

    const currentNetworkSlice = useSelector(state => state.currentNetworkSlice)

    const wrapperBalanceSlice = useSelector(state => state.wrapperBalanceSlice)
    const data_wei = wrapperBalanceSlice?.data?.toString()
    const data_eth = data_wei ? parseFloat(Web3.utils.fromWei(data_wei, "ether")) : 0

    const accountBalanceSlice = useSelector(state => state.accountBalanceSlice)
    const acc_balance_eth = accountBalanceSlice?.data ? parseFloat(Web3.utils.fromWei(parseInt(accountBalanceSlice?.data, 16).toString(),"ether")) : 0

    const wrapSchema = Yup.object().shape({
        amount: Yup.number()
            .required(t("require"))
            .test("invalid", t("invalid.invalid"), function(value){
                const fixed_acc_balance = handleRenderPrice(acc_balance_eth, 4)
                return value > 0 && value <= fixed_acc_balance
            })
        ,
    })

    const formik = useFormik({
        initialValues: {
            amount: "",
        },
        validationSchema: wrapSchema,
        onSubmit: async (values) => {
            const temp_amount = values?.amount === handleRenderPrice(acc_balance_eth, 4) ? acc_balance_eth : values?.amount
            await handleClickWrap(temp_amount)
        },
    })

    const handleClickWrap = async (temp_amount) => {
        await handleWrap(temp_amount, formik)
    }

    const handleToggle = () => {
        toggle()
        formik.resetForm()
    }

    return (
        <Modal isOpen={isOpen} toggle={handleToggle} centered>
            <ModalHeaderComponent
                toggle={handleToggle}
                title={t("wrap")}
            />
            <ModalBody>
                <div>
                    <Label>
                        {t("amount")}
                    </Label>
                    <InputGroup>
                        <InputGroupText>
                            <img
                                className="icon-input-sub coin"
                                alt=""
                                src={currentNetworkSlice?.networkId?.toString() === "97" ? BNBcoin : VBCoin}
                            /> {" "}
                            <span style={{marginLeft: "5px", fontSize: "13px", paddingTop: "0px"}}>{currentNetworkSlice?.networkId?.toString() === "97" ? "BNB" : "VBC"}</span>
                        </InputGroupText>
                        <Input
                            type="number"
                            placeholder={currentNetworkSlice?.networkId?.toString() === "97" ? "BNB" : "VBC"}
                            className="input-number-hide-arrow hide-valid-invalid "
                            name="amount"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.amount}
                            valid={formik.errors.amount && formik.touched.amount ? false : formik.values.amount ? true : false}
                            invalid={formik.touched.amount && formik.errors.amount ? true : false}
                        />
                    </InputGroup>
                    {formik.touched.amount && formik.errors.amount ? (
                        <p className="font-weight-regular font-size-sm text-danger mb-0 mt-1 pt-1">
                        {formik.errors.amount}
                        </p>
                    ) : null}
                    <br/>
                    <div style={{display: "flex", alignItems: "center"}}>
                        <div style={{marginRight: "10px"}}>
                            {t("accountBalance")}:
                        </div>
                        <div style={{marginTop: "5.5px"}}>
                            <RenderPrice
                                type={currentNetworkSlice?.networkId?.toString() === "97" ? "BNB" : "VBC"}
                                data={acc_balance_eth}
                            />
                        </div>
                        <div className="waSi__maxBtn"
                            onClick={() => {
                                formik.setValues({amount: handleRenderPrice(acc_balance_eth, 4)})
                            }}
                        >
                            {t("max")}
                        </div>
                    </div>
                </div>
                <hr/>
                <div>
                    <Label>
                        {t("wrapperBalance")}
                    </Label>
                    <InputGroup>
                        <InputGroupText>
                            <img
                                className="icon-input-sub coin"
                                alt=""
                                src={currentNetworkSlice?.networkId?.toString() === "97" ? WBNBcoin : WVBCoin}
                            /> {" "}
                            <span style={{marginLeft: "5px", fontSize: "13px", paddingTop: "0px"}}>{currentNetworkSlice?.networkId?.toString() === "97" ? "WBNB" : "WVBC"}</span>
                        </InputGroupText>
                        <Input
                            type="text"
                            placeholder={data_eth}
                            value={`${handleRenderPrice(data_eth, 4)} ${formik.values.amount ? " + "  + formik.values.amount : ""}`}
                            disabled
                            className="input-number-hide-arrow"
                        />
                    </InputGroup>
                </div>
            </ModalBody>
            <ModalFooter>
                <div
                    className={
                        formik.values?.amount && !formik?.errors?.amount ?
                        "normal-btn" : "disabled-normal-btn"
                    }
                    onClick={() => {
                        if(formik.values?.amount && !formik?.errors?.amount){
                            formik.handleSubmit()
                        }
                    }}
                >
                    {t("wrap")}
                </div>
            </ModalFooter>
        </Modal>
    )
}

export default WrapModal