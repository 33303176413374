import React from "react";
import SweetAlert from 'react-bootstrap-sweetalert';
import { withTranslation } from 'react-i18next';
import { Button } from "reactstrap";
import "./css/sweet.css";

function ContentAlert(...props) {
    const emptyFunc = () => {}
    return (
         <SweetAlert success title={props[0].title ? props[0].title : props[0].t('success')} onConfirm={props[0].onConfirm || emptyFunc()} showConfirm={false} showCancel={false} style={{ display: "block", top: props[0].top ? props[0].top : "0%" }}>
         <div className="text-center pt-2">
             {props[0].hideCancel ? null :
                 <Button onClick={props[0].onClose} outline color="danger" className="mx-1">
                     <span className="btn-wrapper--label">
                         {props[0].cancelBtnText ? props[0].cancelBtnText : props[0].t('cancel')}
                     </span>
                 </Button>
             }
             <Button onClick={props[0].onConfirm || emptyFunc()} color={props[0].color ? props[0].color : "success"} className="mx-1">
                 <span className="btn-wrapper--label">
                     {props[0].confirmBtnText ? props[0].confirmBtnText : props[0].t('confirm')}
                 </span>
             </Button>
         </div>
     </SweetAlert >
    )
}

export default withTranslation('common')(ContentAlert);