import { takeLatest, call, put } from 'redux-saga/effects'
import { LINK_API } from "../../../constants/API"
import fetchData from "../fetchData"
import { getListSetsOfCollection, getListSetsOfCollectionFailure, getListSetsOfCollectionSuccess } from "../../reducer/config.collection.detail/listSetsOfCollectionSlice"

function* fetchListSetsOfCollection (payload) {
    try {
        const temp_payload = payload?.payload

        const page = temp_payload?.page ? `&page=${temp_payload?.page}` : ""
        const collectionAddr = temp_payload?.collectionAddr ? `&collectionAddr=${temp_payload?.collectionAddr}` : ""
        const baseType = temp_payload?.baseType ? `&baseType=${temp_payload?.baseType}` : ""
        const likeAddress = temp_payload?.likeAddress ? `&likeAddress=${temp_payload?.likeAddress}` : ""

        const url = LINK_API.GET_LIST_SETS + "?size=24" + page + collectionAddr + baseType + likeAddress + "&status=ONSALE,NOTFORSALE,INCOMING,SOLD"

        const response = yield call(fetchData, url)

        const data = response?.data?.data

        yield put({type: getListSetsOfCollectionSuccess().type, payload: data})

    } catch (error) {
        yield put({ type: getListSetsOfCollectionFailure().type})
        
    }
}

const listSetsOfCollectionSaga = [
    takeLatest(getListSetsOfCollection().type, fetchListSetsOfCollection)
]

export default listSetsOfCollectionSaga