import React from "react";
import VietnamFlag from "../../../assets/images/Flag/VietnamFlag.png"
import UKFlag from "../../../assets/images/Flag/UKFlag.png"
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import MenuDownIcon from "mdi-react/MenuDownIcon"

const Language = props => {
    const {
        i18n,
        t,
        language,
        saveLang,
    } = props

    const handleChangeLang = async (lang) => {
        await i18n.changeLanguage(lang)
    }

    React.useEffect(() => {
        i18n.changeLanguage(language)
    }, [i18n, language])

    return (
        <>
            <UncontrolledDropdown direction="up">
                <DropdownToggle tag={"span"}>
                    <div className="headerSidebar__language">
                        <div className="headerSidebar__language--value" >   
                            <img
                                src={language === "en" ? UKFlag : VietnamFlag}
                                alt=""
                            />
                            <div>
                                {language === "en" ? t("english") : t("vietnamese")}
                            </div>
                        </div>
                        <div>
                            <MenuDownIcon/>
                        </div>
                    </div>
                </DropdownToggle>
                <DropdownMenu style={{margin: "0"}}>
                    <DropdownItem onClick={() => {handleChangeLang("vi"); saveLang("vi")}}>
                        <div className="dropdown__item">
                            <img
                                src={VietnamFlag}
                                alt=""
                            /> <div> {t("vietnamese")} </div>
                        </div>
                    </DropdownItem>
                    <DropdownItem onClick={() => {handleChangeLang("en"); saveLang("en")}}>
                        <div className="dropdown__item">
                            <img
                                src={UKFlag}
                                alt=""
                            /> <div> {t("english")} </div>
                        </div>
                    </DropdownItem>
                </DropdownMenu>
            </UncontrolledDropdown>
        </>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        saveLang: data => dispatch({type: "SAVE_LANGUAGE", data})
    }
}

const mapStateToProps = (state) => {
    return {
        language: state.language
    }
}

export default withTranslation('common')(withRouter(connect(mapStateToProps, mapDispatchToProps)(Language)));