import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isLoading: false,
}

const listAllSetsSlice = createSlice({
    name:"listAllSets",
    initialState: initialState,
    reducers: {
        getListAllSets(state, action){
            return {
                isLoading: true,
            }
        },
        getListAllSetsSuccess(state, action){
            return {
                isLoading: false,
                ...action.payload
            }
        },
        getListAllSetsFailure(state, action){
            return {
                isLoading: false,
            }
        },
        cleanListAllSets(state, action){
            return {
                isLoading: false
            }
        }
    }
})

export const { cleanListAllSets, getListAllSets, getListAllSetsFailure, getListAllSetsSuccess } = listAllSetsSlice.actions

export default listAllSetsSlice.reducer