import React from "react"
import { withRouter } from "react-router-dom"
import VBChainNFTLogo  from "../../../assets/images/VBChainNFTLogo/VBChainNFTLogo.png"
import EBSLogo from "../../../assets/images/WalletLogo/EBSLogo.webp"
import { useMediaQuery } from 'react-responsive'

const Logo = props => {
    const {
        match,
        history,
    } = props

    const is_responsive = useMediaQuery({ query: '(max-width: 1199px)' })

    const handleMarketplace = () => {
        if( !match?.path?.includes("markplace") ) {
            history.push("/marketplace")
        }
    }

    return (
        <div className="header__logo"
            onClick={handleMarketplace}
        >
            <img
                alt=""
                src={!is_responsive ? VBChainNFTLogo : EBSLogo}
            />
        </div>
    )
}

export default withRouter(Logo)