import CryptoJS from "crypto-js";
import Tx from "ethereumjs-tx"

const handleSignTx = async (
    passphrase,
    encPrivateKey,
    rawTx,
    currentNetwork) => {
    try {
        const KEY = process.env.REACT_APP_KEY
        const decryptedWPassword = CryptoJS.AES.decrypt(passphrase, KEY).toString(CryptoJS.enc.Utf8);
        const dePrivateKey = CryptoJS.AES.decrypt(encPrivateKey, decryptedWPassword.toString()).toString(CryptoJS.enc.Utf8);
        const privatekeySplit = dePrivateKey.substring(2)
        const privateKey = Buffer.from(privatekeySplit, "hex")
        const transaction = new Tx(rawTx, {chainId: currentNetwork && currentNetwork.networkId ? parseInt(currentNetwork.networkId) : 0})
        transaction.sign(privateKey)
        const signedTx = '0x' + transaction.serialize().toString('hex') 
        return  {
            success: true,
            data: signedTx
        }
    } catch (error) {
        return {
            success: false
        }
    }
}

export default handleSignTx