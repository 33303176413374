import { takeLatest, call, put } from 'redux-saga/effects'
import { LINK_API } from "../../../constants/API"
import fetchData from "../fetchData"
import { getListProvenanceOfNFT, getListProvenanceOfNFTFailure, getListProvenanceOfNFTSuccess } from "../../reducer/config.nft.detail/listProvenanceOfNFTSlice"

function* fetchListProvenanceOfNFT (payload) {
    try {

        const url = LINK_API.GET_PROVENANCE_OF_NFT + `?item_id=${payload?.payload}`

        const response = yield call(fetchData, url)

        const data = response.data

        yield put({type: getListProvenanceOfNFTSuccess().type, payload: data})

    } catch (error) {
        yield put({ type: getListProvenanceOfNFTFailure().type })
        
    }
}

const listProvenanceOfNFTSaga = [
    takeLatest(getListProvenanceOfNFT().type, fetchListProvenanceOfNFT)
]

export default listProvenanceOfNFTSaga