import { takeLatest, call, put } from 'redux-saga/effects'
import { LINK_API } from "../../../constants/API"
import fetchData from "../fetchData"
import { getBidHistoryOfNFT, getBidHistoryOfNFTFailure, getBidHistoryOfNFTSuccess } from "../../reducer/config.nft.detail/bidHistoryOfNFTSlice"

function* fetchBidHistoryOfNFT (payload) {
    try {

        let url = LINK_API.GET_HISTORY_BID_OF_NFT + `/${payload?.payload}`

        const response = yield call(fetchData, url)

        const data = response?.data

        yield put({type: getBidHistoryOfNFTSuccess().type, payload: data})

    } catch (error) {
        yield put({ type: getBidHistoryOfNFTFailure().type })
        
    }
}

const bidHistoryOfNFTSaga = [
    takeLatest(getBidHistoryOfNFT().type, fetchBidHistoryOfNFT)
]

export default bidHistoryOfNFTSaga