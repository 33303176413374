import { lazy } from "react";

const DetailNFT = lazy(() => import("../pages/Detail.NFT"))
const DetailSet = lazy(() => import("../pages/Detail.Set"))
const Support = lazy(() => import("../pages/Support.Page/Support"))

const DetailRoutes = [
    {
        path: "/nft/detail/collectionAddr=:collectionAddr/tokenId=:tokenId/:mode",
        component: DetailNFT
    },
    {
        path: "/nft/detail/collectionAddr=:collectionAddr/tokenId=:tokenId",
        component: DetailNFT
    },
    {
        path: "/set/detail/:id/:mode",
        component: DetailSet,
    },
    {
        path: "/set/detail/:id",
        component: DetailSet,
    },
    {
        path: "/support",
        component: Support
    },
]

export default DetailRoutes