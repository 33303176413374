import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isLoading: false,
}

const wrapperBalanceSlice = createSlice({
    name:"wrapperBalance",
    initialState: initialState,
    reducers: {
        getWrapperBalance(state, action){
            return { isLoading: true }
        },
        getWrapperBalanceSuccess(state, action){
            return { isLoading: false, data: action.payload }
        },
        getWrapperBalanceFailure(state, action){
            return { isLoading: false }
        },
        cleanWrapperBalance(state, action){
            return { isLoading: false }
        }
    }
})

export const { cleanWrapperBalance, getWrapperBalance, getWrapperBalanceFailure, getWrapperBalanceSuccess } = wrapperBalanceSlice.actions

export default wrapperBalanceSlice.reducer