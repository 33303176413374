import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isLoading: false,
    is_failure: false,
}

const listPropertiesOfNFTSlice = createSlice({
    name:"listPropertiesOfNFT",
    initialState: initialState,
    reducers: {
        getListPropertiesOfNFT(state, action){
            return {
                isLoading: true,
                is_failure: false,
            }
        },
        getListPropertiesOfNFTSuccess(state, action){
            return {
                isLoading: false,
                is_failure: false,
                data: action?.payload,
            }
        },
        getListPropertiesOfNFTFailure(state, action){
            return {
                isLoading: false,
                is_failure: true,
            }
        },
        cleanListPropertiesOfNFT(state, action){
            return{
                isLoading: false,
                is_failure: false,
            }
        }
    }
})

export const { 
    cleanListPropertiesOfNFT,
    getListPropertiesOfNFT,
    getListPropertiesOfNFTFailure,
    getListPropertiesOfNFTSuccess,
} = listPropertiesOfNFTSlice.actions

export default listPropertiesOfNFTSlice.reducer