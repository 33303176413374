import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isLoading: false,
}

const listCategoriesSlice = createSlice({
    name:"listCategories",
    initialState: initialState,
    reducers: {
        getListCategories(state, action){
            return {
                isLoading: true,
            }
        },
        getListCategoriesSuccess(state, action){
            return {
                isLoading: false,
                data: action.payload
            }
        },
        getListCategoriesFailure(state, action){
            return {
                isLoading: false,
            }
        },
        cleanListCategories(state, action){
            return {
                isLoading: false
            }
        }
    }
})

export const { cleanListCategories, getListCategories, getListCategoriesFailure, getListCategoriesSuccess } = listCategoriesSlice.actions

export default listCategoriesSlice.reducer