import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    is_connected: false
}

const currentNetworkSlice = createSlice({
    name:"currentNetwork",
    initialState: initialState,
    reducers: {
        saveCurrentNetwork(state, action){
            return{ ...action.payload , is_connected: true}
        },
        cleanCurrentNetwork(state, action){
            return {is_connected: false}
        }
    }
})

export const { cleanCurrentNetwork, saveCurrentNetwork } = currentNetworkSlice.actions

export default currentNetworkSlice.reducer