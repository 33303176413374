import { createSlice } from "@reduxjs/toolkit";

const initialState = ""

const currentBcAddressSlice = createSlice({
    name:"currentBcAddress",
    initialState: initialState,
    reducers: {
        saveBcAddress(state, action){
            return action.payload
        },
        cleanBcAddress(state, action){
            return ""
        }
    }
})

export const { cleanBcAddress, saveBcAddress } = currentBcAddressSlice.actions

export default currentBcAddressSlice.reducer