import React from "react";
import "./index.scss"
import PassphraseModal from "./Passphrase.Modal";
import Axios from "axios"
import { LINK_API } from "../../../constants/API";
import CryptoJS from "crypto-js";
import { useDispatch } from "react-redux";
import { saveVbcWalletEncPass } from "../../../redux/reducer/config.wallet/vbcWalletEncPassSlice";
import { saveVBCWallet } from "../../../redux/reducer/config.wallet/currentVBCWalletInfoSlice";
import { saveBcAddress } from "../../../redux/reducer/config.wallet/currentBcAddressSlice";
import { saveVBCAccount } from "../../../redux/reducer/config.wallet/currentVBCAccountSlice";
import { setIsConnectWallet } from "../../../redux/reducer/config.wallet/isConnectWalletSlice";
import { saveVBCWalletType } from "../../../redux/reducer/config.wallet/walletTypeSlice";

const VBCWalletItem = props => {
    const {
        wallet,
        setOnProcess,
    } = props

    const [is_passphrase_modal, setIsPassphraseModal] = React.useState(false)
    const dispatch = useDispatch()

    const handleSelectWallet = async (temp_passphrase, formikOb) => {
        setOnProcess({status: "loading"})
        setIsPassphraseModal(!is_passphrase_modal)
        try {
            const KEY = process.env.REACT_APP_KEY
            const hashedPassword = CryptoJS.SHA256(temp_passphrase).toString();
            const encryptedWPassword = CryptoJS.AES.encrypt(temp_passphrase.toString(), KEY).toString();
            setOnProcess({status: "loading"})
    
            const changeIsActiveBody = {
                hashedPassword: hashedPassword,
                isActive: 1
            }
            Axios({
                method: "PUT",
                url: LINK_API.SWITCH_ACTIVE_WALLET + wallet?._id ,
                data: changeIsActiveBody
            })
            .then(res => {
                dispatch(saveVbcWalletEncPass(encryptedWPassword))
    
                Axios({
                    method: "GET",
                    url: LINK_API.GET_ACCOUNT_BY_PLATFORM + `?walletID=${wallet?._id}&&platform=0`
                })
                .then(res => {
                    const tempList = res?.data?.accounts
                    const tempBcAddress = tempList[0]?.address
                    setOnProcess({status: "closeAlert"})
    
                    dispatch(saveVBCWallet(wallet))
                    dispatch(saveBcAddress(tempBcAddress))
                    dispatch(saveVBCAccount(tempList[0]))
                    dispatch(setIsConnectWallet())
                    dispatch(saveVBCWalletType())
                })
                .catch(error => {
                    setOnProcess({status: "error"})
                    formikOb?.resetForm()
                })
            })
            .catch(error => {
                setOnProcess({status: "error"})
                formikOb?.resetForm()
            })
        } catch (error) {
            setOnProcess({status: "error"})
            formikOb?.resetForm()
        }
    }

    return (
        <>
            <PassphraseModal
                isOpen={is_passphrase_modal}
                toggle={() => setIsPassphraseModal(!is_passphrase_modal)}
                wallet={wallet}
                handleSelectWallet={handleSelectWallet}
            />
            <div className="waSi__vbcWaItem" onClick={() => setIsPassphraseModal(!is_passphrase_modal)}>  
                {wallet?.name}
            </div>
        </>
    )
}

export default VBCWalletItem