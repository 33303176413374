import React from "react"
import SweetAlert from 'react-bootstrap-sweetalert';
import { withTranslation } from 'react-i18next';
import {Button} from "reactstrap"
import "./css/sweet.css"
function Error(...props) {
    const handleError = () => {
        if(props[0].onClose){
            props[0].onClose();
        }
    }
    return (
        <SweetAlert
            error
            title={props[0].title ? props[0].title : props[0].t('failure')}
            showCancel={false}
            onConfirm={handleError}
            showConfirm={false}
            confirmBtnText={props[0].confirmBtnText ? props[0].confirmBtnText : props[0].t('confirm')  }
        >
             {/* <Button  color="neutral-danger" onClick={handleError}>{props[0].confirmBtnText ? props[0].confirmBtnText : props[0].t('close')}</Button> */}
            <Button  color="neutral-danger" onClick={handleError}>{props[0].confirmBtnText ? props[0].confirmBtnText : props[0].t('close')}</Button>
        </SweetAlert>
    )
}

export default withTranslation('common')(Error);