import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isLoading: false,
}

const listNetworkSlice = createSlice({
    name:"listNetwork",
    initialState: initialState,
    reducers: {
        getListNetwork(state, action){
            return {
                isLoading: true,
            }
        },
        getListNetworkSuccess(state, action){
            return {
                isLoading: false,
                data: action.payload
            }
        },
        getListNetworkFailure(state, action){
            return {
                isLoading: false,
            }
        },
    }
})

export const { getListNetwork, getListNetworkFailure, getListNetworkSuccess } = listNetworkSlice.actions

export default listNetworkSlice.reducer