import { takeLatest, call, put } from 'redux-saga/effects'
import { LINK_API } from "../../../constants/API"
import fetchData from "../fetchData"
import { getOwnedNFTsList, getOwnedNFTsListSuccess, getOwnedNFTsListFailure } from "../../reducer/config.profile/ownedNFTsListSlice"

function* fetchOwnedNFTsList (payload) {
    try {

        const temp_payload = payload?.payload

        const params = {
            page: temp_payload?.page || 1,
            size: 12,
            isSet: 0,
            isBundle: 0,
            owner: temp_payload?.owner,
            status: "ONSALE,NOTFORSALE,SOLD,INCOMING",
            likeAddress: temp_payload?.likeAddress
        }

        const url = LINK_API.GET_LIST_NFTS + "-unique" 
        const response = yield call(fetchData, url, "GET", {}, params)

        const data = response.data
 
        yield put({type: getOwnedNFTsListSuccess().type, payload: data})

    } catch (error) {
        yield put({ type: getOwnedNFTsListFailure().type })
        
    }
}

const ownedNFTsListSaga = [
    takeLatest(getOwnedNFTsList().type, fetchOwnedNFTsList)
]

export default ownedNFTsListSaga