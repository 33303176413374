import { takeLatest, call, put } from 'redux-saga/effects'
import { LINK_API } from "../../../constants/API"
import fetchData from "../fetchData"
import { getActivitesOfCollection, getActivitesOfCollectionFailure, getActivitesOfCollectionSuccess } from "../../reducer/config.collection.detail/activitiesOfCollectionSlice"

function* fetchActivitiesOfCollection (payload) {
    try {
        const temp_payload = payload?.payload

        const temp_params = {
            "page": 1,
            "size": 20,
            ...temp_payload,
        }

        const url = LINK_API.GET_ACTIVITIES_OF_COLLECTION

        const response = yield call(fetchData, url, "GET", {}, temp_params)

        const data = response?.data

        yield put({type: getActivitesOfCollectionSuccess().type, payload: data})

    } catch (error) {
        yield put({ type: getActivitesOfCollectionFailure().type, payload: {isError: true} })
        
    }
}

const activitiesOfCollectionSaga = [
    takeLatest(getActivitesOfCollection().type, fetchActivitiesOfCollection)
]

export default activitiesOfCollectionSaga