import { createSlice } from "@reduxjs/toolkit";

const initialState = {}

const currentVBCAccountSlice = createSlice({
    name:"currentVBCAccount",
    initialState: initialState,
    reducers: {
        saveVBCAccount(state, action){
            return {...action.payload}
        },
        cleanVBCAccount(state, action){
            return {}
        }
    }
})

export const { cleanVBCAccount, saveVBCAccount } = currentVBCAccountSlice.actions

export default currentVBCAccountSlice.reducer