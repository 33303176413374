import React from "react"
import SweetAlert from 'react-bootstrap-sweetalert';
import { withTranslation } from 'react-i18next';
import {Button} from "reactstrap"
import "./css/sweet.css"

const Warning = props => {

    const {
        onConfirm,
        title,
        hideCancel,
        subTitle,
        t
    } = props

    return(
        <SweetAlert
            title={""}
            warning
            onConfirm={() => {}}
            showConfirm={false}
        >
            <h1>{title ? title : t("warning")}</h1>
            {
                subTitle ?
                <p>{subTitle}</p>
                :
                null
            }

            <div
                style={{display: "flex", justifyContent: "center", marginTop: "20px"}}
            >
                {
                    hideCancel ? null :
                    <Button color="neutral-danger" className="mr-4">
                        {t("cancel")}
                    </Button>
                }
                <Button color="neutral-warning" onClick={onConfirm ? onConfirm : () => {}}>
                    {t("confirm")}
                </Button>
            </div>
        </SweetAlert>
    )
}

export default withTranslation('common')(Warning);