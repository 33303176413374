import { takeLatest, call, put } from 'redux-saga/effects'
import { LINK_API } from "../../../constants/API"
import fetchData from "../fetchData"
import { getBidTypeHistory, getBidTypeHistoryFailure, getBidTypeHistorySuccess } from "../../reducer/config.profile/bidTypeHistorySlice"


function* fetchBidTypeHistory (payload) {
    try {
        const temp_payload = payload?.payload

        const params = {
            "type": temp_payload.type,
            "isLocked": temp_payload.isLocked,
            "networkId": temp_payload.networkId,
        }

        const bidder = temp_payload?.bidder ? `/${temp_payload?.bidder}` : ""

        const url = LINK_API.GET_BID_TYPE_HISTORY + bidder

        const response = yield call(fetchData, url, "GET", {} , params)

        const data = response.data

        yield put({type: getBidTypeHistorySuccess().type, payload: data})

    } catch (error) {
        yield put({ type: getBidTypeHistoryFailure().type })
    }
}

const bidTypeHistorySaga = [
    takeLatest(getBidTypeHistory().type, fetchBidTypeHistory)
]

export default bidTypeHistorySaga