import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isLoading: false,
    is_failure: false,
}

const allBundlesListSlice = createSlice({
    name:"allBundlesList",
    initialState: initialState,
    reducers: {
        getAllBundlesList(state, action){
            return {
                isLoading: true,
                is_failure: false,
            }
        },
        getAllBundlesListSuccess(state, action){
            return {
                isLoading: false,
                is_failure: false,
                ...action.payload
            }
        },
        getAllBundlesListFailure(state, action){
            return {
                isLoading: false,
                is_failure: true,
            }
        },
        cleanAllBundlesList(state, action){
            return {
                isLoading: false,
                is_failure: false,
            }
        }
    }
})

export const { cleanAllBundlesList, getAllBundlesList, getAllBundlesListFailure, getAllBundlesListSuccess } = allBundlesListSlice.actions

export default allBundlesListSlice.reducer