import React from "react";
import Language from "./Language";
import Close from "./Close";
import Wallet from "../Container.Comp/Wallet";
import ProductMenu from "./Product.Menu";

const Container = props => {
    const {
        toggle,
        handleSetIsAccountSidebar,
    } = props

    return (
        <div className="headerSidebar__container">
            <div className="headerSidebar__container--content normal-scrollbar">
                <Close toggle={toggle} />
                <div style={{
                    width: "100%", padding: "20px 20px 0px 20px"
                }}>
                    <Wallet handleSetIsAccountSidebar={handleSetIsAccountSidebar}/>
                </div>
                
                <ProductMenu/>
            </div>
            <Language/>
        </div>  
    )
}

export default Container