import { takeLatest, call, put } from 'redux-saga/effects'
import { LINK_API } from "../../../constants/API"
import fetchData from "../fetchData"
import { getCollectionsListOfProfile, getCollectionsListOfProfileFailure, getCollectionsListOfProfileSuccess } from "../../reducer/config.profile/collectionsListOfProfileSlice"

function* fetchCollectionsListOfProfile (payload) {
    try {
        const params = {
            size: 12,
            page: payload?.payload?.page || 1,
            creator: payload?.payload?.creator,
            likeAddress: payload?.payload?.likeAddress,
        }

        const url = LINK_API.GET_LIST_COLLECTIONS

        const response = yield call(fetchData, url, "GET", {} , params)

        const data = response.data

        yield put({type: getCollectionsListOfProfileSuccess().type, payload: data})

    } catch (error) {
        yield put({ type: getCollectionsListOfProfileFailure().type })
        
    }
}

const collectionsListOfProfileSaga = [
    takeLatest(getCollectionsListOfProfile().type, fetchCollectionsListOfProfile)
]

export default collectionsListOfProfileSaga