import React from "react";
import { useTranslation } from "react-i18next";
import { Input, InputGroup, InputGroupText, Label, Modal, ModalBody, ModalFooter } from "reactstrap";
import ModalHeaderComponent from "../../ModalHeader";
import LockIcon from "mdi-react/LockIcon"
import * as Yup from "yup"
import { useFormik } from "formik"
import EyeOutlineIcon from "mdi-react/EyeOutlineIcon"
import EyeRemoveOutlineIcon from "mdi-react/EyeRemoveOutlineIcon"

const PassphraseModal = props => {
    const {
        isOpen,
        toggle,
        wallet,
        handleSelectWallet,
    } = props

    const { t } = useTranslation("common")

    const passphraseSchema = Yup.object().shape({
        passphrase: Yup.string()
            .required(t("require"))
        ,
    })

    const formik = useFormik({
        initialValues: {
            passphrase: "",
        },
        validationSchema: passphraseSchema,
        onSubmit: async (values) => {
            await handleConfirmPass(
                values?.passphrase,
            )
        },
    })
    const handleConfirmPass = async (passphrase) => {
        await handleSelectWallet(passphrase, formik)
    }

    const [is_show_pass, setIsShowPass] = React.useState(false)
   
    const handleToggle = () => {
        toggle()
        formik.resetForm()
    }

    return (
        <Modal isOpen={isOpen} toggle={handleToggle} centered>
            <ModalHeaderComponent
                toggle={handleToggle}
                title={t("activeWallet")}
            />
            <ModalBody
                onKeyDown={e => {
                    if(e.keyCode === 13) {
                        formik.handleSubmit()
                    }
                }}
            >
                <Label>
                    {t("walletName")}: <strong>{wallet?.name || t("noDataFound")}</strong>
                </Label>
                <br/>
                <Label>
                    {t("passphrase")}:
                </Label>
                <InputGroup>
                    <InputGroupText>
                        <LockIcon className="icon-input-primary" />
                    </InputGroupText>
                    <Input
                        placeholder={t("passphrase")}
                        type={is_show_pass ? "text" : "password"}
                        name="passphrase"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.passphrase}
                        valid={formik.errors.passphrase && formik.touched.passphrase ? false : formik.values.passphrase ? true : false}
                        invalid={formik.touched.passphrase && formik.errors.passphrase ? true : false}
                        className="hide-valid-invalid"
                        style={{borderRadius: "0 0.29rem 0.29rem 0"}}
                    />
                    <div className="icon-inside-input-left">
                        <div onClick={() => setIsShowPass(!is_show_pass)} >
                            {
                                is_show_pass ?
                                <EyeRemoveOutlineIcon/>
                                :
                                <EyeOutlineIcon/>
                            }
                        </div>
                    </div>
                </InputGroup>
                {formik.touched.passphrase && formik.errors.passphrase ? (
                    <p className="font-weight-regular font-size-sm text-danger mb-0 mt-1 pt-1">
                    {formik.errors.passphrase}
                    </p>
                ) : null}
            </ModalBody>
            <ModalFooter>
                <div 
                    className={`
                        ${ formik?.values?.passphrase && !formik?.errors?.passphrase ? "normal-btn" : "disabled-normal-btn" }
                    `}
                    onClick={() => {
                        if( formik?.values?.passphrase && !formik?.errors?.passphrase) {
                            formik.handleSubmit()
                        }
                    }}
                >
                    {t("activeWallet")}
                </div>
            </ModalFooter>
        </Modal>
    )
}

export default PassphraseModal