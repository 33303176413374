import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isLoading: false,
    is_failure: false,
}

const bidHistoryOfNFTSlice = createSlice({
    name:"bidHistoryOfNFT",
    initialState: initialState,
    reducers: {
        getBidHistoryOfNFT(state, action){
            return {
                isLoading: true,
                is_failure: false,
            }
        },
        getBidHistoryOfNFTSuccess(state, action){
            return {
                isLoading: false,
                is_failure: false,
                data: action?.payload,
            }
        },
        getBidHistoryOfNFTFailure(state, action){
            return {
                isLoading: false,
                is_failure: true,
            }
        },
        cleanBidHistoryOfNFT(state, action){
            return{
                isLoading: false,
                is_failure: false,
            }
        }
    }
})

export const { 
    cleanBidHistoryOfNFT,
    getBidHistoryOfNFT,
    getBidHistoryOfNFTFailure,
    getBidHistoryOfNFTSuccess,
} = bidHistoryOfNFTSlice.actions

export default bidHistoryOfNFTSlice.reducer