import { takeLatest, call, put } from 'redux-saga/effects'
import { LINK_API } from "../../../constants/API"
import fetchData from "../fetchData"
import { getOrderHistory, getOrderHistoryFailure, getOrderHistorySuccess } from "../../reducer/config.profile/orderHistorySlice"


function* fetchOrderHistory (payload) {
    try {
        const temp_payload = payload?.payload

        const params = {
            type: temp_payload?.type,
        }

        const url = LINK_API.GET_ORDER_HISTORY + `/${temp_payload?.addressBC}`

        const response = yield call(fetchData, url, "GET", {} , params)

        const data = response.data

        yield put({type: getOrderHistorySuccess().type, payload: data})

    } catch (error) {
        yield put({ type: getOrderHistoryFailure().type })
    }
}

const orderHistorySaga = [
    takeLatest(getOrderHistory().type, fetchOrderHistory)
]

export default orderHistorySaga