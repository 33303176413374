import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isLoading: false,
    is_failure: false,
}

const detailUserSlice = createSlice({
    name:"detailUser",
    initialState: initialState,
    reducers: {
        getDetailUser(state, action){
            return {
                isLoading: true,
                is_failure: false,
            }
        },
        getDetailUserSuccess(state, action){
            return {
                isLoading: false,
                is_failure: false,
                ...action.payload
            }
        },
        getDetailUserFailure(state, action){
            return {
                isLoading: false,
                is_failure: true,
                ...action.payload,
            }
        },
        cleanDetailUser(state, action){
            return {
                isLoading: false,
                is_failure: false,
            }
        }
    }
})

export const { cleanDetailUser, getDetailUser, getDetailUserFailure, getDetailUserSuccess } = detailUserSlice.actions

export default detailUserSlice.reducer