import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isLoading: false,
    is_failure: false,
}

const totalBidBalanceSlice = createSlice({
    name:"totalBidBalance",
    initialState: initialState,
    reducers: {
        getTotalBidBalance(state, action){
            return {
                isLoading: true,
                is_failure: false,
            }
        },
        getTotalBidBalanceSuccess(state, action){
            return {
                isLoading: false,
                is_failure: false,
                data: action.payload
            }
        },
        getTotalBidBalanceFailure(state, action){
            return {
                isLoading: false,
                is_failure: true,
            }
        },
        cleanTotalBidBalance(state, action) {
            return {
                isLoading: false,
                is_failure: false,
            }
        }
    }
})

export const { cleanTotalBidBalance, getTotalBidBalance, getTotalBidBalanceFailure, getTotalBidBalanceSuccess } = totalBidBalanceSlice.actions

export default totalBidBalanceSlice.reducer