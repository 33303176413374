import React from "react";
import { useTranslation } from "react-i18next";
import DotsVerticalIcon from "mdi-react/DotsVerticalIcon"
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import CONTRACT_TYPE from "../../../constants/CONTRACT_TYPE"
import { getWrapperBalance } from "../../../redux/reducer/config.balance/wrapperBalanceSlice";
import Web3 from "web3"
import { getTotalBidBalance } from "../../../redux/reducer/config.wallet/totalBidBalanceSlice";
import { handleRenderPrice } from "../../../constants/handleRenderPrice";
import UnwrapModal from "./Unwrap.Modal";
import unwrapBalance from "../utils/unwrapBalance";
import { getAccountBalance } from "../../../redux/reducer/config.balance/accountBalanceSlice";
import RenderPrice from "../../Render.Price";

const WrapperBalance = props => {
    const {setOnProcess, isOpen} = props
    const { t } = useTranslation("common")

    const dispatch = useDispatch()
    const currentNetworkSlice = useSelector(state => state.currentNetworkSlice)
    const currentBcAddressSlice = useSelector(state => state.currentBcAddressSlice)

    const totalBidBalanceSlice = useSelector(state => state.totalBidBalanceSlice)
    const total_bid_wei = totalBidBalanceSlice?.data?.toString() || "0"
    const total_bid_eth = parseFloat(Web3.utils.fromWei(total_bid_wei, "ether"))


    const wrapperBalanceSlice = useSelector(state => state.wrapperBalanceSlice)
    const data_wei = wrapperBalanceSlice?.data?.toString()
    const data_eth = data_wei ? parseFloat(Web3.utils.fromWei(data_wei, "ether")) : 0

    const temp_wei = wrapperBalanceSlice?.data && Web3.utils.toBN(wrapperBalanceSlice?.data?.toString())
    const temp_lock_wei = totalBidBalanceSlice?.data && Web3.utils.toBN(totalBidBalanceSlice?.data?.toString())
    const temp_available_wei = temp_wei && temp_lock_wei && temp_wei.sub(temp_lock_wei)
    const temp_available_eth = temp_available_wei && parseFloat(Web3.utils.fromWei(temp_available_wei, "ether"))

    const walletTypeSlice = useSelector(state => state.walletTypeSlice)
    const vbcWalletEncPassSlice = useSelector(state => state.vbcWalletEncPassSlice)
    const currentVBCAccountSlice = useSelector(state => state.currentVBCAccountSlice)

    const handleGetWrapperBalance = React.useCallback(() => {
        const body = {
            network_id: currentNetworkSlice?.networkId?.toString(),
            function_name: "balanceOf",
            param_list: [currentBcAddressSlice, 1],
            contract_type: CONTRACT_TYPE.WRAPPER
        }
        if(isOpen){
            dispatch(getWrapperBalance(body))
        }
    }, [dispatch, currentBcAddressSlice, currentNetworkSlice, isOpen])

    React.useEffect(() => {
        handleGetWrapperBalance()
    }, [handleGetWrapperBalance])

    const handleGetTotalBidBalance = React.useCallback(() => {
        const payload = {
            bidder: currentBcAddressSlice,
            networkId: currentNetworkSlice?.networkId?.toString()
        }
        dispatch(getTotalBidBalance(payload))
    }, [currentBcAddressSlice, currentNetworkSlice?.networkId, dispatch])

    React.useEffect(() => {
        handleGetTotalBidBalance()
    }, [handleGetTotalBidBalance])

    const [is_unwrap_modal, setIsUnwrapModal] = React.useState(false)
    const openModal = () => {
        if(handleRenderPrice(data_eth - total_bid_eth, 4) > 0) {
            setIsUnwrapModal(!is_unwrap_modal)
        }
        else {
            setOnProcess({status: "warning", hideCancel: true, title: t("notEnoughWrapperBalance")})
        }      
    }

    const handleGetAccountBlance = React.useCallback(() => {
        const body = {
            url: currentNetworkSlice?.domain + `${currentNetworkSlice?.networkId?.toString() === "97" ? "" : "VBC001"}`,
            params: {
                jsonrpc: "2.0",
                id: currentNetworkSlice?._id,
                params: [currentBcAddressSlice, "latest"],
                method: "eth_getBalance",
            }
        }
        dispatch(getAccountBalance(body))
    }, [currentNetworkSlice, currentBcAddressSlice, dispatch])

    const handleUnwrap = async (amount, formikOb) => {
        setIsUnwrapModal(!is_unwrap_modal)
        setOnProcess({status: "loading"})
        try {
            const unwrap_params = {
                "network_id": currentNetworkSlice?.networkId?.toString(),
                "token_address": null,
                "is_native_token": true,
                "value": amount ? Web3.utils.toWei(amount?.toString(), "ether") : "0",
                "is_metamask": walletTypeSlice === "MetaMask" ? true : false,
                "bc_address": currentBcAddressSlice,
                walletTypeSlice: walletTypeSlice ? walletTypeSlice : "",
                currentNetworkSlice: currentNetworkSlice ? currentNetworkSlice : "",
                vbcWalletEncPassSlice: vbcWalletEncPassSlice ? vbcWalletEncPassSlice : "",
                encPrivateKey: currentVBCAccountSlice?.encPrivateKey,
                currentBcAddressSlice: currentBcAddressSlice ? currentBcAddressSlice : ""
            }
            const unwrap_res = await unwrapBalance(unwrap_params)
            if(unwrap_res?.success){
                setOnProcess({status: "closeAlert"})
                handleGetAccountBlance()
                handleGetWrapperBalance()
                formikOb.resetForm()
            }
            else {
                setOnProcess({status: "error"})
                formikOb.resetForm()
            }
        } catch (error) {
            setOnProcess({status: "error"})
            formikOb.resetForm()
        }
    }

    return (
        <>
            <UnwrapModal
                isOpen={is_unwrap_modal}
                toggle={() => setIsUnwrapModal(!is_unwrap_modal)}
                handleUnwrap={handleUnwrap}
            />
            <div className="waSi__bala">
                <div className={
                    `
                        waSi__bala--title
                        wrapper
                    `
                }
                
                >
                    <div className="waSi__bala--contentTitle">
                        {t("wrapperBalance")}
                    </div>
                    <div className="waSi__bala--options">
                        <UncontrolledDropdown direction="start">
                            <DropdownToggle tag={"span"}>
                                <div className="waSi__bala--opt">
                                    <DotsVerticalIcon/>
                                </div>
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem onClick={openModal}>
                                    <div className="dropdown__item">
                                        {t("unwrap")}
                                    </div>
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </div>
                </div>
                <div className="waSi__bala--value">
                    <RenderPrice
                        type={currentNetworkSlice?.networkId?.toString() === "97" ? "WBNB" : "WVBC"}
                        data={temp_available_eth}
                    />
                    {
                        total_bid_eth > 0 ?
                        <>
                            <div className="mt-2">
                                <span style={{color: "gray"}}> {t("wrapperLocked")}: </span> <span>{handleRenderPrice(total_bid_eth, 4)}</span>
                            </div>
                            <div className="mt-2">
                                <span style={{color: "#28a745"}}> {t("wrapperAvailable")}: </span> <span>{handleRenderPrice((data_eth), 4)}</span>
                            </div>
                        </>
                        :
                        null
                    }
                </div>
            </div>
        </>
    )
}

export default WrapperBalance