import React from "react";
import Container from "./Container";

const Wrapper = props => {
    const {
        isOpen, toggle,
        handleSetIsAccountSidebar,
    } = props

    return(
        <div className={
            `
                headerSidebar__wrapper
                ${isOpen ? "isOpen" : ""}
            `
        }
        
        >
            <div 
                style={{
                    height: "100vh",
                    flexGrow: "1",
                    cursor: "pointer"
                }}
                onClick={toggle}
            />
            <Container toggle={toggle} handleSetIsAccountSidebar={handleSetIsAccountSidebar} />
        </div>
    )
}

export default Wrapper