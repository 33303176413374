import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isLoading: false,
}

const listAllCollectionsSlice = createSlice({
    name:"listAllCollections",
    initialState: initialState,
    reducers: {
        getListAllCollections(state, action){
            return {
                isLoading: true,
            }
        },
        getListAllCollectionsSuccess(state, action){
            return {
                isLoading: false,
                ...action.payload
            }
        },
        getListAllCollectionsFailure(state, action){
            return {
                isLoading: false,
            }
        },
        cleanListAllCollections(state, action){
            return {
                isLoading: false
            }
        }
    }
})

export const { cleanListAllCollections, getListAllCollections, getListAllCollectionsFailure, getListAllCollectionsSuccess } = listAllCollectionsSlice.actions

export default listAllCollectionsSlice.reducer