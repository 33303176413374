
import loadWeb3 from "./loadWeb3";

const switchNetworkOnMetaMask = async (currentNetworkSlice) => {

    const is_load_web3_result = await loadWeb3()

    if(is_load_web3_result){
        try {
            let success = false
            try {
                await window.ethereum.request({
                  method: "wallet_switchEthereumChain",
                  params: [{ chainId: `0x${currentNetworkSlice?.networkId?.toString(16)}` }],
                });
                success = true
                return {success}
            } 
            catch (switchError) {
                if (switchError.code === 4902) {
                    try {
                        await window.ethereum.request({
                        method: "wallet_addEthereumChain",
                        params: [
                            {
                            chainName: currentNetworkSlice?.name,
                            chainId: `0x${currentNetworkSlice?.networkId?.toString(16)}`,
                            rpcUrls: [currentNetworkSlice?.domain + `${currentNetworkSlice?.networkId?.toString() === "97" ? "" : "VBC001"}`],
                            iconUrls: [currentNetworkSlice?.icon],
                            },
                        ],
                        });
                        success = true
                        return {success}
                    } catch (addError) {
                        success = false
                        return {success}
                    }
                }
                else {
                    success = false
                    return {success}
                }
            }
            
        } catch (error) {
            ;
            return {success: false}
        }
    }
    else {
        return {success: false}
    }
   
}

export default switchNetworkOnMetaMask