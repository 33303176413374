import { takeLatest, call, put, select } from 'redux-saga/effects'
import { LINK_API } from "../../../constants/API"
import fetchData from "../fetchData"
import { getDetailProfileAccount, getDetailProfileAccountFailure, getDetailProfileAccountSuccess } from "../../reducer/config.profile/detailProfileAccountSlice"
import Axios from 'axios'

const accountSlice = state => state.accountSlice

function* fetchDetailProfileAccount (payload) {
    try {

        const account = yield select(accountSlice)

        Axios.defaults.headers.common.Authorization = `Bearer ${account?.data?.token}`;

        const url = LINK_API.GET_DETAIL_PROFILE_DASHBOARD_ACCOUNT

        const response = yield call(fetchData, url)

        const data = response.data

        yield put({type: getDetailProfileAccountSuccess().type, payload: data})

    } catch (error) {
        yield put({ type: getDetailProfileAccountFailure().type })
        
    }
}

const detailProfileAccountSaga = [
    takeLatest(getDetailProfileAccount().type, fetchDetailProfileAccount)
]

export default detailProfileAccountSaga