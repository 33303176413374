import { takeLatest, call, put } from 'redux-saga/effects'
import { LINK_API } from "../../../constants/API"
import fetchData from "../fetchData"
import { getDetailUser, getDetailUserFailure, getDetailUserSuccess } from "../../reducer/config.user.detail/detailUserSlice"

function* fetchDetailUser (payload) {
    try {
        const temp_payload = payload?.payload
        const params = {
            ...temp_payload,
        }

        let url = LINK_API.GET_USER_LIST

        const response = yield call(fetchData, url, "GET", {} , params)

        const data = response?.data
        const render_data = data?.data[0]

        if(render_data) {
            yield put({type: getDetailUserSuccess().type, payload: render_data})
        }
        else {
            yield put({ type: getDetailUserFailure().type })
        }
    } catch (error) {
        yield put({ type: getDetailUserFailure().type })
    }
}

const detailUserSaga = [
    takeLatest(getDetailUser().type, fetchDetailUser)
]

export default detailUserSaga