import React from "react";
import MenuIcon from "mdi-react/MenuIcon"
import "./index.scss"
import Wrapper from "./Wrapper";

const HeaderSidebar = props => {
    const {
        handleSetIsAccountSidebar
    } = props

    const [is_show_sidebar, setIsShowSidebar] = React.useState(false)
    const handleSetIsShowSidebar = () => {
        setIsShowSidebar(!is_show_sidebar)
    }

    return (
        <>
            <div className="headerSidebar__btn"
                onClick={handleSetIsShowSidebar}
            >
                <MenuIcon/>
            </div>
            <Wrapper
                isOpen={is_show_sidebar}
                toggle={handleSetIsShowSidebar}
                handleSetIsAccountSidebar={handleSetIsAccountSidebar}
            />
        </>
    )
}

export default HeaderSidebar