import { lazy } from "react";

const Marketplace = lazy(() => import('../pages/Marketplace'))
const AllNFTs = lazy(() => import("../pages/All.NFTs"))
const AllCollections = lazy(() => import("../pages/All.Collections"))
const AllSets = lazy(() => import("../pages/All.Sets"))
const AllBundles = lazy(() => import("../pages/All.Bundles"))
const DownloadApp = lazy(() => import("../pages/Download.App"))
const Support = lazy(() => import("../pages/Support.Page/Support"))
const Notifications = lazy(() => import("../pages/Notifications"))
const Fractional = lazy(() => import("../pages/Fractional"))
const DetailBundle = lazy(() => import("../pages/Detail.Bundle"))
const DetailUser = lazy(() => import("../pages/Detail.User"))
const DetailProfile = lazy(() => import("../pages/Detail.Profile"))
const OrderHistory = lazy(() => import("../pages/Order.History"))
const DetailCollection = lazy(() => import("../pages/Detail.Collection"))
const InspectionReport = lazy(() => import("../pages/Inspection.Report"))
const DetailPreOrder = lazy(() => import("../pages/Detail.Pre.Order"))

const MainRoutes = [
    {
        path: "/marketplace",
        component: Marketplace
    },
    {
        path: "/nfts/page=:page",
        component: AllNFTs
    },
    {
        path: "/collections/page=:page",
        component: AllCollections
    },
    {
        path: "/sets/page=:page",
        component: AllSets
    },
    {
        path: "/download-app",
        component: DownloadApp
    },
    {
        path: "/support",
        component: Support
    },
    {
        path: "/notification",
        component: Notifications
    },
    {
        path: "/fractional",
        component: Fractional,
    },
    {
        path: "/bundle/detail/:id",
        component: DetailBundle,
    },
    {
        path: "/bundles/page=:page",
        component: AllBundles,
    },
    {
        path: "/user/detail/:id",
        component: DetailUser,
    },
    {
        path: "/profile",
        component: DetailProfile
    },
    {
        path: "/order-history",
        component: OrderHistory,
    },
    {
        path: "/collection/detail/:id",
        component: DetailCollection,
    },
    {
        path: "/nft/inspection/:id",
        component: InspectionReport,
    },
    {
        path: "/pre-order/detail",
        component: DetailPreOrder
    },
]

export default MainRoutes