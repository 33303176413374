import React from "react";
import Loading from "./loading.gif"

const Suspense = props => {
    return(
        <div
            style={{
                position: "fixed",
                width: "100vw",
                height: "100vh"
            }}
            className="d-flex align-items-center justify-content-center"
        >
            <img
                src={Loading}
                alt="..."
                style={{
                    width: "30vw"
                }}
            />
        </div>
    )
}

export default Suspense