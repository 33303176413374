import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { UncontrolledTooltip } from "reactstrap"
import renderAddress from "../../../utils/renderAddress";
import { useTranslation } from "react-i18next";
import { Copy } from "react-feather"
import loadWeb3 from "../../../utils/loadWeb3";
import Axios from "axios"
import { LINK_API } from "../../../constants/API";
import { saveCurrentNetwork } from "../../../redux/reducer/config.network/currentNetworkSlice";
import { setIsConnectWallet, setIsNotConnectWallet } from "../../../redux/reducer/config.wallet/isConnectWalletSlice";
import { cleanWalletType, saveMetaMaskWalletType } from "../../../redux/reducer/config.wallet/walletTypeSlice";
import { cleanBcAddress, saveBcAddress } from "../../../redux/reducer/config.wallet/currentBcAddressSlice";
import { cleanVbcWalletEncPass } from "../../../redux/reducer/config.wallet/vbcWalletEncPassSlice";
import { cleanListVBCAccount } from "../../../redux/reducer/config.wallet/listVBCAccountSlice";
import { cleanVBCAccount } from "../../../redux/reducer/config.wallet/currentVBCAccountSlice";
import { cleanVBCWallet } from "../../../redux/reducer/config.wallet/currentVBCWalletInfoSlice";
import { cleanAccountBalance } from "../../../redux/reducer/config.balance/accountBalanceSlice";
import { cleanWrapperBalance } from "../../../redux/reducer/config.balance/wrapperBalanceSlice";
import Web3 from "web3"

const MetaMaskAddress = props => {
    const {setOnProcess} = props
    const { t } = useTranslation("common")
    const dispatch = useDispatch()
    const currentBcAddressSlice = useSelector(state => state.currentBcAddressSlice)
    const walletTypeSlice = useSelector(state => state.walletTypeSlice)
    const listNetworkSlice = useSelector(state => state.listNetworkSlice)
    const detailProfileAccountSlice = useSelector(state => state.detailProfileAccountSlice)
    const accountSlice = useSelector(state => state.accountSlice)

    React.useEffect(() => {
        const checkWeb3 = async () => {
            const loadWeb3_res = await loadWeb3()
            return loadWeb3_res
        }
        checkWeb3()
    }, [])

    const handleChainChanged = React.useCallback((chainId) => {
        setOnProcess({status: "loading"})
        if(walletTypeSlice === "MetaMask" && chainId){
            const find_network = listNetworkSlice?.data?.find(nw => chainId ===  `0x${nw.networkId.toString(16)}`)
            if(find_network){
                dispatch(saveCurrentNetwork(find_network))
                setOnProcess({status: "closeAlert"})
            }
            else {
                setOnProcess({status: "chooseNw"})
            }
        }
        else {
            setOnProcess({status: "error"})
        }
    }, [walletTypeSlice, dispatch, listNetworkSlice?.data, setOnProcess])

    React.useEffect(() => {
        if(window.ethereum){
            if(walletTypeSlice === "MetaMask"){
                window.ethereum.on("chainChanged", handleChainChanged)
            }
            else {
                window.ethereum.removeListener("chainChanged", handleChainChanged)
            }
        }
        return () => window.ethereum.removeListener("chainChanged", handleChainChanged)
    },[handleChainChanged, walletTypeSlice])

    const handleAccountsChanged = React.useCallback((accounts) => {
        setOnProcess({status: "loading"})
        if(accounts[0]){
            const account = Web3.utils.toChecksumAddress(accounts[0])
            Axios({
                method: "GET",
                url: LINK_API.CHECK_BC_ADDRESS,
                params: {"bc_address": account}
            })
            .then(async(res) => {
                if(res?.data?.data){
                    const owned_bc_address = detailProfileAccountSlice?.data?.bc_address_mints ? detailProfileAccountSlice?.data?.bc_address_mints : []
                    const find_bc_address = owned_bc_address.find(bc_address => bc_address.toString() === account?.toString())
                    if(find_bc_address){
                        dispatch(saveBcAddress(find_bc_address))
                        dispatch(saveMetaMaskWalletType())
                        dispatch(setIsConnectWallet())
                        setOnProcess({status: "closeAlert"})
                    }
                    else {
                        setOnProcess({status: "warning", hideCancel: true, title: t("bcAddrInUse"), subTitle: t("plsUseAnotherAddr")})
                    }
                }
                else {
                    Axios.defaults.headers.common.Authorization = `Bearer ${accountSlice?.data?.token}`;
                    await Axios({
                        method: "POST",
                        url: LINK_API.ADD_ADDRESS_TO_VBCHAIN_ACCOUNT,
                        data: {
                            "new_bc_address": account
                        },
                    })
                    .then(res => {
                        dispatch(saveBcAddress(account))
                        dispatch(saveMetaMaskWalletType())
                        dispatch(setIsConnectWallet())
                        setOnProcess({status: "closeAlert"})
                    })
                    .catch(error => {
                        setOnProcess({status: "error"})
                    })
                }
            })
            .catch(error => {
                setOnProcess({status: "error"})
            })
        }
        else {
            dispatch(setIsNotConnectWallet())
            dispatch(cleanWalletType())
            dispatch(cleanBcAddress())
            dispatch(cleanVbcWalletEncPass())
            dispatch(cleanListVBCAccount())
            dispatch(cleanVBCAccount())
            dispatch(cleanVBCWallet())
            dispatch(cleanAccountBalance())
            dispatch(cleanWrapperBalance())
            setOnProcess({status: "closeAlert"})
        }
    }, [accountSlice?.data, dispatch, detailProfileAccountSlice?.data, setOnProcess, t])

    
    React.useEffect(() => {
        if(window.ethereum){
            if(walletTypeSlice === "MetaMask"){
                window.ethereum.on("accountsChanged", handleAccountsChanged)
            }
            else {
                window.ethereum.removeListener("accountsChanged", handleAccountsChanged)
            }
        }
        return () => window.ethereum.removeListener("accountsChanged", handleAccountsChanged)
    },[handleAccountsChanged, walletTypeSlice])


    const [copy_content, setCopyContent] = React.useState(t("copy"))

    return (
        <>
            <div className="waSi__address--content">
                <div className="waSi__address--render">
                    {renderAddress(currentBcAddressSlice)}
                </div>
                <div className="waSi__address--icon">
                    <div id="copy-address"
                        onClick={() => {
                            if(currentBcAddressSlice){
                                setCopyContent(t("copied"))
                                navigator.clipboard.writeText(currentBcAddressSlice)
                                setTimeout(() => {
                                    setCopyContent(t("copy"))
                                }, 2000);
                            }
                        }}
                    >
                        <Copy/>
                    </div>
                    <UncontrolledTooltip
                        target="copy-address"
                    >
                        {copy_content}
                    </UncontrolledTooltip>
                </div>
            </div>
        </>
    )
}

export default MetaMaskAddress