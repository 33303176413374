import React from "react";
import AlertCircleIcon from "mdi-react/AlertCircleIcon"
import VBCoin from "../../../assets/images/VBCoin/VBCoin.svg"
import WVBCoin from "../../../assets/images/VBCoin/WVBCoin.svg"
import BNBcoin from "../../../assets/images/networkIcon/bnbIcon.png"
import WBNBcoin from "../../../assets/images/VBCoin/WBNBcoin.png"

const Icon = props => {
    const {
        type,
    } = props

    return (
        <>
            {
                !type ?
                <>
                    <AlertCircleIcon className="renderPrice__icon"/>
                </>
                :
                <>
                    <img
                        src={ 
                            type === "VBC" ?
                            VBCoin :
                            type === "WVBC" ?
                            WVBCoin :
                            type === "BNB" ?
                            BNBcoin :
                            type === "WBNB" ?
                            WBNBcoin :
                            ""
                        }
                        alt="price-icon"
                        className="renderPrice__icon"
                    />
                </>
            }
        </>
    )
}

export default Icon