import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isLoading: false,
    is_failure: false,
}

const onSaleNFTsListOfUserSlice = createSlice({
    name:"onSaleNFTsListOfUser",
    initialState: initialState,
    reducers: {
        getOnSaleNFTsListOfUser(state, action){
            return {
                isLoading: true,
                is_failure: false,
            }
        },
        getOnSaleNFTsListOfUserSuccess(state, action){
            return {
                isLoading: false,
                is_failure: false,
                ...action.payload,
            }
        },
        getOnSaleNFTsListOfUserFailure(state, action){
            return {
                isLoading: false,
                is_failure: true,
            }
        },
        cleanOnSaleNFTsListOfUser(state, action){
            return {
                isLoading: false,
                is_failure: false,
            }
        }
    }
})

export const { cleanOnSaleNFTsListOfUser, getOnSaleNFTsListOfUser, getOnSaleNFTsListOfUserFailure, getOnSaleNFTsListOfUserSuccess } = onSaleNFTsListOfUserSlice.actions

export default onSaleNFTsListOfUserSlice.reducer