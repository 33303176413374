import React from "react";
import VietnamFlag from "../../../assets/images/Flag/VietnamFlag.png"
import UKFlag from "../../../assets/images/Flag/UKFlag.png"
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import MenuDownIcon from "mdi-react/MenuDownIcon"

const Language = props => {
    const {
        i18n,
        t,
        language,
        saveLang,
        match
    } = props

    const [is_hide_border, setIsHideBorder] = React.useState("")

    const handleSetIsHideBorder = React.useCallback(() => {
        if(match?.path?.includes("marketplace") && window?.scrollY <= 70){
            setIsHideBorder("is_hide_border")
        }
        else {
            setIsHideBorder("")
        }
    }, [match?.path])

    React.useEffect(() => {
        handleSetIsHideBorder()
        window.addEventListener("scroll", handleSetIsHideBorder)
        return () => {
            setIsHideBorder("")
            window.removeEventListener("scroll", handleSetIsHideBorder)
        }
    }, [handleSetIsHideBorder])

    const handleChangeLang = async (lang) => {
        await i18n.changeLanguage(lang)
    }

    React.useEffect(() => {
        i18n.changeLanguage(language)
    }, [i18n, language])

    return (
        <div className="header__language">
            <UncontrolledDropdown direction="down">
                <DropdownToggle tag={"span"}>
                    <div className="header__language--content">
                        <div className="header__language--flag">
                            <img
                                src={language === "en" ? UKFlag : VietnamFlag}
                                alt=""
                            />
                        </div>
                        <div
                            className={
                                `
                                    ${is_hide_border}
                                    header__language--arrow
                                `
                            }
                        >
                            <MenuDownIcon/>
                        </div>
                    </div>
                </DropdownToggle>
                <DropdownMenu>
                    <DropdownItem onClick={() => {handleChangeLang("vi"); saveLang("vi")}}>
                        <div className="dropdown__item">
                            <img
                                src={VietnamFlag}
                                alt=""
                            /> <div> {t("vietnamese")} </div>
                        </div>
                    </DropdownItem>
                    <DropdownItem onClick={() => {handleChangeLang("en"); saveLang("en")}}>
                        <div className="dropdown__item">
                            <img
                                src={UKFlag}
                                alt=""
                            /> <div> {t("english")} </div>
                        </div>
                    </DropdownItem>
                </DropdownMenu>
            </UncontrolledDropdown>
        </div>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        saveLang: data => dispatch({type: "SAVE_LANGUAGE", data})
    }
}

const mapStateToProps = (state) => {
    return {
        language: state.language
    }
}

export default withTranslation('common')(withRouter(connect(mapStateToProps, mapDispatchToProps)(Language)));