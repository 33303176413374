import { lazy } from "react";

const SignUp = lazy(() => import("../pages/Sign.Up"))
const VerifyEmail = lazy(() => import("../pages/Verify.Email"))
const CreateWallet = lazy(() => import("../pages/Create.Wallet"))
const SignIn = lazy(() => import("../pages/Sign.In"))

const NormalRoutes = [
    {
        path: "/sign-in",
        component: SignIn
    },
    {
        path: "/sign-up",
        component: SignUp
    },
    {
        path: "/account/verify-email/:user_id/:token_hash",
        component: VerifyEmail
    },
    {
        path: "/create-wallet",
        component: CreateWallet
    },
]

export default NormalRoutes