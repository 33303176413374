import { takeLatest, call, put } from 'redux-saga/effects'
import { LINK_API } from "../../../constants/API"
import fetchData from "../fetchData"
import { getNFTsListOfBundle, getNFTsListOfBundleFailure, getNFTsListOfBundleSuccess } from "../../reducer/config.bundle.detail/nftsListOfBundleSlice"

function* fetchNFTsListOfBundle (payload) {
    try {
        const temp_payload = payload?.payload

        const bundle = temp_payload?.id ? `&bundle=${temp_payload?.id}` : ""
        const likeAddress = temp_payload?.likeAddress ? `&likeAddress=${temp_payload?.likeAddress}` : ""

        const url = LINK_API.GET_LIST_NFTS + "?size=12&page=1&status=ONSALE,NOTFORSALE,INCOMING,SOLD" + bundle + likeAddress

        const response = yield call(fetchData, url)

        const data = response?.data

        yield put({type: getNFTsListOfBundleSuccess().type, payload: data})

    } catch (error) {
        yield put({ type: getNFTsListOfBundleFailure().type})
        
    }
}

const nftsListOfBundleSaga = [
    takeLatest(getNFTsListOfBundle().type, fetchNFTsListOfBundle)
]

export default nftsListOfBundleSaga