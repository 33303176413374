import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isLoading: false,
}

const listSetsOfCollectionSlice = createSlice({
    name:"listSetsOfCollection",
    initialState: initialState,
    reducers: {
        getListSetsOfCollection(state, action){
            return {
                isLoading: true,
            }
        },
        getListSetsOfCollectionSuccess(state, action){
            return {
                isLoading: false,
                data: action.payload
            }
        },
        getListSetsOfCollectionFailure(state, action){
            return {
                isLoading: false,
            }
        },
        cleanListSetsOfCollection(state, action){
            return {
                isLoading: false
            }
        }
    }
})

export const { cleanListSetsOfCollection, getListSetsOfCollection, getListSetsOfCollectionFailure, getListSetsOfCollectionSuccess } = listSetsOfCollectionSlice.actions

export default listSetsOfCollectionSlice.reducer