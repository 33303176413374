import { takeLatest, call, put } from 'redux-saga/effects'
import { LINK_API } from "../../../constants/API"
import fetchData from "../fetchData"
import { getNftListOfSet, getNftListOfSetFailure, getNftListOfSetSuccess } from "../../reducer/config.set.detail/nftListOfSetSlice"

function* fetchNftOfSet (payload) {
    try {
        const temp_payload = payload?.payload
        const setId = temp_payload?.id ? `?setId=${temp_payload?.id}` : ""
 
        let url = LINK_API.GET_SMALL_INFO_OF_NFTS_LIST + setId

        const response = yield call(fetchData, url)

        const data = response?.data

        const temp_list = data?.data
        const onsale_list = temp_list?.filter(nft => nft?.status === "ONSALE")
        const another_status_list = temp_list?.filter(nft => nft?.status !== "ONSALE")

        const new_data = {
            ...data,
            data: onsale_list.concat(another_status_list)
        }

        yield put({type: getNftListOfSetSuccess().type, payload: new_data})

    } catch (error) {
        yield put({ type: getNftListOfSetFailure().type })
        
    }
}

const nftListOfSetSaga = [
    takeLatest(getNftListOfSet().type, fetchNftOfSet)
]

export default nftListOfSetSaga