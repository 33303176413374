import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getListNetwork } from "../../../redux/reducer/config.network/listNetworkSlice";
import { saveCurrentNetwork } from "../../../redux/reducer/config.network/currentNetworkSlice";
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap"
import { useTranslation } from "react-i18next";
import MenuDownIcon from "mdi-react/MenuDownIcon"
import switchNetworkOnMetaMask from "../../../utils/switchNetworkOnMetaMask";

const Network = props => {
    const { t } = useTranslation("common")
    const dispatch = useDispatch()
    const listNetworkSlice = useSelector(state => state.listNetworkSlice)
    const currentNetworkSlice = useSelector(state => state.currentNetworkSlice)
    const walletTypeSlice = useSelector(state => state.walletTypeSlice)

    const handleGetListNetwork = React.useCallback(() => {
        if(!listNetworkSlice?.data) {
            dispatch(getListNetwork())
        }
    }, [dispatch, listNetworkSlice?.data])

    React.useState(() => {
        handleGetListNetwork()
    }, [handleGetListNetwork])

    const handleSaveCurrentNetwork = React.useCallback(() => {
        if(!currentNetworkSlice?.networkId && listNetworkSlice?.data && listNetworkSlice?.data[0]){
            dispatch(saveCurrentNetwork(listNetworkSlice?.data[0]))
        }
    }, [listNetworkSlice?.data, dispatch, currentNetworkSlice?.networkId])

    React.useEffect(() => {
        handleSaveCurrentNetwork()
    }, [handleSaveCurrentNetwork])

    const handleChangeCurrentNetwork = async (network) => {
        if(walletTypeSlice === "MetaMask") {
            const switch_network_result = await switchNetworkOnMetaMask(network)
            if(switch_network_result?.success) {
                dispatch(saveCurrentNetwork(network))
            }
            else {
                
            }
        }
        else {
            dispatch(saveCurrentNetwork(network))
        }
    }

    return (
        <>
            <UncontrolledDropdown>
                <DropdownToggle tag={"span"}>
                    <div className="waSi__network">
                        <img
                            alt="network_icon"
                            src={currentNetworkSlice?.icon}
                        />
                        <div className="waSi__network--name">
                            {currentNetworkSlice?.name || t("noDataFound")}
                        </div>
                        <div className="waSi__network--caret">
                            <MenuDownIcon/>
                        </div>
                    </div>
                </DropdownToggle>
                <DropdownMenu>
                    {
                        listNetworkSlice?.data?.map((nw, index) => {
                            return(
                                <DropdownItem
                                    key={index}
                                    onClick={() => {
                                        if(nw?.networkId !== currentNetworkSlice?.networkId){
                                            handleChangeCurrentNetwork(nw)
                                        }                                        
                                    }}
                                >
                                    <div className="dropdown__item">
                                        <img
                                            alt=""
                                            src={nw?.icon}
                                        /> 
                                        <div> {nw?.name} </div>
                                    </div>
                                </DropdownItem>
                            )
                        })
                    }
                    
                
                </DropdownMenu>
            </UncontrolledDropdown>
        </>
    )
}
export default Network