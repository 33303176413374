import { takeLatest, call, put } from 'redux-saga/effects'
import { LINK_API } from "../../../constants/API"
import fetchData from "../fetchData"
import { getDetailSet, getDetailSetFailure, getDetailSetSuccess } from "../../reducer/config.set.detail/detailSetSlice"

function* fetchDetailSet (payload) {
    try {
        const temp_payload = payload?.payload

        const id = temp_payload?.id ? `?id=${temp_payload?.id}` : ""
        const likeAddress = temp_payload?.likeAddress ? `&likeAddress=${temp_payload?.likeAddress}` : ""

        let url = LINK_API.GET_DETAIL_SET + id + likeAddress

        const response = yield call(fetchData, url)

        const data = response?.data

        yield put({type: getDetailSetSuccess().type, payload: {...data?.data}})

    } catch (error) {
        yield put({ type: getDetailSetFailure().type })
        
    }
}

const detailSetSaga = [
    takeLatest(getDetailSet().type, fetchDetailSet)
]

export default detailSetSaga