import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isLoading: false,
    is_failure: false,
}

const inspectionReportOfNFTSlice = createSlice({
    name:"inspectionReportOfNFT",
    initialState: initialState,
    reducers: {
        getInspectionReportOfNFT(state, action){
            return {
                isLoading: true,
                is_failure: false,
            }
        },
        getInspectionReportOfNFTSuccess(state, action){
            return {
                isLoading: false,
                is_failure: false,
                data: action?.payload,
            }
        },
        getInspectionReportOfNFTFailure(state, action){
            return {
                isLoading: false,
                is_failure: true,
            }
        },
        cleanInspectionReportOfNFT(state, action){
            return{
                isLoading: false,
                is_failure: false,
            }
        }
    }
})

export const { 
    cleanInspectionReportOfNFT,
    getInspectionReportOfNFT,
    getInspectionReportOfNFTFailure,
    getInspectionReportOfNFTSuccess,
} = inspectionReportOfNFTSlice.actions

export default inspectionReportOfNFTSlice.reducer