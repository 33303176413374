import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isLoading: false,
}

const listVBCWalletSlice = createSlice({
    name:"listVBCWallet",
    initialState: initialState,
    reducers: {
        getListVBCWallet(state, action){
            return {
                isLoading: true,
            }
        },
        getListVBCWalletSuccess(state, action){
            return {
                isLoading: false,
                data: action.payload
            }
        },
        getListVBCWalletFailure(state, action){
            return {
                isLoading: false,
            }
        },
    }
})

export const { getListVBCWallet, getListVBCWalletFailure, getListVBCWalletSuccess } = listVBCWalletSlice.actions

export default listVBCWalletSlice.reducer