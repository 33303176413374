import React from "react";
import { UncontrolledTooltip } from "reactstrap";
import { handleRenderPrice } from "../../../constants/handleRenderPrice";
import { v4 } from 'uuid';

const Price = props => {
    const {
        data,
        type,
    } = props

    const fixed_data = handleRenderPrice((data || 0), 4)
    const is_fixed = fixed_data !== parseFloat(data)
    const id = v4()
    
    return (
        <>
            <span className={`renderPrice__price ${type}`}
                id={"price-render" + id}
            >
                {is_fixed ? "~" : ""} {fixed_data} {type}
            </span>
            {
                is_fixed ?
                <UncontrolledTooltip target={"price-render" + id}>
                    {data}
                </UncontrolledTooltip>
                :
                null
            }
        </>
    )
}

export default Price