import { takeLatest, call, put } from 'redux-saga/effects'
import { LINK_API } from "../../../constants/API"
import { getWrapperBalance, getWrapperBalanceFailure, getWrapperBalanceSuccess } from "../../reducer/config.balance/wrapperBalanceSlice"
import Axios from 'axios'

function fetchData(data) {
    return Axios({
        method: "POST",
        url: LINK_API.GET_WRAPPER_BALANCE,
        data,
        headers: {
            "marketplace-key": process.env.REACT_APP_MARKETPLACE_KEY
        }
    })
}

function* fetchWrapperBalance (payload) {
    try {
        const data = payload?.payload
        const response = yield call(fetchData, data)
        const balance = response?.data

        yield put({type: getWrapperBalanceSuccess().type, payload: balance?.data})
    } catch (error) {
        yield put({ type: getWrapperBalanceFailure().type })
        
    }
}

const wrapperBalanceSaga = [
    takeLatest(getWrapperBalance().type, fetchWrapperBalance)
]

export default wrapperBalanceSaga