import React from "react";
import "./index.scss"

const Fade = props => {

    const [is_scroll, setIsScroll] = React.useState("")

    const handleSetIsScroll = React.useCallback(() => {
        if(window.scrollY > 30) {
            setIsScroll("is_scroll")
        }
        else {
            setIsScroll("")
        }
    }, [])

    React.useEffect(() => {
        window.addEventListener("scroll", handleSetIsScroll)
        return () => {
            setIsScroll("")
            window.removeEventListener("scroll", handleSetIsScroll)
        }
    }, [handleSetIsScroll])

    return (
        <div className={
            `
                header__fade
                ${is_scroll}
            `
        }>

        </div>
    )
}

export default Fade