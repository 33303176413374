import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isLoading: false,
    is_failure: false,
}

const detailNFTSlice = createSlice({
    name:"detailNFT",
    initialState: initialState,
    reducers: {
        getDetailNFT(state, action){
            return {
                isLoading: true,
                is_failure: false,
            }
        },
        getDetailNFTSuccess(state, action){
            return {
                isLoading: false,
                is_failure: false,
                ...action.payload
            }
        },
        getDetailNFTFailure(state, action){
            return {
                isLoading: false,
                is_failure: true,
            }
        },
        cleanDetailNFT(state, action){
            return {
                isLoading: false,
                is_failure: false,
            }
        }
    }
})

export const { cleanDetailNFT, getDetailNFT, getDetailNFTFailure, getDetailNFTSuccess } = detailNFTSlice.actions

export default detailNFTSlice.reducer