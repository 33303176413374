import { takeLatest, call, put, select } from 'redux-saga/effects'
import { LINK_API } from "../../../constants/API"
import fetchData from "../fetchData"
import { getListVBCAccount, getListVBCAccountFailure, getListVBCAccountSuccess } from "../../reducer/config.wallet/listVBCAccountSlice"
import Axios from 'axios'

const accountSlice = state => state.accountSlice

function* fetchListVBCAccount (payload) {
    try {
        const account = yield select(accountSlice)

        Axios.defaults.headers.common.Authorization = `Bearer ${account?.data?.token}`;

        const walletID = payload?.payload?._id ? `?walletID=${payload?.payload?._id}` : ""

        const url = LINK_API.GET_ACCOUNT_BY_PLATFORM + walletID + "&platform=0"

        const response = yield call(fetchData, url)

        const data = response?.data?.accounts

        yield put({type: getListVBCAccountSuccess().type, payload: data})

    } catch (error) {
        yield put({ type: getListVBCAccountFailure().type })
        
    }
}

const listVBCAccountSaga = [
    takeLatest(getListVBCAccount().type, fetchListVBCAccount)
]

export default listVBCAccountSaga