import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isLoading: false,
}

const listTrendingAuctionsSlice = createSlice({
    name:"listTrendingAuctions",
    initialState: initialState,
    reducers: {
        getListTrendingAuctions(state, action){
            return {
                isLoading: true,
            }
        },
        getListTrendingAuctionsSuccess(state, action){
            return {
                isLoading: false,
                ...action.payload
            }
        },
        getListTrendingAuctionsFailure(state, action){
            return {
                isLoading: false,
            }
        },
        cleanListTrendingAuctions(state, action){
            return {
                isLoading: false
            }
        }
    }
})

export const { cleanListTrendingAuctions, getListTrendingAuctions, getListTrendingAuctionsFailure, getListTrendingAuctionsSuccess } = listTrendingAuctionsSlice.actions

export default listTrendingAuctionsSlice.reducer