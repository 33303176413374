import React from "react"

const VaultIcon = props => {

    return (
        <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.27018 12.309L7.72008 11.4009L4.94336 13.0298L2.16503 11.4009L0.614929 12.309L4.93855 14.8446L9.27018 12.309Z" fill="#737475"/>
            <path d="M0 8.69077L4.32362 11.2263V6.15207L0 3.61816V8.69077Z" fill="#737475"/>
            <path d="M12.0501 10.6803L10.5 9.77051L8.94989 10.6787L10.5 11.5868L12.0501 10.6803Z" fill="#737475"/>
            <path d="M4.32362 15.9257L0 13.3918V18.4645L4.32362 21V15.9257Z" fill="#737475"/>
            <path d="M5.55823 21L9.88185 18.4645V13.3918L5.55823 15.9257V21Z" fill="#737475"/>
            <path d="M5.55823 11.2263L9.88185 8.69077V3.61816L5.55823 6.15207V11.2263Z" fill="#737475"/>
            <path d="M0.614929 2.5355L4.93855 5.07101L9.26218 2.5355L4.94336 0L0.614929 2.5355Z" fill="#737475"/>
            <path d="M20.3851 12.309L18.8366 11.4009L16.0583 13.0298L13.2799 11.4009L11.7314 12.309L16.0551 14.8446L20.3851 12.309Z" fill="#737475"/>
            <path d="M11.7314 2.5355L16.0551 5.07101L20.3787 2.5355L16.0583 0L11.7314 2.5355Z" fill="#737475"/>
            <path d="M21 13.3918L16.6764 15.9257V21L21 18.4645V13.3918Z" fill="#737475"/>
            <path d="M15.4434 15.9257L11.1198 13.3918V18.4645L15.4434 21V15.9257Z" fill="#737475"/>
            <path d="M21 8.69077V3.61816L16.6764 6.15207V11.2263L21 8.69077Z" fill="#737475"/>
            <path d="M11.1149 8.69077L15.4386 11.2263V6.15207L11.1149 3.61816V8.69077Z" fill="#737475"/>
        </svg>
    )
}

export default VaultIcon