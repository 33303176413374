import { takeLatest, call, put } from 'redux-saga/effects'
import { LINK_API } from "../../../constants/API"
import fetchData from "../fetchData"
import { getBidListOfSet, getBidListOfSetSuccess, getBidListOfSetFailure } from "../../reducer/config.set.detail/bidListOfSetSlice"

function* fetchBidListOfSet (payload) {
    try {

        let url = LINK_API.GET_HISTORY_BID_OF_NFT + `/${payload?.payload}`

        const response = yield call(fetchData, url)

        const data = response?.data

        yield put({type: getBidListOfSetSuccess().type, payload: data})

    } catch (error) {
        yield put({ type: getBidListOfSetFailure().type })
        
    }
}

const bidListOfSetSaga = [
    takeLatest(getBidListOfSet().type, fetchBidListOfSet)
]

export default bidListOfSetSaga