import { takeLatest, call, put } from 'redux-saga/effects'
import { LINK_API } from "../../../constants/API"
import fetchData from "../fetchData"
import { getListPopularCollections, getListPopularCollectionsFailure, getListPopularCollectionsSuccess } from "../../reducer/list/listPopularCollectionsSlice"

function* fetchListPopularCollections (payload) {
    try {

        const likeAddress = payload?.payload?.likeAddress ? `&likeAddress=${payload?.payload?.likeAddress}` : ""

        const url = LINK_API.GET_LIST_COLLECTIONS + "?size=5&page=1&sort=like" + likeAddress

        // const url = LINK_API.GET_LIST_COLLECTIONS + "?size=4&page=1" + likeAddress

        const response = yield call(fetchData, url)

        const data = response.data

        yield put({type: getListPopularCollectionsSuccess().type, payload: data})

    } catch (error) {
        yield put({ type: getListPopularCollectionsFailure().type })
        
    }
}

const listPopularCollectionsSaga = [
    takeLatest(getListPopularCollections().type, fetchListPopularCollections)
]

export default listPopularCollectionsSaga