import Axios from 'axios'
import { takeLatest, call, put } from 'redux-saga/effects'
import { LINK_API } from "../../../constants/API"
// import fetchData from "../fetchData"
import { getInspectionReportOfNFT, getInspectionReportOfNFTFailure, getInspectionReportOfNFTSuccess } from "../../reducer/config.nft.detail/inspectionReportOfNFTSlice"

const fetchData = (url, params) => {  
    return Axios({
        method: "GET",
        url,
        headers: {
            "marketplace-key": process.env.REACT_APP_MARKETPLACE_KEY
        },
        params,
    })
}

function* fetchInspectionReportOfNFT (payload) {
    try {

        const params = {
            "item_id": payload?.payload?.item_id
        }

        let url = LINK_API.GET_INSPECTION_REPORT_OF_NFT

        const response = yield call(fetchData, url, params)

        const data = response?.data

        yield put({type: getInspectionReportOfNFTSuccess().type, payload: data})

    } catch (error) {
        yield put({ type: getInspectionReportOfNFTFailure().type })
        
    }
}

const inspectionReportOfNFTSaga = [
    takeLatest(getInspectionReportOfNFT().type, fetchInspectionReportOfNFT)
]

export default inspectionReportOfNFTSaga