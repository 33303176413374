import Axios from 'axios'
import { takeLatest, call, put } from 'redux-saga/effects'
import { LINK_API } from "../../../constants/API"
import { getListPropertiesOfNFT, getListPropertiesOfNFTFailure, getListPropertiesOfNFTSuccess } from "../../reducer/config.nft.detail/listPropertiesOfNFTSlice"

const fetchData = url => {  
    return Axios({
        method: "GET",
        url,
        headers: {
            "marketplace-key": process.env.REACT_APP_MARKETPLACE_KEY
        }
    })
}

function* fetchListPropertiesOfNFT (payload) {
    try {

        const url = LINK_API.GET_PROPERTIES_OF_NFT + `?item_id=${payload?.payload}`

        const response = yield call(fetchData, url)

        const data = response.data

        yield put({type: getListPropertiesOfNFTSuccess().type, payload: data})

    } catch (error) {
        yield put({ type: getListPropertiesOfNFTFailure().type })
        
    }
}

const listPropertiesOfNFTSaga = [
    takeLatest(getListPropertiesOfNFT().type, fetchListPropertiesOfNFT)
]

export default listPropertiesOfNFTSaga