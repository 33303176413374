import React from "react";
import "./index.scss"
import AccountBalance from "./Account.Balance";
import WrapperBalance from "./Wrapper.Balance";
import TotalBalance from "./Total.Balance";

const Balance = props => {
    const {
        setOnProcess,
        isOpen,
    } = props

    return (
        <>
            <div className="container mt-4">
                <TotalBalance/>
            </div>
            <div className="container mt-4">
                <AccountBalance setOnProcess={setOnProcess} isOpen={isOpen} />
            </div>
            <div className="container mt-4">
                <WrapperBalance setOnProcess={setOnProcess} isOpen={isOpen} />
            </div>
        </>
    )
}

export default Balance