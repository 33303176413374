import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isLoading: false,
}

const listPopularSetsSlice = createSlice({
    name:"listPopularSets",
    initialState: initialState,
    reducers: {
        getListPopularSets(state, action){
            return {
                isLoading: true,
            }
        },
        getListPopularSetsSuccess(state, action){
            return {
                isLoading: false,
                ...action.payload,
            }
        },
        getListPopularSetsFailure(state, action){
            return {
                isLoading: false,
            }
        },
        cleanListPopularSets(state, action){
            return {
                isLoading: false
            }
        }
    }
})

export const { cleanListPopularSets, getListPopularSets, getListPopularSetsFailure, getListPopularSetsSuccess } = listPopularSetsSlice.actions

export default listPopularSetsSlice.reducer