import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isLoading: false,
    is_failure: false,
}

const propertiesOfSetSlice = createSlice({
    name:"propertiesOfSet",
    initialState: initialState,
    reducers: {
        getPropertiesOfSet(state, action){
            return {
                isLoading: true,
                is_failure: false,
            }
        },
        getPropertiesOfSetSuccess(state, action){
            return {
                isLoading: false,
                is_failure: false,
                data: action.payload
            }
        },
        getPropertiesOfSetFailure(state, action){
            return {
                isLoading: false,
                is_failure: true,
            }
        },
        cleanPropertiesOfSet (state, action){
            return {
                isLoading: false,
                is_failure: false,
            }
        }
    }
})

export const { cleanPropertiesOfSet , getPropertiesOfSet, getPropertiesOfSetFailure, getPropertiesOfSetSuccess } = propertiesOfSetSlice.actions

export default propertiesOfSetSlice.reducer