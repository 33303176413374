import Axios from "axios";

const checkTxHash = async (network, tx_hash) => {
    try {
        let txReceiptResult = null;
        const checkBinanceNetwork = network?.networkId?.toString() === "97" ? true : false
        while (!txReceiptResult) {
            const txReceipt = await Axios.post(network?.domain + `${checkBinanceNetwork ? "" : "VBC001"}`, {
                jsonrpc: "2.0",
                id: network?.networkId,
                params: [tx_hash],
                method: "eth_getTransactionReceipt",
            });

            if (
                txReceipt &&
                txReceipt.data &&
                txReceipt.data.result &&
                txReceipt.data.result.status
            ) {
                txReceiptResult = txReceipt.data.result.status;
                if (txReceiptResult === "0x1") {
                    return {
                        success: true
                    }
                } 
                else if(txReceiptResult === "0x0") {
                    return {
                        success: false
                    }
                }
            }
        }
    } catch (error) {
        return {
            success: false
        }
    }
}

export default checkTxHash