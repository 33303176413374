import React from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";

const ProductMenu = props => {
    const {
        t,
        history,
        match,
    } = props

    const product_list = [
        {
            title: t("collections.collections"),
            id: "collections",
            path: "/collections/page=1",
        },
        {
            title: t("sets"),
            id: "sets",
            path: "/sets/page=1",
        },
        {
            title: t("nfts"),
            id: "nfts",
            path: "/nfts/page=1",
        },
    ]

    return (
        <div className="headerSidebar__product">
            {
                product_list?.map((product, index) => {
                    const is_actived = match?.path?.includes(product?.id) ? "is_actived" : ""

                    return (
                        <div
                            key={index}
                            className={
                                `
                                    headerSidebar__product--item
                                    ${is_actived}
                                `
                            }
                            onClick={() => {
                                history.push(product.path)
                                window.scrollTo(0, 0)
                            }}
                        >
                            {product?.title}
                        </div>
                    )
                })
            }
        </div>
    )
}

export default withTranslation("common")(withRouter(ProductMenu))